import { useQuery } from "@apollo/client";
import { ENUM_ADMIN_ROLES, ENUM_WORKFLOW_TYPE } from "@prisma/client";
import { ADMIN_READ_AGENT_ACTIONS } from "@roadflex/graphql";
import { useReadAdmin } from "@roadflex/react-hooks";
import { AgentActionData } from "@roadflex/types";
import { useState } from "react";
import AgentActionsUI from "./agent-actions.ui";

type AgentActionsProps = {
  selectedWorkflowType: ENUM_WORKFLOW_TYPE;
  adminRole: ENUM_ADMIN_ROLES;
  adminDateTimeTemplate: (date: Date) => JSX.Element;
};

export const AgentActions = (props: AgentActionsProps) => {
  const { selectedWorkflowType, adminRole, adminDateTimeTemplate } = props;
  const { adminListData } = useReadAdmin();
  const [dropDownValue, setdropDownValue] = useState("");

  const { data: agentActionData, loading: agentActionDataLoading } = useQuery<{
    agentActions: {
      callClientAction: AgentActionData[];
      smsAction: AgentActionData[];
      emailAction: AgentActionData[];
    };
  }>(ADMIN_READ_AGENT_ACTIONS, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
    variables: {
      data: {
        workflowType: selectedWorkflowType,
        adminId: dropDownValue,
      },
    },
  });

  const admins =
    adminListData?.readAdmins?.adminList?.filter((admin) =>
      admin?.roles?.includes(adminRole),
    ) || [];
  const tabs = [
    { id: 1, name: "Call Client", current: true, href: "#" },
    { id: 2, name: "Email", current: false, href: "#" },
    { id: 3, name: "SMS", current: false, href: "#" },
  ];
  return (
    <AgentActionsUI
      dropDownValue={dropDownValue}
      setdropDownValue={setdropDownValue}
      callClientActionData={
        agentActionData?.agentActions?.callClientAction || []
      }
      emailActionData={agentActionData?.agentActions?.emailAction || []}
      smsActionData={agentActionData?.agentActions?.smsAction || []}
      admins={admins}
      tabs={tabs}
      agentActionDataLoading={agentActionDataLoading}
      adminDateTimeTemplate={adminDateTimeTemplate}
    ></AgentActionsUI>
  );
};

export default AgentActions;
