import { useMutation, useQuery } from "@apollo/client";
import { ENUM_SENDGRID_TEMPLATE } from "@prisma/client";
import { adminAssignMailType, adminSmsEmailTest } from "@roadflex/constants";
import {
  CREATE_UPDATE_ADMIN_EMAIL_TEMPLATE,
  CREATE_UPDATE_MAIL_TYPE,
  DELETE_ADMIN_EMAIL_TEMPLATE,
  READ_ADMIN_EMAIL_TEMPLATE_LIST,
  READ_PROSPECTIVE_LEADS_COMMUNICATION_FILE_ENTRIES,
  READ_PROSPECTIVE_LEADS_FILE_ENTRIES,
  READ_SMS_TEMPLATE_FOR_PROSPECTIVE_LEADS,
  TEST_EMAIL_OR_SMS_TEMPLATE,
  UPDATE_SMS_TEMPLATE_FOR_PROSPECTIVE_LEADS,
  UPLOAD_PROSPECTIVE_LEADS_COMMUNICATION_FILE,
} from "@roadflex/graphql";
import {
  EmailTemplateDetails,
  MailTypeDetails,
  ProspectiveLeadsFileEntryType,
  SendGridTemplateDetails,
} from "@roadflex/types";

import { useFormik } from "formik";

type useCreateUpdateAdminEmailTemplateProps = {
  initialValues: {
    templateName: string;
    emailId: string;
    variables: Record<string, string>;
    sendgridTemplateType: ENUM_SENDGRID_TEMPLATE;
  };
  onSubmit: (val: any, err: any) => void;
};

type useDeleteAdminEmailTemplateProps = {
  initialValues: {
    idList: [string];
  };
  onSubmit: (val: any, err: any) => void;
};

type useCreateUpdateMailTypeProps = {
  initialValues: {
    mailType: string;
    templateName: string;
  };
  onSubmit: (val: any, err: any) => void;
};

type useTestEmailOrSmsProps = {
  initialValues: {
    mode: string;
    phoneNumber: string;
    smsTemplate: string;
    emailTemplateId: string;
    emailList: [string];
    variables: Record<string, string>;
    emailId: string;
  };
  onSubmit: (val: any, err: any) => void;
};

export function useReadAdminEmailTemplates() {
  const { data, refetch } = useQuery<{
    readAdminEmailTemplateList: {
      code: string;
      message: string;
      sendGridTemplates: SendGridTemplateDetails[];
      emailTemplates: EmailTemplateDetails[];
      mailTypes: MailTypeDetails[];
    };
  }>(READ_ADMIN_EMAIL_TEMPLATE_LIST, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
  });

  const adminEmailTemplateList: EmailTemplateDetails[] =
    data?.readAdminEmailTemplateList?.emailTemplates?.map((value: any) => {
      return {
        id: value.id,
        templateName: value.templateName,
        email: value.emailId,
        sendgridTemplateType: value.sendgridTemplateType,
        variables: value.variables,
      };
    }) || [];

  const sendGridTemplateList: SendGridTemplateDetails[] =
    data?.readAdminEmailTemplateList?.sendGridTemplates?.map((value: any) => {
      return {
        id: value.id,
        sendgridTemplateType: value.sendgridTemplateType,
        variableNames: value.variableNames,
      };
    }) || [];
  return {
    adminEmailTemplateList,
    sendGridTemplateList,
    mailTypes: data?.readAdminEmailTemplateList?.mailTypes,
    refetchTemplates: refetch,
  };
}
export function useCreateUpdateAdminEmailTemplate({
  onSubmit,
  initialValues,
}: useCreateUpdateAdminEmailTemplateProps) {
  const [createUpdateAdminEmailTemplateFn] = useMutation<{
    createUpdateAdminEmailTemplate: {
      code: string;
      message: string;
    };
  }>(CREATE_UPDATE_ADMIN_EMAIL_TEMPLATE);

  const {
    handleChange,
    handleSubmit,
    handleBlur,
    values,
    isSubmitting,
    ...rest
  } = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit: async (value) => {
      try {
        const res = await createUpdateAdminEmailTemplateFn({
          variables: { data: value },
        });
        if (res?.data?.createUpdateAdminEmailTemplate) {
          onSubmit(res?.data?.createUpdateAdminEmailTemplate, null);
        }
      } catch (error) {
        if (error instanceof Error) {
          onSubmit(null, error);
        } else {
          onSubmit(null, new Error("Something went wrong"));
        }
      }
    },
  });

  return {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    ...rest,
  };
}
export function useDeleteAdminEmailTemplate({
  onSubmit,
  initialValues,
}: useDeleteAdminEmailTemplateProps) {
  const [deleteAdminEmailTemplateFn] = useMutation<{
    deleteAdminEmailTemplate: {
      code: string;
      message: string;
    };
  }>(DELETE_ADMIN_EMAIL_TEMPLATE);

  const {
    handleChange,
    handleSubmit,
    handleBlur,
    values,
    isSubmitting,
    ...rest
  } = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit: async (value) => {
      try {
        const res = await deleteAdminEmailTemplateFn({
          variables: { data: value },
        });
        if (res?.data?.deleteAdminEmailTemplate) {
          onSubmit(res?.data?.deleteAdminEmailTemplate, null);
        }
      } catch (error) {
        if (error instanceof Error) {
          onSubmit(null, error);
        } else {
          onSubmit(null, new Error("Something went wrong"));
        }
      }
    },
  });

  return {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    ...rest,
  };
}
export function useCreateUpdateMailType({
  onSubmit,
  initialValues,
}: useCreateUpdateMailTypeProps) {
  const [createUpdateMailTypeFn] = useMutation<{
    createUpdateMailType: {
      code: string;
      message: string;
    };
  }>(CREATE_UPDATE_MAIL_TYPE);

  const {
    handleChange,
    handleSubmit,
    handleBlur,
    values,
    isSubmitting,
    ...rest
  } = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: adminAssignMailType,
    onSubmit: async (value) => {
      try {
        const res = await createUpdateMailTypeFn({
          variables: { data: value },
        });
        if (res?.data?.createUpdateMailType) {
          onSubmit(res?.data?.createUpdateMailType, null);
        }
      } catch (error) {
        if (error instanceof Error) {
          onSubmit(null, error);
        } else {
          onSubmit(null, new Error("Something went wrong"));
        }
      }
    },
  });

  return {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    ...rest,
  };
}
export function useTestEmailOrSms({
  onSubmit,
  initialValues,
}: useTestEmailOrSmsProps) {
  const [testEmailOrSmsTemplateFn] = useMutation<{
    testEmailOrSmsTemplate: {
      code: string;
      message: string;
    };
  }>(TEST_EMAIL_OR_SMS_TEMPLATE);

  const {
    handleChange,
    handleSubmit,
    handleBlur,
    values,
    isSubmitting,
    ...rest
  } = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: adminSmsEmailTest,
    onSubmit: async (value) => {
      try {
        const res = await testEmailOrSmsTemplateFn({
          variables: { data: value },
        });
        if (res?.data?.testEmailOrSmsTemplate) {
          onSubmit(res?.data?.testEmailOrSmsTemplate, null);
        }
      } catch (error) {
        if (error instanceof Error) {
          onSubmit(null, error);
        } else {
          onSubmit(null, new Error("Something went wrong"));
        }
      }
    },
  });

  return {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    ...rest,
  };
}

export function useAdminOutreach() {
  const {
    data: data1,
    refetch: refetchOutreachData,
    loading: outreachDataLoading,
  } = useQuery(READ_PROSPECTIVE_LEADS_FILE_ENTRIES, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
  });

  const outreachData: ProspectiveLeadsFileEntryType[] =
    data1?.readProspectiveLeadsFileEntries?.prospectiveLeadsFileEntries?.map(
      (oldObj: any) => {
        const newObj = {
          ...oldObj,
          createdAt: oldObj.createdAt ? new Date(oldObj.createdAt) : null,
        };
        return newObj;
      },
    ) || [];

  const {
    data: data2,
    refetch: refetchOutreachHistoryData,
    loading: outreachHistoryDataLoading,
  } = useQuery(READ_PROSPECTIVE_LEADS_COMMUNICATION_FILE_ENTRIES, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
  });

  const outreachHistoryData: ProspectiveLeadsFileEntryType[] =
    data2?.readProspectiveLeadsCommunicationFileEntries?.prospectiveLeadsCommunicationFileEntries?.map(
      (oldObj: any) => {
        const newObj = {
          ...oldObj,
          createdAt: oldObj.createdAt ? new Date(oldObj.createdAt) : null,
        };
        return newObj;
      },
    ) || [];

  const { data: outReachSmsTemplate } = useQuery(
    READ_SMS_TEMPLATE_FOR_PROSPECTIVE_LEADS,
    {
      fetchPolicy: "no-cache",
    },
  );

  const smsTemplate =
    outReachSmsTemplate?.readSmsTemplateForProspectiveLeads || {};

  const [saveSmsTemplateFn, { loading: savingSmsTemplate }] = useMutation(
    UPDATE_SMS_TEMPLATE_FOR_PROSPECTIVE_LEADS,
    {
      refetchQueries: [READ_SMS_TEMPLATE_FOR_PROSPECTIVE_LEADS],
    },
  );

  const [testEmailTemplateFn] = useMutation(TEST_EMAIL_OR_SMS_TEMPLATE);

  const [initiateOutreachAction, { loading: outreachActionLoading }] =
    useMutation(UPLOAD_PROSPECTIVE_LEADS_COMMUNICATION_FILE, {
      refetchQueries: [READ_PROSPECTIVE_LEADS_COMMUNICATION_FILE_ENTRIES],
    });

  return {
    outreachData,
    outreachDataLoading,
    refetchOutreachData,
    outreachHistoryData,
    outreachHistoryDataLoading,
    refetchOutreachHistoryData,
    saveSmsTemplateFn,
    savingSmsTemplate,
    testEmailTemplateFn,
    smsTemplate,
    initiateOutreachAction,
    outreachActionLoading,
  };
}
