/** @format */
import { TextInput, Toast } from "@roadflex/react-components-www";
import axios from "axios";
import { useState } from "react";
import {
  atomOneDark,
  atomOneLight,
  CodeBlock,
  CopyBlock,
} from "react-code-blocks";
import {
  Button,
  ButtonSize,
  ButtonType,
  ButtonVariant,
} from "../../../buttons";

const { NEXT_PUBLIC_API_URL } = process.env;
const baseUrl = `${NEXT_PUBLIC_API_URL}`;

type DeveloperControlCenterUIProps = {
  handleSubmit: () => void;
  isSubmitting: boolean;
  deleteHandleSubmit: () => void;
  deleteIsSubmitting: boolean;
  authorizationBearer?: string;
};

const DeveloperControlCenterUI = ({
  handleSubmit,
  deleteHandleSubmit,
  isSubmitting,
  deleteIsSubmitting,
  authorizationBearer,
}: DeveloperControlCenterUIProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isDataOpen, setIsDataOpen] = useState(true);
  const [isPaginationOpen, setIsPaginationOpen] = useState(true);
  const [isFuelOpen, setIsFuelOpen] = useState(false);

  const TEST_KEY = "roadflex_test_abc123";
  const webhookUrl = `${baseUrl}/api/v1/fuelcard/transactions`;

  const sampleAuthorization = {
    "Content-Type": "application/json",
    Authorization: "Bearer roadflex_test_abc123",
  };

  const sampleRequest = {
    startDate: "2025-01-01T00:00:00Z",
    endDate: "2025-01-31T23:59:59Z",
    page: 1,
    pageSize: 100,
  };

  const sampleResponse = {
    message: "Transactions fetched successfully",
    data: [
      {
        id: "tx_123456",
        transactionAmount: 4250,
        authorizationDate: "2025-01-15T10:30:00Z",
        merchantName: "Sample Merchant",
        fuel: {
          quantity: 12.2,
          type: "Unleaded Regular",
        },
      },
    ],
    count: 1,
    pagination: {
      totalCount: 3,
      currentPage: 1,
      totalPages: 3,
      pageSize: 1,
      hasMore: true,
    },
  };

  const testWebhook = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    try {
      setIsLoading(true);

      const response = await axios.post(
        webhookUrl,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${TEST_KEY}`,
          },
        },
      );

      await new Promise((resolve) => setTimeout(resolve, 1000));
      setIsLoading(false);
      Toast({ type: "success", message: "API request completed successfully" });
      await new Promise((resolve) => setTimeout(resolve, 2000));
    } catch (error) {
      if (error) {
        Toast({
          type: "error",
          message: (error as string) || "An error occurred. Please try again.",
        });
        setIsLoading(false);
      }
    }
  };

  return (
    <form className="flex flex-col w-full text-sm md:text-base">
      <div>
        <div className="p-4 space-y-4 bg-white rounded-md shadow md:p-8">
          <div className="font-semibold md:py-2 text-brown-500 md:text-lg">
            Developer Control Center
          </div>
          <div className="flex flex-col justify-between sm:flex-row sm:items-center">
            <label htmlFor="fleet-type" className="block font-medium">
              Test Webhook Endpoint
            </label>
            <div className="relative w-full sm:max-w-[250px] lg:max-w-2xl sm:ml-4">
              <code className="block p-3 mt-2 text-sm bg-gray-100 rounded-md">
                {`Bearer ${TEST_KEY}`}
              </code>
            </div>
          </div>
          <div className="flex justify-end">
            <Button
              type={ButtonType.Button}
              onClick={(e) => {
                testWebhook(e);
              }}
              variant={ButtonVariant.AppOrange}
              size={ButtonSize.AppSize}
              disabled={isLoading}
            >
              {isLoading ? "Loading ..." : "Test"}
            </Button>
          </div>
          <div className="flex flex-col justify-between sm:flex-row sm:items-center">
            <label htmlFor="fleet-type" className="block font-medium">
              Authorization Bearer Token
            </label>
            <div className="relative w-full sm:max-w-[250px] lg:max-w-2xl sm:ml-4">
              <TextInput
                name="currentPassword"
                value={authorizationBearer ? authorizationBearer : ""}
                disabled
                variant="medium"
                placeholder=""
              ></TextInput>
            </div>
          </div>
          <div className="flex justify-end">
            <Button
              type={ButtonType.Submit}
              onClick={() => {
                handleSubmit();
              }}
              variant={ButtonVariant.AppOrange}
              size={ButtonSize.AppSize}
              disabled={isSubmitting || deleteIsSubmitting}
            >
              {authorizationBearer && <>Regenerate</>}
              {!authorizationBearer && <>Generate</>}
            </Button>
            {authorizationBearer && (
              <Button
                type={ButtonType.Submit}
                onClick={() => {
                  deleteHandleSubmit();
                }}
                variant={ButtonVariant.AppOrange}
                size={ButtonSize.AppSize}
                disabled={isSubmitting || deleteIsSubmitting}
                className="ml-2"
              >
                Delete
              </Button>
            )}
          </div>

          <div className="mt-4 space-y-8 text-gray-700 md:mt-8">
            <div className="p-3 py-4 font-mono text-sm bg-gray-100 rounded-md w-fit">
              <span className="p-2 font-bold text-white bg-green-500 rounded-md">
                POST
              </span>{" "}
              {baseUrl}/api/v1/fuelcard/transactions
            </div>
            <div className="flex flex-col md:flex-row">
              <div className="flex flex-col gap-4 md:mr-16 md:w-3/5">
                <div>
                  <h2 className="mb-3 text-xl font-semibold text-brown-500">
                    Transaction Webhook Endpoint
                  </h2>
                  <p className="text-justify text-gray-600">
                    This endpoint allows you to securely access your transaction
                    data. Using your unique authorization bearer token, fetch
                    your recent transactions. The webhook returns up to 100 of
                    your most recent transactions at a time and allows you
                    specify custom date ranges, up to 90 days back.<br></br>
                    <br></br>
                    Generate your authorization token and use it in your webhook
                    request calls.
                  </p>
                </div>
                <div>
                  <h3 className="pb-4 mb-3 font-semibold border-b text-brown-500">
                    Request Headers
                  </h3>
                  <div className="pl-4 space-y-4">
                    <div>
                      <p className="mb-1 font-mono">
                        <span className="font-semibold">Authorization</span>
                        <span className="ml-1 text-red-600">required</span>
                      </p>
                      <p className="ml-4 text-gray-600">
                        Your API authorization token used for authentication.
                      </p>
                    </div>

                    <div>
                      <p className="mb-1 font-mono">
                        <span className="font-semibold">Content-Type</span>
                        <span className="ml-1 text-red-600">required</span>
                      </p>
                      <p className="ml-4 text-gray-600">
                        Specifies the media type of the resource. Use
                        &ldquo;application/json&ldquo; when sending JSON data in
                        the request body.
                      </p>
                    </div>
                  </div>
                </div>
                <div>
                  <h3 className="pb-4 mb-3 font-semibold border-b text-brown-500">
                    Request Parameters
                  </h3>
                  <div className="pl-4 space-y-4">
                    <div>
                      <p className="mb-1 font-mono">
                        <span className="font-semibold">startDate</span>
                        <span className="ml-1 text-gray-500">optional</span>
                      </p>
                      <p className="ml-4 text-gray-600">
                        Start date for transaction query in ISO 8601 format. If
                        not provided, defaults to start of current day UTC.
                        <br />
                        <span className="text-sm text-gray-500">
                          Example: 2025-01-01T00:00:00Z
                        </span>
                      </p>
                    </div>

                    <div>
                      <p className="mb-1 font-mono">
                        <span className="font-semibold">endDate</span>
                        <span className="ml-1 text-gray-500">optional</span>
                      </p>
                      <p className="ml-4 text-gray-600">
                        End date for transaction query in ISO 8601 format. If
                        not provided, defaults to end of current day UTC.
                        <br />
                        <span className="text-sm text-gray-500">
                          Example: 2025-01-31T23:59:59Z
                        </span>
                      </p>
                    </div>

                    <div>
                      <p className="mb-1 font-mono">
                        <span className="font-semibold">page</span>
                        <span className="ml-1 text-gray-500">optional</span>
                      </p>
                      <p className="ml-4 text-gray-600">
                        Page number of the transaction data to retrieve (default
                        = 1).
                        <br />
                        <span className="text-sm text-gray-500">
                          Example: 2
                        </span>
                      </p>
                    </div>

                    <div>
                      <p className="mb-1 font-mono">
                        <span className="font-semibold">pageSize</span>
                        <span className="ml-1 text-gray-500">optional</span>
                      </p>
                      <p className="ml-4 text-gray-600">
                        Number of transaction records per page (default = 100).
                        <br />
                        <span className="text-sm text-gray-500">
                          Example: 50
                        </span>
                      </p>
                    </div>
                  </div>
                </div>

                <div>
                  <h3 className="pb-4 mb-3 font-semibold border-b text-brown-500">
                    Returns
                  </h3>
                  <div className="pl-4 space-y-4">
                    <div>
                      <p className="mb-1 font-mono">
                        <span className="font-semibold">message</span>
                        <span className="ml-1 text-blue-600">string</span>
                      </p>
                      <p className="ml-4 text-gray-600">
                        Status message of the request
                      </p>
                    </div>

                    <div>
                      <p
                        className="mb-1 font-mono cursor-pointer"
                        onClick={() => setIsDataOpen(!isDataOpen)}
                      >
                        <span className="font-semibold">data</span>
                        <span className="ml-1 text-blue-600">array</span>
                        <span
                          className={`ml-2 text-sm ${
                            isDataOpen ? "rotate-90" : ""
                          }`}
                        >
                          {isDataOpen ? "▼" : "▶"}
                        </span>
                      </p>
                      <p className="ml-4 text-gray-600">
                        Array of transaction objects. Limited to 100 most recent
                        entries.
                      </p>

                      {isDataOpen && (
                        <div className="pl-4 mt-2 space-y-4 border-l border-gray-300">
                          <div>
                            <p className="mb-1 font-mono">
                              <span className="font-semibold">data[].id</span>
                              <span className="ml-1 text-blue-600">string</span>
                            </p>
                            <p className="ml-4 text-gray-600">
                              Unique identifier for the transaction.
                            </p>
                          </div>

                          <div>
                            <p className="mb-1 font-mono">
                              <span className="font-semibold">
                                data[].transactionAmount
                              </span>
                              <span className="ml-1 text-blue-600">
                                integer
                              </span>
                            </p>
                            <p className="ml-4 text-gray-600">
                              The transaction amount in USD, in cents.
                            </p>
                          </div>

                          <div>
                            <p className="mb-1 font-mono">
                              <span className="font-semibold">
                                data[].authorizationDate
                              </span>
                              <span className="ml-1 text-blue-600">string</span>
                            </p>
                            <p className="ml-4 text-gray-600">
                              Timestamp when the transaction was authorized, in
                              ISO 8601 format.
                              <br />
                              <span className="text-sm text-gray-500">
                                Example: 2025-01-15T10:30:00Z
                              </span>
                            </p>
                          </div>

                          <div>
                            <p className="mb-1 font-mono">
                              <span className="font-semibold">
                                data[].merchantName
                              </span>
                              <span className="ml-1 text-blue-600">string</span>
                            </p>
                            <p className="ml-4 text-gray-600">
                              Name of the merchant where the transaction
                              occurred.
                            </p>
                          </div>

                          <div>
                            <p
                              className="mb-1 font-mono cursor-pointer"
                              onClick={() => setIsFuelOpen(!isFuelOpen)}
                            >
                              <span className="font-semibold">data[].fuel</span>
                              <span className="ml-1 text-blue-600">
                                object | null
                              </span>
                              <span
                                className={`ml-2 text-sm ${
                                  isFuelOpen ? "rotate-90" : ""
                                }`}
                              >
                                {isFuelOpen ? "▼" : "▶"}
                              </span>
                            </p>
                            <p className="ml-4 text-gray-600">
                              Fuel-related details for the transaction. Null for
                              non-fuel transactions.
                            </p>

                            {isFuelOpen && (
                              <div className="pl-4 mt-2 space-y-2 border-l border-gray-300">
                                <div>
                                  <p className="mb-1 font-mono">
                                    <span className="font-semibold">
                                      data[].fuel.quantity
                                    </span>
                                    <span className="ml-1 text-blue-600">
                                      number
                                    </span>
                                  </p>
                                  <p className="ml-4 text-gray-600">
                                    Quantity of fuel purchased, in gallons.
                                  </p>
                                </div>

                                <div>
                                  <p className="mb-1 font-mono">
                                    <span className="font-semibold">
                                      data[].fuel.type
                                    </span>
                                    <span className="ml-1 text-blue-600">
                                      string
                                    </span>
                                  </p>
                                  <p className="ml-4 text-gray-600">
                                    Type of fuel purchased (e.g., Unleaded
                                    Regular, Unleaded Plus, Diesel).
                                  </p>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </div>

                    <div>
                      <p className="mb-1 font-mono">
                        <span className="font-semibold">count</span>
                        <span className="ml-1 text-blue-600">integer</span>
                      </p>
                      <p className="ml-4 text-gray-600">
                        Total number of transactions returned in the response.
                      </p>
                    </div>

                    <div>
                      <p
                        className="mb-1 font-mono cursor-pointer"
                        onClick={() => setIsPaginationOpen(!isPaginationOpen)}
                      >
                        <span className="font-semibold">pagination</span>
                        <span className="ml-1 text-blue-600">object</span>
                        <span
                          className={`ml-2 text-sm ${
                            isPaginationOpen ? "rotate-90" : ""
                          }`}
                        >
                          {isPaginationOpen ? "▼" : "▶"}
                        </span>
                      </p>
                      <p className="ml-4 text-gray-600">
                        Pagination details for the response.
                      </p>
                      {isPaginationOpen && (
                        <div className="pl-4 mt-2 space-y-2 border-l border-gray-300">
                          <div>
                            <p className="mb-1 font-mono">
                              <span className="font-semibold">
                                pagination.totalCount
                              </span>
                              <span className="ml-1 text-blue-600">
                                integer
                              </span>
                            </p>
                            <p className="ml-4 text-gray-600">
                              Total number of transactions available.
                            </p>
                          </div>

                          <div>
                            <p className="mb-1 font-mono">
                              <span className="font-semibold">
                                pagination.currentPage
                              </span>
                              <span className="ml-1 text-blue-600">
                                integer
                              </span>
                            </p>
                            <p className="ml-4 text-gray-600">
                              Current page number (default = 1).
                            </p>
                          </div>

                          <div>
                            <p className="mb-1 font-mono">
                              <span className="font-semibold">
                                pagination.totalPages
                              </span>
                              <span className="ml-1 text-blue-600">
                                integer
                              </span>
                            </p>
                            <p className="ml-4 text-gray-600">
                              Total number of pages based on available
                              transactions.
                            </p>
                          </div>

                          <div>
                            <p className="mb-1 font-mono">
                              <span className="font-semibold">
                                pagination.pageSize
                              </span>
                              <span className="ml-1 text-blue-600">
                                integer
                              </span>
                            </p>
                            <p className="ml-4 text-gray-600">
                              Number of items per page (default = 100).
                            </p>
                          </div>

                          <div>
                            <p className="mb-1 font-mono">
                              <span className="font-semibold">
                                pagination.hasMore
                              </span>
                              <span className="ml-1 text-blue-600">
                                boolean
                              </span>
                            </p>
                            <p className="ml-4 text-gray-600">
                              Indicates if more pages are available.
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-4 mt-4 md:mt-0 md:w-2/5">
                <div className="overflow-hidden border border-gray-300 rounded-lg">
                  <div className="px-4 py-2 font-medium text-gray-100 bg-[#424751] border-b border-gray-300">
                    <span className="text-green-500">POST</span>{" "}
                    /api/v1/fuelcard/transactions
                  </div>
                  <div className="px-4 py-2 text-xs font-medium text-gray-100 bg-[#343941]">
                    AUTH
                  </div>
                  <CopyBlock
                    text={JSON.stringify(sampleAuthorization, null, 2)}
                    language="json"
                    showLineNumbers={true}
                    theme={atomOneDark}
                    codeBlock
                    customStyle={{
                      borderRadius: "0px",
                      borderBottom: "1px solid #D1D5DB",
                    }}
                  />
                  <div className="px-4 py-2 text-xs font-medium text-gray-100 bg-[#343941]">
                    PARAMETERS
                  </div>
                  <CopyBlock
                    text={JSON.stringify(sampleRequest, null, 2)}
                    language="json"
                    showLineNumbers={true}
                    theme={atomOneDark}
                    codeBlock
                    customStyle={{
                      borderRadius: "0px",
                    }}
                  />
                </div>

                <div className="overflow-hidden border border-gray-300 rounded-lg">
                  <div className="px-4 py-2 font-medium text-gray-800 bg-gray-100 border-b border-gray-300">
                    RESPONSE
                  </div>
                  <CodeBlock
                    text={JSON.stringify(sampleResponse, null, 2)}
                    language="json"
                    showLineNumbers={true}
                    theme={atomOneLight}
                    customStyle={{
                      borderRadius: "0px",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};
export default DeveloperControlCenterUI;
