/** @format */
import { useMutation } from "@apollo/client";
import { genericSignInValidationSchema } from "@roadflex/constants";
import { ADMIN_SIGN_IN, READ_CURRENT_ADMIN } from "@roadflex/graphql";
import { useFormik } from "formik";

type UseSignInProps = {
  onSubmit: (val: any, error: any) => void;
  initialValues: {
    email: string;
    password: string;
    otp: string;
    isOtpVerify: boolean;
  };
};

export const useAdminSignin = ({ onSubmit, initialValues }: UseSignInProps) => {
  const [signInFn] = useMutation(ADMIN_SIGN_IN, {
    refetchQueries: [READ_CURRENT_ADMIN],
  });

  const {
    handleChange,
    handleSubmit,
    handleBlur,
    values,
    isSubmitting,
    ...rest
  } = useFormik({
    initialValues,
    validationSchema: genericSignInValidationSchema,
    onSubmit: async (value) => {
      try {
        const res = await signInFn({
          variables: { data: value },
        });
        onSubmit(res, null);
      } catch (error) {
        onSubmit(null, error);
      }
    },
  });

  return {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    ...rest,
  };
};
