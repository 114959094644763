/* eslint-disable dot-notation */
/** @format */
import { useMutation } from "@apollo/client";
import { ENUM_CUSTOMER_TYPE } from "@prisma/client";
import { ADMIN_MOVE_APPROVED_BUSINESS_TO_PREPAID } from "@roadflex/graphql";
import { useFormik } from "formik";

type Values = {
  userId: string;
  customerType: ENUM_CUSTOMER_TYPE | null;
};

type UseApprovalProps = {
  initialValues: Values;
  onSubmit: (val: any, err: any) => void;
};

export const useMoveApprovedUserToPrepaid = ({
  onSubmit,
  initialValues,
}: UseApprovalProps) => {
  const [moveApprovedBusinessToPrepaid] = useMutation(
    ADMIN_MOVE_APPROVED_BUSINESS_TO_PREPAID,
  );

  const { handleSubmit, isSubmitting, setFieldValue, ...rest } = useFormik({
    initialValues,
    onSubmit: async (value) => {
      const data = {
        ...value,
      };
      try {
        const res = await moveApprovedBusinessToPrepaid({
          variables: { data },
        });
        onSubmit(res, null);
      } catch (error) {
        onSubmit(null, error);
      }
    },
  });

  return {
    handleSubmit,
    isSubmitting,
    setFieldValue,
    ...rest,
  };
};
