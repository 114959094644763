import { FilterMatchMode, FilterOperator } from "primereact/api";
import { DataTablePFSEvent } from "primereact/datatable";
import { FilterConfigType } from "../types";

export const transactionHeaderAll = [
  { label: "Date", key: "authorizationDate" },
  { label: "Posted Date", key: "transactionDate" },
  { label: "Timezone Reported In", key: "timezone" },
  { label: "Driver Name", key: "driverName" },
  { label: "Vehicle Name", key: "vehicleName" },
  { label: "VIN", key: "vin" },
  { label: "Odometer Reading", key: "odometerReading" },
  { label: "Location", key: "location" },
  { label: "Card Last Four", key: "lastFour" },
  { label: "Policy Applied", key: "policyName" },
  { label: "Merchant", key: "merchantName" },
  { label: "Merchant Category", key: "merchantCategory" },
  { label: "Amount", key: "amount" },
  { label: "Discount", key: "discount" },
  { label: "Fuel Type", key: "fuelType" },
  { label: "Fuel Category", key: "fuelCategory" },
  { label: "Fuel in Gallons", key: "gallons" },
  { label: "Price per Gallon", key: "pricePerGallon" },
  { label: "Status", key: "status" },
  { label: "Memo", key: "memo" },
  { label: "Transaction Tag Name", key: "transactionTagName" },
  { label: "Transaction Tag ID", key: "transactionTagId" },
  { label: "Transaction Tag Description", key: "transactionTagDescription" },
  { label: "Card Tag Name", key: "cardTagName" },
  { label: "Card Tag ID", key: "cardTagId" },
  { label: "Card Tag Description", key: "cardTagDescription" },
  { label: "Driver Tag Names", key: "driverTagName" },
  { label: "Driver Tag IDs", key: "driverTagId" },
  { label: "Driver Tag Descriptions", key: "driverTagDescription" },
  { label: "Vehicle Tag Names", key: "vehicleTagName" },
  { label: "Vehicle Tag IDs", key: "vehicleTagId" },
  { label: "Vehicle Tag Descriptions", key: "vehicleTagDescription" },
];

export const authorizationHeaders = [
  { label: "Date", key: "authorizationDate" },
  { label: "Timezone Reported In", key: "timezone" },
  { label: "Driver Name", key: "driverName" },
  { label: "Vehicle Name", key: "vehicleName" },
  { label: "VIN", key: "vin" },
  { label: "Odometer Reading", key: "odometerReading" },
  { label: "Location", key: "location" },
  { label: "Card Last Four", key: "lastFour" },
  { label: "Policy Applied", key: "policyName" },
  { label: "Merchant", key: "merchantName" },
  { label: "Merchant Category", key: "merchantCategory" },
  { label: "Amount", key: "amount" },
  { label: "Status", key: "status" },
  { label: "Memo", key: "memo" },
  { label: "Transaction Tag Name", key: "transactionTagName" },
  { label: "Transaction Tag ID", key: "transactionTagId" },
  { label: "Transaction Tag Description", key: "transactionTagDescription" },
  { label: "Card Tag Name", key: "cardTagName" },
  { label: "Card Tag ID", key: "cardTagId" },
  { label: "Card Tag Description", key: "cardTagDescription" },
  { label: "Driver Tag Names", key: "driverTagName" },
  { label: "Driver Tag IDs", key: "driverTagId" },
  { label: "Driver Tag Descriptions", key: "driverTagDescription" },
  { label: "Vehicle Tag Names", key: "vehicleTagName" },
  { label: "Vehicle Tag IDs", key: "vehicleTagId" },
  { label: "Vehicle Tag Descriptions", key: "vehicleTagDescription" },
];

export const initFilterConfig: FilterConfigType = {
  date: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
  },
  merchantName: {
    operator: FilterOperator.OR,
    constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
  },
  merchantState: {
    operator: FilterOperator.OR,
    constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
  },
  transactionAmount: {
    operator: FilterOperator.OR,
    constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
  },
  status: {
    operator: FilterOperator.OR,
    constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
  },
  lastFour: { value: null, matchMode: FilterMatchMode.IN },
  driver: { value: null, matchMode: FilterMatchMode.IN },
  vehicle: { value: null, matchMode: FilterMatchMode.IN },
  vehicleVIN: { value: null, matchMode: FilterMatchMode.IN },
};

export const transactionInitialLazyParamsConst: DataTablePFSEvent = {
  first: 0,
  rows: 10,
  page: 0,
  filters: initFilterConfig,
  sortField: "",
  sortOrder: 1,
  multiSortMeta: null,
  isReport: false,
};

export const authorizationInitialLazyParamsConst: DataTablePFSEvent = {
  first: 0,
  rows: 10,
  page: 0,
  filters: initFilterConfig,
  sortField: "date",
  sortOrder: -1,
  multiSortMeta: [],
  isReport: true,
};
