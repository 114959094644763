/** @format */
import { useMutation, useQuery } from "@apollo/client";
import { ENUM_CUSTOMER_TYPE } from "@prisma/client";
import { adminUpdateFuelDiscount } from "@roadflex/constants";
import {
  ADMIN_READ_BONUS_AND_FUEL_DISCOUNT,
  ADMIN_READ_CARD_SETTING,
  ADMIN_UPDATE_APPROVE_UNDERWRITING_AUTOMATION,
  ADMIN_UPDATE_BONUS_AND_FUEL_DISCOUNT,
  ADMIN_UPDATE_CARD_SETTING,
} from "@roadflex/graphql";
import { BonusType, FuelDiscountType } from "@roadflex/types";
import { useFormik } from "formik";

type useUpdateBonusAndFuelDiscountProps = {
  initialValues: {
    mode: string;
    referralBonus: BonusType;
    signupBonus: BonusType;
    fuelDiscount: FuelDiscountType;
  };
  onSubmit: (val: any, err: any) => void;
};

type useUpdateAdminCardSettingProps = {
  initialValues: {
    mode: string;
    id: string;
    dailyLimit: any;
    transactionLimit: any;
    categories: string;
    customerType: ENUM_CUSTOMER_TYPE;
    restrictedMerchants: string;
  };
  onSubmit: (val: any, err: any) => void;
};

type useUpdateAdminApproveUnderwritingAutomationProps = {
  initialValues: {
    status: boolean;
  };
  onSubmit: (val: any, err: any) => void;
};

export const useReadBonusAndFuelDiscount = (mode: string) => {
  const {
    data: adminBonusFuelDiscountData,
    refetch: adminBonusFuelDiscountRefetch,
    loading: adminBonusFuelDiscountLoading,
  } = useQuery<{
    readBonusAndFuelDiscount: {
      code: string;
      message: string;
      referralBonus: BonusType;
      signupBonus: BonusType;
      fuelDiscount: FuelDiscountType;
    };
  }>(ADMIN_READ_BONUS_AND_FUEL_DISCOUNT, {
    variables: {
      data: {
        mode,
      },
    },
    fetchPolicy: "no-cache",
  });

  return {
    adminBonusFuelDiscountData,
    adminBonusFuelDiscountRefetch,
    adminBonusFuelDiscountLoading,
  };
};

export const useReadAdminCardSetting = () => {
  const {
    data: adminCardSettingData,
    refetch: adminCardSettingRefetch,
    loading: adminCardSettingLoading,
  } = useQuery<{
    readAdminCardSetting: {
      data: {
        id: string;
        dailyLimit: number;
        transactionLimit: number;
        categories: string;
        customerType: ENUM_CUSTOMER_TYPE;
        restrictedMerchants: string;
      }[];
      code: string;
      message: string;
    };
  }>(ADMIN_READ_CARD_SETTING);

  return {
    adminCardSettingData,
    adminCardSettingRefetch,
    adminCardSettingLoading,
  };
};

export const useUpdateBonusAndFuelDiscount = ({
  onSubmit,
  initialValues,
}: useUpdateBonusAndFuelDiscountProps) => {
  const [updateFuelDiscountAndBonusFn] = useMutation<{
    updateFuelDiscountAndBonus: {
      code: string;
      message: string;
    };
  }>(ADMIN_UPDATE_BONUS_AND_FUEL_DISCOUNT);

  const {
    handleChange: adminBonusAndFuelDiscountHandleChange,
    handleSubmit: adminBonusAndFuelDiscountHandleSubmit,
    values: adminBonusAndFuelDiscountValues,
    isSubmitting: adminBonusAndFuelDiscountIsSubmitting,
    setFieldValue: adminBonusAndFuelDiscountSetFieldValue,
    handleBlur: adminBonusAndFuelDiscountHandleBlur,
    touched: adminBonusAndFuelDiscountTouched,
    setFieldTouched: adminBonusAndFuelDiscountSetFieldTouched,
    errors: adminBonusAndFuelDiscountErrors,
  } = useFormik({
    initialValues,
    validationSchema: adminUpdateFuelDiscount,
    onSubmit: async (values) => {
      const data = {
        mode: values?.mode,
        fuelDiscount: {
          creditFuelDiscount: values?.fuelDiscount?.creditFuelDiscount * 100,
          creditPlusFuelDiscount:
            values?.fuelDiscount?.creditPlusFuelDiscount * 100,
          prepaidFuelDiscount: values?.fuelDiscount?.prepaidFuelDiscount * 100,
          prepaidPlusFuelDiscount:
            values?.fuelDiscount?.prepaidPlusFuelDiscount * 100,
        },
        referralBonus: {
          id: values?.referralBonus?.id,
          bonusType: values?.referralBonus?.bonusType,
          fuelQuantity: values?.referralBonus?.fuelQuantity,
          days: values?.referralBonus?.days,
          bonusAmount: values?.referralBonus?.bonusAmount * 100,
        },
        signupBonus: {
          id: values?.signupBonus?.id,
          bonusType: values?.signupBonus?.bonusType,
          fuelQuantity: values?.signupBonus?.fuelQuantity,
          bonusAmount: values?.signupBonus?.bonusAmount * 100,
        },
      };
      try {
        const res = await updateFuelDiscountAndBonusFn({
          variables: { data },
        });
        if (res?.data?.updateFuelDiscountAndBonus) {
          onSubmit(res?.data?.updateFuelDiscountAndBonus, null);
        }
      } catch (error) {
        if (error instanceof Error) {
          onSubmit(null, error);
        } else {
          onSubmit(null, new Error("Something went wrong"));
        }
      }
    },
  });
  return {
    adminBonusAndFuelDiscountHandleChange,
    adminBonusAndFuelDiscountHandleSubmit,
    adminBonusAndFuelDiscountValues,
    adminBonusAndFuelDiscountIsSubmitting,
    adminBonusAndFuelDiscountSetFieldValue,
    adminBonusAndFuelDiscountHandleBlur,
    adminBonusAndFuelDiscountTouched,
    adminBonusAndFuelDiscountSetFieldTouched,
    adminBonusAndFuelDiscountErrors,
  };
};

export const useUpdateAdminCardSetting = ({
  onSubmit,
  initialValues,
}: useUpdateAdminCardSettingProps) => {
  const [updateAdminCardSettingFn] = useMutation<{
    updateAdminCardSetting: {
      code: string;
      message: string;
    };
  }>(ADMIN_UPDATE_CARD_SETTING);

  const {
    handleChange: updateAdminCardSettingHandleChange,
    handleSubmit: updateAdminCardSettingHandleSubmit,
    values: updateAdminCardSettingValues,
    setFieldValue: updateAdminCardSettingSetFieldValue,
    isSubmitting: updateAdminCardSettingSubmitting,
  } = useFormik({
    initialValues,
    onSubmit: async (values) => {
      const data = {
        ...values,
        dailyLimit: values.dailyLimit * 100,
        transactionLimit: values.transactionLimit * 100,
      };
      try {
        const res = await updateAdminCardSettingFn({
          variables: { data },
        });
        if (res?.data?.updateAdminCardSetting) {
          onSubmit(res?.data?.updateAdminCardSetting, null);
        }
      } catch (error) {
        if (error instanceof Error) {
          onSubmit(null, error);
        } else {
          onSubmit(null, new Error("Something went wrong"));
        }
      }
    },
  });

  return {
    updateAdminCardSettingHandleChange,
    updateAdminCardSettingHandleSubmit,
    updateAdminCardSettingValues,
    updateAdminCardSettingSetFieldValue,
    updateAdminCardSettingSubmitting,
  };
};

export const useUpdateAdminApproveUnderwritingAutomation = ({
  onSubmit,
  initialValues,
}: useUpdateAdminApproveUnderwritingAutomationProps) => {
  const [updateAdminApproveUnderwritingAutomationFn] = useMutation<{
    updateAdminUnderwritingAutomation: {
      code: string;
      message: string;
    };
  }>(ADMIN_UPDATE_APPROVE_UNDERWRITING_AUTOMATION);

  const {
    handleChange,
    handleSubmit,
    handleBlur,
    values,
    isSubmitting,
    ...rest
  } = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit: async (value) => {
      try {
        const res = await updateAdminApproveUnderwritingAutomationFn({
          variables: { data: value },
        });
        if (res?.data?.updateAdminUnderwritingAutomation) {
          onSubmit(res?.data?.updateAdminUnderwritingAutomation, null);
        }
      } catch (error) {
        if (error instanceof Error) {
          onSubmit(null, error);
        } else {
          onSubmit(null, new Error("Something went wrong"));
        }
      }
    },
  });

  return {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    ...rest,
  };
};
