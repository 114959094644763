/** @format */

export const classes = {
  base: "outline-none focus:outline-none transition ease-in-out duration-300 flex items-center justify-center",
  disabled: "opacity-50 cursor-not-allowed",
  pill: "rounded-full",
  size: {
    small: "px-2 py-1 text-sm",
    appSize: "px-2 md:px-4 py-1 md:py-2 text-sm",
    normal: "px-2 md:px-4 py-1 md:py-2 text-sm md:text-base",
    large: "px-6 py-2 text-lg",
    wrap: "px-0 py-0 m-0",
  },
  variant: {
    primary:
      "bg-primary-500 focus:ring-2 focus:ring-primary-500 focus:ring-opacity-50 text-white rounded-md",
    secondary:
      "bg-secondary-500 focus:ring-2 focus:ring-secondary-500 focus:ring-opacity-50 text-primary-500 rounded-md",
    fleetUpYellow:
      "bg-[#EAAB1E] focus:ring-2 focus:ring-[#EAAB1E] focus:ring-opacity-50 text-white rounded-md",
    orange:
      "bg-orange-500 focus:ring-2 focus:ring-orange-500 focus:ring-opacity-50 text-white rounded-md",
    appOrange: "bg-orange-100 text-brown-500 rounded-md",
    primaryOutline:
      "bg-transparent border border-solid border-primary-500 rounded-md",
    secondaryOutline:
      "bg-transparent border border-solid border-secondary-500 rounded-md",
    orangeOutline:
      "bg-transparent border border-solid border-orange-500 group hover:bg-orange-500 hover:text-white active:bg-orange-600 rounded-md",
    orangeLightOutline:
      "bg-transparent border border-solid border-orange-700 hover:bg-orange-100 active:bg-orange-700 rounded-md",
    transparent: "bg-transparent border border-transparent rounded-md",
    gray: "bg-scarpa-flow-100 focus:ring-2 focus:ring-scarpa-flow-100 focus:ring-opacity-50 text-white rounded-md",
    grayOutline:
      "bg-transparent border border-solid border-scarpa-flow-100 group rounded-md",
    grayOutlineWhiteText:
      "bg-transparent border border-solid border-scarpa-flow-100 group active:bg-scarpa-flow-200 rounded-md text-white",
    green:
      "inline-flex items-center text-white bg-green-600 border border-transparent rounded-md shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500",
    red: "bg-red-500 hover:bg-red-500 focus:ring-red-500 focus:ring-2 border-transparent rounded-md focus:ring-opacity-50 text-white",
    white:
      "inline-flex items-center text-black bg-white border border-transparent rounded-md shadow-sm focus:outline-none focus:ring-0 focus:ring-offset-0 focus:ring-dullwhite",
    black:
      "bg-black hover:bg-black focus:ring-2 focus:ring-black border-transparent focus:ring-opacity-50 text-white rounded-md",
    brown:
      "bg-brown focus:ring-2 focus:ring-brown focus:ring-opacity-50 text-white rounded-md",
    square:
      "bg-transparent focus:ring-2 focus:ring-brown border-scarpa-flow-100 border border-solid focus:ring-opacity-50 text-black",
  },
  loader: {
    primary: "text-white",
    secondary: "text-white",
    orange: "text-white group-hover:text-white",
    primaryOutline: "text-white",
    secondaryOutline: "text-white",
    orangeOutline: "text-white",
    orangeLightOutline: "text-white",
    transparent: "text-white",
    gray: "text-white",
    grayOutline: "text-scarpa-flow-100",
    grayOutlineWhiteText: "text-white",
    green: "text-white",
    red: "text-white",
    white: "text-white",
    black: "text-white",
    brown: "text-white",
  },
};

export enum ButtonType {
  Submit = "submit",
  Button = "button",
}

export enum ButtonSize {
  Small = "small",
  AppSize = "appSize",
  Normal = "normal",
  Large = "large",
  Wrap = "wrap",
}

export enum ButtonVariant {
  Primary = "primary",
  PrimaryOutline = "primaryOutline",
  Secondary = "secondary",
  SecondaryOutline = "secondaryOutline",
  Orange = "orange",
  OrangeOutline = "orangeOutline",
  OrangeLightOutline = "orangeLightOutline",
  Transparent = "transparent",
  Green = "green",
  Red = "red",
  Gray = "gray",
  GrayOutline = "grayOutline",
  GrayOutlineWhiteText = "grayOutlineWhiteText",
  White = "white",
  Black = "black",
  Brown = "brown",
  Square = "square",
  Borderless = "borderless",
  AppOrange = "appOrange",
  FleetUpYellow = "fleetUpYellow",
}
