/** @format */
import { ArrowUpTrayIcon } from "@heroicons/react/24/outline";
import {
  BillingCycleType,
  ExtendedTransactionType,
  QuickBookDetailsType,
} from "@roadflex/types";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { useState } from "react";

import getConfig from "next/config";
import Image from "next/image";
import { Button, ButtonSize, ButtonVariant } from "../../buttons";
import { GenericExportModal } from "../../modals/generic-export-modal/generic-export-modal";

const { publicRuntimeConfig } = getConfig();
const { NEXT_PUBLIC_CDN_URL } = publicRuntimeConfig;

// Types for the filters used in this component
type FilterProps = "date" | "transactionAmount";

type AccountingUIProps = {
  transactionList: ExtendedTransactionType[];
  billingList: BillingCycleType[];
  transactionLoading: boolean;
  isPrepaidCustomer: boolean;
  transactionsFilterConfig: {
    [key in FilterProps]: {
      operator: FilterOperator;
      constraints: {
        value: null | string | Date;
        matchMode: FilterMatchMode;
      }[];
    };
  };
  setTransactionFilterConfig: (data: {
    [key in FilterProps]: {
      operator: FilterOperator;
      constraints: [
        { value: null | string | Date; matchMode: FilterMatchMode },
      ];
    };
  }) => void;
  initFilterConfig: {
    [key in FilterProps]: {
      operator: FilterOperator;
      constraints: [
        { value: null | string | Date; matchMode: FilterMatchMode },
      ];
    };
  };

  showComplete: boolean;
  setShowComplete: (value: boolean) => void;
  showLoading: boolean;
  setShowLoading: (value: boolean) => void;
  dates: Date | Date[] | undefined;
  setDates: (value: Date | Date[] | undefined) => void;
  fetchSoaData: () => void;

  // QuickBooks data
  quickbookData: QuickBookDetailsType | null;
  connectToQuickbooks: () => void;
  disconnectQuickbooks: () => void;
  submitting: boolean;
};

export default function AccountingUI({
  transactionList,
  billingList,
  transactionLoading,
  setTransactionFilterConfig,
  transactionsFilterConfig,
  isPrepaidCustomer,
  initFilterConfig,

  showLoading,
  setShowLoading,
  showComplete,
  setShowComplete,
  dates,
  setDates,
  fetchSoaData,

  quickbookData,
  submitting,
  connectToQuickbooks,
  disconnectQuickbooks,
}: AccountingUIProps) {
  // State for the generic export modal
  const [openExportModal, setOpenExportModal] = useState(false);
  const [title, setTitle] = useState("");

  // Called when user applies a new date filter
  const dateFilterApplied = (e: unknown) => {
    const event = e as {
      field: string;
      constraints: {
        operator: FilterOperator;
        constraints: [
          { value: null | string | Date; matchMode: FilterMatchMode },
        ];
      };
    };

    setTransactionFilterConfig({
      ...initFilterConfig,
      date: {
        operator: event.constraints.operator || initFilterConfig.date.operator,
        constraints:
          event.constraints.constraints || initFilterConfig.date.constraints,
      },
    });
  };

  return (
    <div className="flex flex-col flex-1 m-4 mt-0 overflow-x-hidden md:px-4 md:pb-4 h-full md:min-h-[calc(100vh-96px)]">
      <div className="text-xl font-bold md:my-12 text-brown-500 md:text-2xl">
        {`Accounting`.toUpperCase()}
      </div>

      {/* =====================
          QUICKBOOKS INTEGRATION
      ===================== */}
      <div className="flex flex-col h-auto mt-5 md:mt-0">
        <div className="flex flex-col h-full p-6 bg-white border border-gray-200 rounded-md shadow-sm">
          {/* Logo Area */}
          <div className="flex items-center justify-center p-6 rounded-md bg-gray-50">
            <div className="flex items-center gap-6">
              <div className="relative w-28 md:w-32">
                <Image
                  className="hover:cursor-pointer"
                  src={`${NEXT_PUBLIC_CDN_URL}/integrations/images/quickbooks-logo.png`}
                  alt="QuickBooks logo"
                  layout="responsive"
                  width={2560}
                  height={656}
                />
              </div>
              <span className="text-2xl font-bold text-brown-500">+</span>
              <div className="relative w-28 md:w-32 right-2">
                <Image
                  className="hover:cursor-pointer"
                  src={`${NEXT_PUBLIC_CDN_URL}/partners/images/roadflex-partner-logo.png`}
                  alt="RoadFlex logo"
                  layout="responsive"
                  width={450}
                  height={80}
                />
              </div>
            </div>
          </div>

          {/* Header & Action Button */}
          <div className="flex flex-col-reverse items-start justify-between gap-6 pt-6 md:flex-row md:items-center">
            <div>
              <h2 className="text-lg font-semibold text-brown-500">
                QuickBooks Integration
              </h2>
              <p className="text-sm leading-relaxed text-gray-700 md:text-base">
                Automatically sync your RoadFlex settled transactions to
                QuickBooks every day. Manage, categorize, and reconcile with
                ease!
              </p>
            </div>

            <div className="flex flex-col md:flex-row md:items-center md:gap-4">
              {!quickbookData ? (
                <Button
                  variant={ButtonVariant.AppOrange}
                  onClick={connectToQuickbooks}
                  loading={submitting}
                  className="whitespace-nowrap"
                >
                  Connect to QuickBooks
                </Button>
              ) : quickbookData.isAuthenticated ? (
                <div className="flex items-center gap-2">
                  <p className="text-sm font-semibold text-green-700">
                    Connected
                  </p>
                  <Button
                    variant={ButtonVariant.AppOrange}
                    onClick={disconnectQuickbooks}
                    loading={submitting}
                  >
                    Disconnect
                  </Button>
                </div>
              ) : (
                <Button
                  variant={ButtonVariant.AppOrange}
                  onClick={connectToQuickbooks}
                  loading={submitting}
                  className="whitespace-nowrap"
                >
                  Re-authentication Required
                </Button>
              )}
            </div>
          </div>

          {/* Body Content: Explanation & Steps */}
          <div className="flex flex-col mt-8 md:flex-row md:gap-8">
            <div className="flex-1 mb-8 md:mb-0">
              <h3 className="mb-2 font-semibold text-md text-brown-500">
                Say Goodbye to Painful Month-End Processes
              </h3>
              <p className="text-sm leading-relaxed text-gray-700 md:text-base">
                Once connected, all your RoadFlex settled transactions, fees,
                and credits will flow seamlessly into QuickBooks each night at
                midnight UTC. You'll be able to categorize and reconcile them
                right inside QuickBooks, saving you hours of manual entry and
                reconciliation headaches.
              </p>
              {!quickbookData?.isAuthenticated && (
                <p className="mt-4 text-sm leading-relaxed text-gray-700 md:text-base">
                  Follow these quick steps to get your RoadFlex account synced
                  with QuickBooks.
                </p>
              )}
            </div>

            {!quickbookData?.isAuthenticated ? (
              <div className="flex-1 space-y-6">
                <div className="flex flex-row items-center gap-4">
                  <div className="flex items-center justify-center w-10 h-10 px-4 py-4 text-white rounded-full bg-brown-500 md:w-12 md:h-12 md:text-lg">
                    1
                  </div>
                  <div className="text-sm md:text-base">
                    Click{" "}
                    <span className="font-semibold">
                      "Connect to QuickBooks"
                    </span>{" "}
                    to begin the authorization process.
                  </div>
                </div>
                <div className="flex flex-row items-center gap-4">
                  <div className="flex items-center justify-center w-10 h-10 px-4 py-4 text-white rounded-full bg-brown-500 md:w-12 md:h-12 md:text-lg">
                    2
                  </div>
                  <div className="text-sm md:text-base">
                    Choose your correct{" "}
                    <span className="font-semibold">QuickBooks company</span>{" "}
                    when prompted.
                  </div>
                </div>
                <div className="flex flex-row items-center gap-4">
                  <div className="flex items-center justify-center w-10 h-10 px-4 py-4 text-white rounded-full bg-brown-500 md:w-12 md:h-12 md:text-lg">
                    3
                  </div>
                  <div className="text-sm md:text-base">
                    Finalize & return here to confirm your account is{" "}
                    <span className="font-semibold">linked successfully</span>.
                  </div>
                </div>
              </div>
            ) : (
              <div className="flex-1 px-4 py-6 border rounded-md bg-gray-50">
                <h4 className="mb-2 text-lg font-semibold text-brown-500">
                  Auto-Sync is Active!
                </h4>
                <p className="text-sm leading-relaxed text-gray-700 md:text-base">
                  Your RoadFlex transactions will sync automatically into
                  QuickBooks each night. Head over to QuickBooks to categorize
                  and reconcile them.
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* END QuickBooks Integration */}

      {/* =====================
          Export Accounting Transactions
          (Remaining code unchanged)
      ===================== */}
      <div className="flex flex-col h-auto mt-5">
        <div className="flex flex-col h-full bg-white rounded-md shadow">
          <div className="flex flex-row items-center justify-between gap-4 p-8 border-b-2 border-brown-500">
            <div>
              <div className="font-semibold md:text-lg text-brown-500">
                Export Accounting Transactions
              </div>
            </div>
            <Button
              size={ButtonSize.AppSize}
              variant={ButtonVariant.AppOrange}
              onClick={() => {
                setTitle("Export Accounting");
                setOpenExportModal(true);
              }}
              disabled={transactionLoading}
              className="whitespace-nowrap"
            >
              <ArrowUpTrayIcon className="w-5 h-5 mr-2" />
              Export
            </Button>
          </div>
          <div className="px-8 py-4 border-b-2 border-brown-500">
            Everything you need to balance your books efficiently. Works with
            your favorite accounting software like QuickBooks.
          </div>
          <div className="flex flex-col gap-8 p-8">
            <div className="flex flex-row items-center gap-4 md:gap-8">
              <div className="flex items-center justify-center w-10 h-10 px-4 py-4 text-white rounded-full bg-brown-500 md:w-12 md:h-12 md:text-lg">
                1
              </div>
              <div className="flex flex-col md:w-5/6">
                <div>Click "Export".</div>
              </div>
            </div>
            <div className="flex flex-row items-center gap-4 md:gap-8">
              <div className="flex items-center justify-center w-10 h-10 px-4 py-4 text-white rounded-full bg-brown-500 md:w-12 md:h-12 md:text-lg">
                2
              </div>
              <div className="flex flex-col md:w-5/6">
                <div>
                  Choose "Today", "Current Month" or a custom date range.
                </div>
              </div>
            </div>
            <div className="flex flex-row items-center gap-4 md:gap-8">
              <div className="flex items-center justify-center w-10 h-10 px-4 py-4 text-white rounded-full bg-brown-500 md:w-12 md:h-12 md:text-lg">
                3
              </div>
              <div className="flex flex-col md:w-5/6">
                <div>Export the transactions within the chosen period.</div>
              </div>
            </div>
            <div className="flex flex-row items-center gap-4 md:gap-8">
              <div className="flex items-center justify-center w-10 h-10 px-4 py-4 text-white rounded-full bg-brown-500 md:w-12 md:h-12 md:text-lg">
                4
              </div>
              <div className="flex flex-col md:w-5/6">
                <div>Transactions exclude fuel discounts and late fees.</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* =====================
          Export Statement of Account
          (Remaining code unchanged)
      ===================== */}
      <div className="flex flex-col h-auto mt-5">
        <div className="flex flex-col h-full bg-white rounded-md shadow">
          <div className="flex flex-row items-center justify-between gap-4 p-8 border-b-2 border-brown-500">
            <div>
              <div className="font-semibold md:text-lg text-brown-500">
                Export Statement of Account
              </div>
            </div>
            <Button
              size={ButtonSize.AppSize}
              variant={ButtonVariant.AppOrange}
              onClick={() => {
                setTitle("Export Statement of Account (PDF)");
                setOpenExportModal(true);
              }}
              disabled={transactionLoading}
              className="whitespace-nowrap"
            >
              <ArrowUpTrayIcon className="w-5 h-5 mr-2" />
              Export
            </Button>
          </div>
          <div className="flex flex-col gap-8 p-8">
            <div className="flex flex-row items-center gap-4 md:gap-8">
              <div className="flex items-center justify-center w-10 h-10 px-4 py-4 text-white rounded-full bg-brown-500 md:w-12 md:h-12 md:text-lg">
                1
              </div>
              <div className="flex flex-col md:w-5/6">
                <div>Click "Export".</div>
              </div>
            </div>
            <div className="flex flex-row items-center gap-4 md:gap-8">
              <div className="flex items-center justify-center w-10 h-10 px-4 py-4 text-white rounded-full bg-brown-500 md:w-12 md:h-12 md:text-lg">
                2
              </div>
              <div className="flex flex-col md:w-5/6">
                <div>Choose a custom date range.</div>
              </div>
            </div>
            <div className="flex flex-row items-center gap-4 md:gap-8">
              <div className="flex items-center justify-center w-10 h-10 px-4 py-4 text-white rounded-full bg-brown-500 md:w-12 md:h-12 md:text-lg">
                3
              </div>
              <div className="flex flex-col md:w-5/6">
                <div>Export the SOA within the chosen period.</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Generic Export Modal */}
      {openExportModal && (
        <GenericExportModal
          title={title}
          showComplete={showComplete}
          setShowComplete={setShowComplete}
          showLoading={showLoading}
          setShowLoading={setShowLoading}
          open={openExportModal}
          setOpen={setOpenExportModal}
          dates={dates}
          setDates={setDates}
          fetchSoaData={fetchSoaData}
          dateFilterApplied={dateFilterApplied}
          transactionsFilterConfig={transactionsFilterConfig}
          transactionList={transactionList}
          billingList={billingList}
          isPrepaidCustomer={isPrepaidCustomer}
        />
      )}
    </div>
  );
}
