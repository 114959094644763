/** @format */

import { useMutation } from "@apollo/client";
import { RadioGroup } from "@headlessui/react";
import {
  CheckCircleIcon,
  ChevronRightIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/24/outline";
import {
  ENUM_AUTO_RELOAD_FREQUENCY,
  ENUM_AUTO_RELOAD_TYPE,
  ENUM_CUSTOMER_TYPE,
} from "@prisma/client";
import {
  appPaymentMethodsValidationSchema,
  LABELS_CREDIT_LIMIT_THRESHOLD,
} from "@roadflex/constants";
import {
  GET_AUTO_RELOAD_DETAILS,
  UPDATE_AUTO_RELOAD_DETAILS,
} from "@roadflex/graphql";
import { AutoReloadDetails } from "@roadflex/types";
import { humanize } from "@roadflex/utils";
import classNames from "classnames";
import { useFormik } from "formik";
import { useRouter } from "next/router";
import { InputSwitch } from "primereact/inputswitch";
import { Tooltip } from "primereact/tooltip";
import { useState } from "react";
import CurrencyInput from "react-currency-input-field";
import { RadioInput } from "../../../../src/inputs";
import {
  Button,
  ButtonSize,
  ButtonType,
  ButtonVariant,
} from "../../../buttons";
import { Select } from "../../../dropdowns";
import { Toast } from "../../../toast-message/toast";

type AutoReloadUIProps = {
  autoReloadDetails?: AutoReloadDetails;
  customerType: ENUM_CUSTOMER_TYPE;
};

const autoReloadTypes = [
  {
    id: 1,
    title: "On a schedule",
    disabled: false,
  },
  {
    id: 2,
    title: "On low balance",
    disabled: false,
  },
];

const frequencyOptions = [
  { label: "Weekly", value: ENUM_AUTO_RELOAD_FREQUENCY.WEEKLY },
  { label: "Monthly", value: ENUM_AUTO_RELOAD_FREQUENCY.MONTHLY },
];

export function AutoReloadUI({
  autoReloadDetails,
  customerType,
}: AutoReloadUIProps) {
  const [updateAutoReloadDetailsFn] = useMutation(UPDATE_AUTO_RELOAD_DETAILS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "no-cache",
    refetchQueries: [GET_AUTO_RELOAD_DETAILS],
  });
  const [updatingDetails, setUpdatingDetails] = useState(false);
  const isCredit = customerType.includes("CREDIT");
  const isCreditEnterprise = customerType.includes("CREDIT_ENTERPRISE");
  const autoPayTypes = [
    {
      id: 1,
      title: "Make payments on a schedule",
      disabled: false,
    },
    {
      ...(isCreditEnterprise
        ? {
            id: 2,
            title: "Make payments on a credit limit threshold",
            disabled: false,
          }
        : {}),
    },
  ];
  const [selectedAutoReloadType, setSelectedAutoReloadType] = useState(
    !isCredit
      ? autoReloadDetails?.reloadType === ENUM_AUTO_RELOAD_TYPE.ON_BAL
        ? autoReloadTypes[1]
        : autoReloadTypes[0]
      : autoReloadDetails?.reloadType === ENUM_AUTO_RELOAD_TYPE.ON_BAL
      ? autoPayTypes[1]
      : autoPayTypes[0],
  );

  const router = useRouter();
  const backToPaymentsPanel = () => {
    router.push("/payments");
  };

  const {
    values,
    setFieldValue,
    setFieldTouched,
    handleSubmit,
    handleChange,
    errors,
    touched,
  } = useFormik({
    initialValues: {
      enabled: autoReloadDetails?.enabled || false,
      reloadFrequency:
        autoReloadDetails?.reloadFrequency || ENUM_AUTO_RELOAD_FREQUENCY.WEEKLY,
      topUpAmount: ((autoReloadDetails?.topUpAmount || 0) / 100).toString(),
      belowAmount: ((autoReloadDetails?.belowAmount || 0) / 100).toString(),
      reloadType: autoReloadDetails?.reloadType || ENUM_AUTO_RELOAD_TYPE.ON_SCH,
      creditLimitThreshold: autoReloadDetails?.creditLimitThreshold || 70,
    },
    validationSchema: appPaymentMethodsValidationSchema(isCredit),
    onSubmit: async (value) => {
      try {
        setUpdatingDetails(true);
        const res = await updateAutoReloadDetailsFn({
          variables: {
            data: {
              enabled: value.enabled,
              topUpAmount: Number(value.topUpAmount) * 100,
              belowAmount: Number(value.belowAmount) * 100,
              reloadType: value.reloadType,
              reloadFrequency: value.reloadFrequency,
              creditLimitThreshold: value.creditLimitThreshold,
            },
          },
        });
        if (res.data.updateAutoReloadDetails.code === "200") {
          Toast({
            type: "success",
            message: res?.data?.updateAutoReloadDetails?.message,
          });
        } else {
          Toast({
            type: "error",
            message: res.data.updateAutoReloadDetails.message,
          });
        }
      } catch (err) {
        if (err instanceof Error) {
          Toast({ type: "error", message: err.message });
        }
      }
      setUpdatingDetails(false);
    },
  });
  const onAutoReloadTypeChange = (value: typeof autoReloadTypes[0]) => {
    setFieldValue(
      "reloadType",
      value.id === 1
        ? ENUM_AUTO_RELOAD_TYPE.ON_SCH
        : ENUM_AUTO_RELOAD_TYPE.ON_BAL,
    );
    setSelectedAutoReloadType(value);
  };

  const onTopUpAmountChange = (value: string | undefined): void => {
    const rawValue = value === undefined ? "" : value;
    setFieldValue("topUpAmount", rawValue || "");
    if (Number.isNaN(Number(value)) && value !== undefined) {
      Toast({ type: "error", message: "Enter a valid number" });
    }
  };

  const onBelowAmountChange = (value: string | undefined): void => {
    const rawValue = value === undefined ? "" : value;
    setFieldValue("belowAmount", rawValue || "");
    if (Number.isNaN(Number(value)) && value !== undefined) {
      Toast({ type: "error", message: "Enter a valid number" });
    }
  };

  return (
    <div className="flex flex-col flex-1 m-4 mt-0 overflow-x-hidden md:px-4 md:pb-4">
      {/* <div className="flex flex-row">
        <Button
          size={ButtonSize.Small}
          variant={ButtonVariant.GrayOutline}
          onClick={backToPaymentsPanel}
          className="pl-0 ml-[-6px] border-0"
        >
          <span className="flex items-center h-5">
            <ChevronRightIcon
              className={
                "-rotate-180 h-5 w-5 transform text-brown-900 ease-in duration-300"
              }
              aria-hidden="true"
            />
          </span>
          Back
        </Button>
        {isCredit && (
          <div className="my-12 ml-4 text-xl font-bold text-brown-500 md:text-2xl">
            {`SCHEDULED PAYMENTS`.toUpperCase()}
          </div>
        )}
        {!isCredit && (
          <div className="my-12 ml-4 text-xl font-bold text-brown-500 md:text-2xl">
            {`AUTO RELOAD`.toUpperCase()}
          </div>
        )}
      </div> */}
      <div className="flex flex-col w-full rounded-md md:my-12">
        <div className="flex flex-col justify-between gap-3 md:flex-row">
          <div className="flex flex-row items-center">
            <div className="">
              <Button
                size={ButtonSize.Small}
                variant={ButtonVariant.GrayOutline}
                onClick={backToPaymentsPanel}
                className="pl-0 ml-[-6px] border-0"
              >
                <span className="flex items-center h-5">
                  <ChevronRightIcon
                    className={
                      "-rotate-180 h-5 w-5 transform ease-in duration-300"
                    }
                    aria-hidden="true"
                  />
                </span>
                Back
              </Button>
            </div>
            <div className="ml-4 text-sm font-medium md:text-lg">
              {isCredit && <span>{`SCHEDULED PAYMENTS`.toUpperCase()}</span>}
              {!isCredit && <span> {`AUTO RELOAD`.toUpperCase()}</span>}
            </div>
          </div>
        </div>
      </div>
      <form
        className="flex flex-col h-full p-4 mt-5 overflow-y-auto bg-white rounded-md shadow md:p-8 text-brown-500 md:mt-0"
        onSubmit={handleSubmit}
      >
        <div className="flex flex-col justify-between flex-grow h-full">
          <div className="space-y-4">
            <div className="flex items-center h-6">
              <span className="mr-4 font-semibold">
                {isCredit ? `Enable Scheduled Payments` : `Enable Auto Reload`}
              </span>
              <InputSwitch
                id="enabled"
                name="enabled"
                checked={values.enabled}
                onChange={() => setFieldValue("enabled", !values.enabled)}
                className="border-0 input-switch [&_span]:!bg-red child:!bg-red"
              />
            </div>
            <RadioGroup
              className=""
              value={selectedAutoReloadType}
              onChange={onAutoReloadTypeChange}
            >
              <div
                className={classNames(
                  isCredit
                    ? "w-full"
                    : "grid grid-cols-1 gap-y-2 sm:grid-cols-2 sm:gap-x-3",
                )}
              >
                {!isCredit &&
                  autoReloadTypes.map((autoReloadType) => (
                    <RadioGroup.Option
                      disabled={autoReloadType.disabled}
                      key={autoReloadType.id}
                      value={autoReloadType}
                      className={({ disabled }) =>
                        classNames(
                          disabled ? "opacity-70" : "",
                          "relative flex cursor-pointer rounded-md border bg-white p-3 text-brown-500 shadow-sm focus:outline-none",
                        )
                      }
                    >
                      <>
                        <span className="flex flex-1">
                          <span className="flex flex-col">
                            <RadioGroup.Label
                              as="span"
                              className="block text-sm font-medium text-brown-900"
                            >
                              {autoReloadType.title}
                            </RadioGroup.Label>
                          </span>
                        </span>
                        {autoReloadType.id === selectedAutoReloadType.id ? (
                          <CheckCircleIcon
                            className={classNames(
                              autoReloadType.id === selectedAutoReloadType.id
                                ? "text-orange-500"
                                : "",
                              "w-5 h-5 text-brown-600",
                            )}
                            aria-hidden="true"
                          />
                        ) : null}
                        <span
                          className={classNames(
                            autoReloadType.id === selectedAutoReloadType.id
                              ? "border-brown-500 border"
                              : "border-transparent",
                            "pointer-events-none absolute -inset-px rounded-lg",
                          )}
                          aria-hidden="true"
                        />
                      </>
                    </RadioGroup.Option>
                  ))}
                {isCredit &&
                  autoPayTypes.map((autoReloadType) => (
                    <RadioGroup.Option
                      disabled={autoReloadType.disabled}
                      key={autoReloadType.id}
                      value={autoReloadType}
                      className={({ disabled }) =>
                        classNames(
                          disabled ? "opacity-70" : "",
                          "relative flex cursor-pointer rounded-md border bg-white p-3 text-brown-500 shadow-sm focus:outline-none",
                        )
                      }
                    >
                      <>
                        <span className="flex flex-1">
                          <span className="flex flex-col">
                            <RadioGroup.Label
                              as="span"
                              className="block text-sm font-medium text-brown-900"
                            >
                              {autoReloadType.title}
                            </RadioGroup.Label>
                          </span>
                        </span>
                        {autoReloadType.id === selectedAutoReloadType.id ? (
                          <CheckCircleIcon
                            className={classNames(
                              autoReloadType.id === selectedAutoReloadType.id
                                ? "text-orange-500"
                                : "",
                              "w-5 h-5 text-brown-600",
                            )}
                            aria-hidden="true"
                          />
                        ) : null}
                        <span
                          className={classNames(
                            autoReloadType.id === selectedAutoReloadType.id
                              ? "border-brown-500 border"
                              : "border-transparent",
                            "pointer-events-none absolute -inset-px rounded-lg",
                          )}
                          aria-hidden="true"
                        />
                      </>
                    </RadioGroup.Option>
                  ))}
              </div>
            </RadioGroup>
            <div>
              {selectedAutoReloadType.id === 1 && !isCredit && (
                <p className="mt-2 mb-8 text-sm text-left text-brown-500">
                  Automatically deposit into your wallet on a regular schedule,
                  weekly or monthly.
                </p>
              )}
              {selectedAutoReloadType.id === 1 && isCredit && (
                <p className="mt-2 mb-8 text-sm text-left text-brown-500">
                  Schedule payments regularly, either weekly or monthly.
                </p>
              )}
              {selectedAutoReloadType.id === 2 && !isCredit && (
                <div className="mt-2 mb-8 text-sm text-left text-brown-500">
                  Automatically deposit into your wallet when your wallet falls
                  below a certain threshold.
                </div>
              )}
              {selectedAutoReloadType.id === 2 && isCredit && (
                <div className="mt-2 mb-8 text-sm text-left text-brown-500">
                  Increase the current cycle's credit limit by remitting funds
                  directly to the credit limit. Payment is processed only the
                  first time the credit limit falls below the specified
                  threshold and does not apply to subsequent occurrences.
                </div>
              )}
              <div className="relative">
                {!isCredit && (
                  <label className="mb-1 text-sm font-semibold">
                    Deposit Amount (min: $25.00)
                    <span className="text-red-500">*</span>
                  </label>
                )}
                {isCredit && (
                  <label className="mb-1 text-sm font-semibold">
                    Payment Amount (min: $25.00)
                    <span className="text-red-500">*</span>
                  </label>
                )}
                <CurrencyInput
                  placeholder="$"
                  value={values.topUpAmount}
                  className={classNames(
                    touched?.topUpAmount && errors?.topUpAmount
                      ? "border-red-500 focus:border-red-500"
                      : "!border-brown-500 focus:!border-brown-500",
                    "block w-full py-2 pl-3 pr-12 border rounded-md sm:text-sm focus:outline-none focus:ring-0",
                  )}
                  allowDecimals={true}
                  onValueChange={onTopUpAmountChange}
                  prefix={"$"}
                  step={10}
                />
                {touched.topUpAmount && errors?.topUpAmount && (
                  <>
                    <Tooltip
                      target=".topUpAmount-error"
                      position="top"
                      className="red-tooltip"
                      content={errors?.topUpAmount}
                    ></Tooltip>
                    <div className="absolute h-[38px] bottom-0 right-0 pr-3 flex items-center cursor-pointer">
                      <ExclamationCircleIcon
                        className="w-5 h-5 text-red-500 topUpAmount-error"
                        aria-hidden="true"
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
            {selectedAutoReloadType.id === 1 && (
              <div className="relative">
                <div className="mb-1 text-sm font-semibold">Frequency</div>
                <Select
                  value={values.reloadFrequency}
                  name="reloadFrequency"
                  id="reloadFrequency"
                  options={frequencyOptions}
                  className="child:text-sm"
                  onChange={(e) => setFieldValue("reloadFrequency", e.value)}
                  onBlur={() => setFieldTouched("reloadFrequency", true)}
                  placeholder="Select Frequency"
                ></Select>
                {values.reloadFrequency === "WEEKLY" ? (
                  isCredit ? (
                    <div className="mt-4 text-sm text-left text-brown-500">
                      Scheduled payments will be initiated every week on Monday.
                    </div>
                  ) : (
                    <div className="mt-4 text-sm text-left text-brown-500">
                      Reloads will be initiated every week on Monday.
                    </div>
                  )
                ) : isCredit ? (
                  <div className="mt-4 text-sm text-left text-brown-500">
                    Scheduled payments will be initiated on the 20th of each
                    month.
                  </div>
                ) : (
                  <div className="mt-4 text-sm text-left text-brown-500">
                    Reloads will be initiated on the 20th of each month.
                  </div>
                )}
              </div>
            )}
            {selectedAutoReloadType.id === 2 &&
              (!isCredit ? (
                <div className="relative">
                  <label className="mb-1 text-sm font-semibold">
                    When balance falls below
                    <span className="text-red-500">*</span>
                  </label>
                  <CurrencyInput
                    placeholder="$"
                    className={classNames(
                      touched?.belowAmount && errors?.belowAmount
                        ? "border-red-500 focus:border-red-500"
                        : "!border-brown-500 focus:!border-brown-500",
                      "block w-full py-2 pl-3 pr-12 border rounded-md sm:text-sm focus:outline-none focus:ring-0",
                    )}
                    allowDecimals={true}
                    value={values.belowAmount}
                    onValueChange={onBelowAmountChange}
                    prefix={"$"}
                    step={10}
                  />
                  {touched.belowAmount && errors?.belowAmount && (
                    <>
                      <Tooltip
                        target=".belowAmount-error"
                        position="top"
                        className="red-tooltip"
                        content={errors.belowAmount}
                      ></Tooltip>
                      <div className="absolute h-[38px] bottom-0 right-0 pr-3 flex items-center cursor-pointer">
                        <ExclamationCircleIcon
                          className="w-5 h-5 text-red-500 belowAmount-error"
                          aria-hidden="true"
                        />
                      </div>
                    </>
                  )}
                </div>
              ) : (
                <div className="relative">
                  <label className="mb-1 text-sm font-semibold">
                    When Credit Limit falls below threshold
                    <span className="text-red-500">*</span>
                  </label>

                  {LABELS_CREDIT_LIMIT_THRESHOLD.map((options) => (
                    <div
                      className="relative flex items-start h-6 col-span-12 mt-1 sm:col-span-12"
                      key={`${options.label}`}
                    >
                      <RadioInput
                        label={humanize(options.label)}
                        labelClass="text-sm"
                        name="creditLimitThreshold"
                        id="creditLimitThreshold"
                        checked={
                          Number(options.value) === values.creditLimitThreshold
                        }
                        className="!rounded"
                        onChange={(e) => {
                          setFieldValue(
                            "creditLimitThreshold",
                            Number(options.value),
                          );
                        }}
                        value={options.value}
                      ></RadioInput>
                    </div>
                  ))}
                </div>
              ))}
          </div>
          <div className="flex flex-row-reverse w-full mt-4 ">
            <Button
              variant={ButtonVariant.AppOrange}
              size={ButtonSize.AppSize}
              type={ButtonType.Submit}
              className="flex flex-row items-center w-full sm:w-auto"
              disabled={updatingDetails}
              loading={updatingDetails}
            >
              Save
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
}
