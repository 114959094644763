import { useQuery } from "@apollo/client";
import {
  ADMIN_READ_AFFILIATES,
  ADMIN_READ_SUBSCRIBERS,
} from "@roadflex/graphql";
import { AffiliateType, SubscriberType } from "@roadflex/types";

export const useReadSubscribers = () => {
  const { data: subscriberList, loading: subscriberListLoading } = useQuery<{
    adminReadSubscribers: {
      subscriberList: SubscriberType[];
    };
  }>(ADMIN_READ_SUBSCRIBERS, {
    fetchPolicy: "no-cache",
  });

  return {
    subscriberList,
    subscriberListLoading,
  };
};

export const useReadAffiliates = () => {
  const { data: affiliatesList, loading: affiliatesListLoading } = useQuery<{
    adminReadAffiliates: {
      affiliatesList: AffiliateType[];
    };
  }>(ADMIN_READ_AFFILIATES, {
    fetchPolicy: "no-cache",
  });

  return {
    affiliatesList,
    affiliatesListLoading,
  };
};
