/** @format */

import { ENUM_CUSTOMER_SOURCE, ENUM_PRODUCT_NAMES } from "@prisma/client";
import { SignupValues } from "@roadflex/types";
import { handleGoogleReCaptchaSubmit, phoneFormatter } from "@roadflex/web-lib";
import { FormikProps } from "formik";
import getConfig from "next/config";
import Link from "next/link";
import { useEffect, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Button, ButtonType, ButtonVariant } from "../buttons";
import { Select } from "../dropdowns";
import { TextInput } from "../inputs";
import { ConfirmModal, OTPModalOnboarding } from "../modals/index-www";

const { publicRuntimeConfig } = getConfig();
const { RECAPTCHA_SITE_KEY } = publicRuntimeConfig;

type SignupUIProps = FormikProps<SignupValues> & {
  showOtpModal: boolean;
  productName: ENUM_PRODUCT_NAMES;
  setShowOtpModal: (showOtpModal: boolean) => void;
  showSignupConfirmationModal: boolean;
  setShowSignupConfirmationModal: (
    showSignupConfirmationModal: boolean,
  ) => void;
  confirmSignup: () => void;
  onOtpSubmit: (value: SignupValues) => void;
  otpSubmitting: boolean;
};

const customerSourceOptions = [
  // { label: "-- Select --", value: "" },
  { label: "Facebook", value: ENUM_CUSTOMER_SOURCE.FACEBOOK },
  { label: "Google Search", value: ENUM_CUSTOMER_SOURCE.GOOGLE_SEARCH },
  { label: "Magazine", value: ENUM_CUSTOMER_SOURCE.MAGAZINE },
  { label: "Referral/Friend", value: ENUM_CUSTOMER_SOURCE.REFERRAL },
  { label: "Conference", value: ENUM_CUSTOMER_SOURCE.CONFERENCE },
  { label: "FleetUp", value: ENUM_CUSTOMER_SOURCE.FLEETUP },
  { label: "NALP", value: ENUM_CUSTOMER_SOURCE.NALP },
  { label: "NPMA", value: ENUM_CUSTOMER_SOURCE.NPMA },
  { label: "Advantage One", value: ENUM_CUSTOMER_SOURCE.ADVANTAGEONE },
  { label: "ProMiles", value: ENUM_CUSTOMER_SOURCE.PROMILES },
  { label: "Linxup", value: ENUM_CUSTOMER_SOURCE.LINXUP },
  { label: "PHCC", value: ENUM_CUSTOMER_SOURCE.PHCC },
  { label: "GeotabConnect", value: ENUM_CUSTOMER_SOURCE.GEOTABCONNECT },
  { label: "Discount Tire", value: ENUM_CUSTOMER_SOURCE.DISCOUNTTIRE },
  { label: "Konexial", value: ENUM_CUSTOMER_SOURCE.KONEXIAL },
  { label: "Others", value: "OTHERS" },
];

export default function SignUpUI({
  values,
  productName,
  handleChange,
  handleBlur,
  handleSubmit,
  isSubmitting,
  errors,
  touched,
  setTouched,
  setFieldValue,
  showOtpModal,
  setShowOtpModal,
  showSignupConfirmationModal,
  setShowSignupConfirmationModal,
  confirmSignup,
  onOtpSubmit,
  otpSubmitting,
  ...rest
}: SignupUIProps) {
  //Ignore Techsbook
  const isSpecialOptionSelected =
    values.customerSource === ENUM_CUSTOMER_SOURCE.ADVANTAGEONE ||
    values.customerSource === ENUM_CUSTOMER_SOURCE.FLEETUP ||
    values.customerSource === ENUM_CUSTOMER_SOURCE.NALP ||
    values.customerSource === ENUM_CUSTOMER_SOURCE.NPMA ||
    values.customerSource === ENUM_CUSTOMER_SOURCE.LINXUP ||
    values.customerSource === ENUM_CUSTOMER_SOURCE.PROMILES ||
    values.customerSource === ENUM_CUSTOMER_SOURCE.PHCC ||
    values.customerSource === ENUM_CUSTOMER_SOURCE.GEOTABCONNECT ||
    values.customerSource === ENUM_CUSTOMER_SOURCE.DISCOUNTTIRE ||
    values.customerSource === ENUM_CUSTOMER_SOURCE.KONEXIAL;

  const filteredOptions = customerSourceOptions.filter((option) => {
    if (!isSpecialOptionSelected) {
      return (
        option.value !== ENUM_CUSTOMER_SOURCE.ADVANTAGEONE &&
        option.value !== ENUM_CUSTOMER_SOURCE.FLEETUP &&
        option.value !== ENUM_CUSTOMER_SOURCE.NALP &&
        option.value !== ENUM_CUSTOMER_SOURCE.NPMA &&
        option.value !== ENUM_CUSTOMER_SOURCE.LINXUP &&
        option.value !== ENUM_CUSTOMER_SOURCE.PROMILES &&
        option.value !== ENUM_CUSTOMER_SOURCE.PHCC &&
        option.value !== ENUM_CUSTOMER_SOURCE.GEOTABCONNECT &&
        option.value !== ENUM_CUSTOMER_SOURCE.DISCOUNTTIRE &&
        option.value !== ENUM_CUSTOMER_SOURCE.KONEXIAL
      );
    }
    return true;
  });
  const captchaRef = useRef<ReCAPTCHA>(null);
  // Function to touch all the form inputs
  const touchAllInputs = () => {
    setTouched(
      Object.keys(values).reduce(
        (acc: { [key: string]: boolean }, fieldName) => {
          acc[fieldName] = true;
          return acc;
        },
        {},
      ),
    );
  };

  useEffect(() => {
    if (showSignupConfirmationModal && Object.keys(errors).length !== 0) {
      setShowSignupConfirmationModal(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors, showSignupConfirmationModal]);

  return (
    <div className="w-full rounded-md">
      <form className="flex flex-col px-4 py-8 md:p-8">
        <div className="grid w-full grid-cols-12 gap-1 md:gap-4">
          <div className="relative col-span-12 md:col-span-6">
            <TextInput
              label="First (and Middle) Name"
              name="firstName"
              value={values.firstName}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.firstName && Boolean(errors.firstName)}
              errorMessage={errors.firstName}
              touched={touched.firstName}
              variant="responsive"
              required
            ></TextInput>
          </div>
          <div className="relative col-span-12 md:col-span-6">
            <TextInput
              label="Last Name"
              name="lastName"
              value={values.lastName}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.lastName && Boolean(errors.lastName)}
              errorMessage={errors.lastName}
              touched={touched.lastName}
              variant="responsive"
              required
            ></TextInput>
          </div>
          <div className="relative col-span-12">
            <TextInput
              type="tel"
              label="Phone Number"
              name="phoneNumber"
              value={values.phoneNumber}
              onChange={(event) =>
                setFieldValue(
                  "phoneNumber",
                  phoneFormatter(event?.target?.value),
                )
              }
              onBlur={handleBlur}
              error={touched.phoneNumber && Boolean(errors.phoneNumber)}
              errorMessage={errors.phoneNumber}
              touched={touched.phoneNumber}
              variant="responsive"
              required
            ></TextInput>
          </div>

          <div className="relative col-span-12">
            <TextInput
              label="Email"
              name="email"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.email && Boolean(errors.email)}
              errorMessage={errors.email}
              touched={touched.email}
              variant="responsive"
              required
            ></TextInput>
          </div>
          <div className="relative col-span-12">
            <TextInput
              label="Organization Name"
              name="businessName"
              value={values.businessName}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.businessName && Boolean(errors.businessName)}
              errorMessage={errors.businessName}
              touched={touched.businessName}
              variant="responsive"
              required
            ></TextInput>
          </div>
          <div className="relative col-span-12 md:col-span-6">
            <Select
              onChange={(e) => {
                setFieldValue("customerSource", e.value);
              }}
              label="How did you hear about us?"
              required
              value={values.customerSource}
              name="customerSource"
              id="customerSource"
              variant="responsive"
              error={touched.customerSource && errors?.customerSource !== ""}
              errorMessage={errors?.customerSource}
              options={filteredOptions}
              disabled={isSpecialOptionSelected}
              onMouseDown={(e) => {
                e.stopPropagation();
              }}
            ></Select>
          </div>
          <div className="relative col-span-12 md:col-span-6">
            <TextInput
              label="Referral Code (Optional)"
              name="referral"
              value={values.referral}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.referral && Boolean(errors.referral)}
              errorMessage={errors.referral}
              touched={touched.referral}
              variant="responsive"
            ></TextInput>
          </div>
          <div className="relative col-span-12">
            <div className="text-sm text-center md:text-right">
              By applying, you are agreeing to our{" "}
              <Link href="/terms-of-service">
                <a href="/terms-of-service" className="text-orange-500">
                  Platform Agreement
                </a>
              </Link>{" "}
              and{" "}
              <Link href="/privacy-policy">
                <a href="/privacy-policy" className="text-orange-500">
                  Privacy Policy
                </a>
              </Link>
              .
            </div>
          </div>
          <div className="relative col-span-12 md:mt-4">
            <ReCAPTCHA
              className="invisible recaptcha"
              sitekey={RECAPTCHA_SITE_KEY}
              ref={captchaRef}
              size="invisible"
            />
            <div className="flex justify-end">
              <Button
                variant={ButtonVariant.Orange}
                type={ButtonType.Button}
                onClick={(event) => {
                  touchAllInputs();
                  setFieldValue("otp", "");
                  setFieldValue("emailOtp", "");
                  if (Object.keys(errors).length === 0) {
                    handleGoogleReCaptchaSubmit(event, captchaRef, () => {
                      setShowSignupConfirmationModal(true);
                    });
                  }
                }}
                disabled={productName === ENUM_PRODUCT_NAMES.FACTOR}
                className="md:max-w-[200px] lg:max-w-[250px] py-3 md:py-2"
              >
                Start Application (5 min)
              </Button>
            </div>
          </div>
        </div>
      </form>
      {showSignupConfirmationModal && (
        <ConfirmModal
          confirm={confirmSignup}
          submitting={isSubmitting}
          show={showSignupConfirmationModal}
          setShow={setShowSignupConfirmationModal}
          title="Confirm email and phone number"
          body={
            <div className="text-left">
              <div className="text-base">
                <p className="text-gray-500 ">
                  Email provided: <b>{values.email}</b>
                </p>
                <p className="text-gray-500 ">
                  Mobile provided: <b>{values.phoneNumber}</b>
                </p>
                <br />
                <p className="text-gray-500 ">
                  If any of this is incorrect, do not proceed with the
                  verification. You will not be able to change your email and
                  phone number throughout the application process.
                </p>
              </div>
            </div>
          }
        />
      )}
      {showOtpModal && (
        <OTPModalOnboarding
          {...{
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            errors,
            touched,
            setTouched,
            setFieldValue,
            ...rest,
          }}
          show={showOtpModal}
          setShow={setShowOtpModal}
          onOtpSubmit={onOtpSubmit}
          otpSubmitting={otpSubmitting}
          fromSignUp={true}
        />
      )}
    </div>
  );
}
