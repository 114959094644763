import { useMutation } from "@apollo/client";
import { genericPasswordSchema } from "@roadflex/constants";
import {
  ADMIN_LOGOUT_ALL_USERS,
  ADMIN_UPDATE_PASSWORD,
} from "@roadflex/graphql";
import { useFormik } from "formik";

type useSetPasswordProps = {
  onSubmit: (val: any, error: any) => void;
  initialValues: {
    currentPassword: string;
    password: string;
    retypePassword: string;
  };
};

type useLogOutAllUsersProps = {
  onSubmit: (val: any, error: any) => void;
  initialValues: {
    userType: string;
  };
};

export const useUpdateAdminPassword = ({
  onSubmit,
  initialValues,
}: useSetPasswordProps) => {
  const [updateAdminPasswordFn] = useMutation<{
    adminUpdatePassword: {
      code: string;
      message: string;
    };
  }>(ADMIN_UPDATE_PASSWORD);

  const {
    handleChange,
    handleSubmit,
    handleBlur,
    values,
    isSubmitting,
    ...rest
  } = useFormik({
    initialValues,
    validationSchema: genericPasswordSchema,
    onSubmit: async (value) => {
      try {
        const res = await updateAdminPasswordFn({
          variables: { data: value },
        });
        if (res?.data?.adminUpdatePassword) {
          onSubmit(res?.data?.adminUpdatePassword, null);
        }
      } catch (error) {
        if (error instanceof Error) {
          onSubmit(null, error);
        } else {
          onSubmit(null, new Error("Something went wrong"));
        }
      }
    },
  });

  return {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    ...rest,
  };
};

export const useLogOutAllUsers = ({
  onSubmit,
  initialValues,
}: useLogOutAllUsersProps) => {
  const [logOutAllUsersFn] = useMutation<{
    adminLogoutAllUsers: {
      code: string;
      message: string;
    };
  }>(ADMIN_LOGOUT_ALL_USERS);

  const {
    handleChange,
    handleSubmit,
    handleBlur,
    values,
    isSubmitting,
    setFieldValue,
    ...rest
  } = useFormik({
    initialValues,
    onSubmit: async (value) => {
      try {
        const res = await logOutAllUsersFn({
          variables: { data: value },
        });
        if (res?.data?.adminLogoutAllUsers) {
          onSubmit(res?.data?.adminLogoutAllUsers, null);
        }
      } catch (error) {
        if (error instanceof Error) {
          onSubmit(null, error);
        } else {
          onSubmit(null, new Error("Something went wrong"));
        }
      }
    },
  });

  return {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    setFieldValue,
    ...rest,
  };
};
