import { useMutation } from "@apollo/client";
import { REMOVE_TRANSACTION_RECEIPT } from "@roadflex/graphql";
import {
  useReadAccountTag,
  useReadAuthorizationDetail,
  useUpdateTransactionMemo,
} from "@roadflex/react-hooks";
import {
  AccountTagType,
  ParameterFilterType,
  UserAuthType,
} from "@roadflex/types";
import { uploadTransactionReceipt } from "@roadflex/web-api";
import getConfig from "next/config";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { Loader } from "../../../loader";
import { Toast } from "../../../toast-message/toast";
import { AuthorizationDetailsPageUI } from "./authorization-details.ui";

const { publicRuntimeConfig } = getConfig();
const { NEXT_PUBLIC_API_URL } = publicRuntimeConfig;
type AuthorizationDetailsProps = {
  readCurrentUserAuth: UserAuthType;
  loading: boolean;
  authorizationId: string;
  refetch: () => void;
  cardFilter: ParameterFilterType[];
};
interface Upload {
  file: File | null;
  progress: number;
  isLoading: boolean;
  hasError: boolean;
  url?: string;
  fromServer: boolean;
  createdAt: Date;
  fileId: string;
}

export const AuthorizationDetailsPage = ({
  readCurrentUserAuth,
  loading,
  authorizationId,
  cardFilter,
  refetch,
}: AuthorizationDetailsProps) => {
  const [editMemo, setEditMemo] = useState(false);
  const [transactionTags, setTransactionTags] = useState<AccountTagType[]>([]);
  const [cardTags, setCardTags] = useState<AccountTagType[]>([]);
  const [driverTags, setDriverTags] = useState<AccountTagType[]>([]);
  const [vehicleTags, setVehicleTags] = useState<AccountTagType[]>([]);
  const router = useRouter();
  if (!authorizationId) {
    router.push(`/transactions`);
  }

  const {
    authorization,
    loading: authorizationDetailLoading,
    refetch: authorizationDetailRefetch,
  } = useReadAuthorizationDetail(authorizationId);

  const { data: accountTagsData, loading: accountTagsDataLoading } =
    useReadAccountTag({});

  const {
    updateTransactionMemoValues,
    handleUpdateTransactionMemoSubmit,
    handleUpdateTransactionMemoChange,
    updateTransactionMemoSubmitting,
    setFieldValue,
  } = useUpdateTransactionMemo({
    initialValues: {
      memo: authorization?.memo,
      transactionId: authorizationId,
      transactionTagIds: authorization?.transactionTags || [],
      cardTagIds: authorization?.cardTags || [],
      driverTagIds: authorization?.driverTags || [],
      vehicleTagIds: authorization?.vehicleTags || [],
      type: "Authorization",
    },
    onSubmit(res, err) {
      if (err) {
        Toast({ type: "error", message: err.message });
        return;
      }
      Toast({
        type: "success",
        message: res?.data?.updateTransactionMemo?.message,
      });
      setEditMemo(false);
      authorizationDetailRefetch();
    },
  });
  const [removeTransactionReceiptFn] = useMutation(REMOVE_TRANSACTION_RECEIPT);
  const [uploads, setUploads] = useState<Upload[]>([]);
  const [isUploading, setIsUploading] = useState<boolean>(false);

  useEffect(() => {
    const fetchFiles = async () => {
      try {
        const fetchedUploads: Upload[] = authorization.receipts.map(
          (receipt: {
            fileName: string;
            folderName: string;
            fileExtension: string;
            createdAt: Date;
          }) => ({
            file: null, // Assuming fileData.content is base64 or blob data
            progress: 100,
            isLoading: false,
            hasError: false,
            fromServer: true,
            createdAt: receipt.createdAt,
            fileId: receipt.fileName,
            url: `${NEXT_PUBLIC_API_URL}/api/files/${receipt.folderName}/${receipt.fileName}.${receipt.fileExtension}`, // Assuming the URL to download the file is provided in the response
          }),
        );

        setUploads(fetchedUploads);
      } catch (error) {
        console.error("Error fetching files:", error);
      }
    };
    if (authorization?.receipts) {
      fetchFiles();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(authorization?.receipts)]);
  const handleReceiptFiles = (files: FileList | null) => {
    if (!files) return;
    const currentUploadCount = uploads.length;
    const newFiles = Array.from(files);

    if (currentUploadCount + newFiles.length > 2) {
      Toast({ type: "error", message: "You can only upload up to 2 files" });
      return;
    }

    const newUploads: Upload[] = [];
    Array.from(files).forEach((file) => {
      newUploads.push({
        file,
        progress: 0,
        isLoading: true,
        hasError: false,
        fromServer: false,
        createdAt: new Date(),
        fileId: "",
      });
    });

    setUploads((prevUploads) => [...prevUploads, ...newUploads]);
  };

  useEffect(() => {
    const uploadFile = async (upload: Upload, index: number) => {
      const interval = setInterval(() => {
        setUploads((prevUploads) => {
          const newProgress = prevUploads.map((item, idx) => {
            if (idx === index && item.progress < 90) {
              return {
                ...item,
                progress: item.progress + Math.floor(Math.random() * 10) + 1,
              };
            }
            return item;
          });
          return newProgress;
        });
      }, 200);

      try {
        await new Promise((resolve) => setTimeout(resolve, 3000)); // Simulate file upload delay
        // Simulate file upload success
        await uploadTransactionReceipt(
          upload.file as File,
          "transaction_receipt",
          authorizationId,
          "Authorization",
        );
        Toast({
          type: "success",
          message: `File ${upload.file?.name} uploaded successfully`,
        });
        setUploads((prevUploads) =>
          prevUploads.map((item, idx) =>
            idx === index
              ? {
                  ...item,
                  progress: 100,
                  isLoading: false,
                  url: URL.createObjectURL(item.file as File),
                }
              : item,
          ),
        );
      } catch (err) {
        Toast({
          type: "error",
          message: `File ${upload.file?.name} upload failed`,
        });
        setUploads((prevUploads) =>
          prevUploads.map((item, idx) =>
            idx === index
              ? { ...item, progress: 0, isLoading: false, hasError: true }
              : item,
          ),
        );
      }
      clearInterval(interval);
    };

    const processUploads = async () => {
      for (let i = 0; i < uploads.length; i++) {
        if (uploads[i].isLoading) {
          await uploadFile(uploads[i], i);
        }
      }
      authorizationDetailRefetch();
      setIsUploading(false);
    };

    if (uploads.length > 0 && !isUploading) {
      setIsUploading(true);
      processUploads();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploads, isUploading]);

  const handleRemoveReceiptFile = async (fileId: string) => {
    await removeTransactionReceiptFn({
      variables: {
        data: {
          fileId: fileId,
        },
      },
    });
    authorizationDetailRefetch();
  };

  const setAuthInitialMemoValues = () => {
    setFieldValue("memo", authorization?.memo || "");
    setFieldValue("transactionTagIds", authorization?.transactionTags || []);
    setFieldValue("cardTagIds", authorization?.cardTags || []);
    setFieldValue("driverTagIds", authorization?.driverTags || []);
    setFieldValue("vehicleTagIds", authorization?.vehicleTags || []);
  };

  useEffect(() => {
    if (authorization) {
      setAuthInitialMemoValues();
    }
  }, [authorization]);

  useEffect(() => {
    if (accountTagsData?.readAccountTags?.accountTagList) {
      const accountTagList =
        accountTagsData.readAccountTags.accountTagList || {};

      setTransactionTags([...accountTagList.TRANSACTION.active]);
      setCardTags([...accountTagList.CARD.active]);
      setDriverTags([...accountTagList.DRIVER.active]);
      setVehicleTags([...accountTagList.VEHICLE.active]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountTagsData]);

  if (authorizationDetailLoading || accountTagsDataLoading) {
    return <Loader />;
  }

  return (
    <AuthorizationDetailsPageUI
      {...{
        authorizationDetailsObj: authorization,
        updateTransactionMemoValues,
        handleUpdateTransactionMemoSubmit,
        updateTransactionMemoSubmitting,
        handleUpdateTransactionMemoChange,
        setFieldValue,
        editMemo,
        setEditMemo,
        transactionTags,
        cardTags,
        driverTags,
        vehicleTags,
        accountTagsDataLoading,
        setAuthInitialMemoValues,
        handleRemoveReceiptFile,
        handleReceiptFiles,
        uploads,
        cardFilter,
      }}
    />
  );
};
