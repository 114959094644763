/** @format */

import { useSubscription } from "@apollo/client";
import {
  AUTHORIZATION_SUBSCRIPTION,
  TRANSACTION_SUBSCRIPTION,
} from "@roadflex/graphql";
import {
  useReadAppDashboardData,
  useReadAuthorizations,
  useReadFilterOptions,
  useReadTransactionDashboard,
  useReadTransactions,
  useUpdateBlockedMerchantsStates,
} from "@roadflex/react-hooks";
import {
  AuthorizationType,
  CardsFilterOptions,
  ChildFeatureType,
  DriversFilterOptions,
  ParameterFilterType,
  TransactionType,
  UserAuthType,
  VehiclesFilterOptions,
} from "@roadflex/types";
import { useRouter } from "next/router";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { DataTablePFSEvent } from "primereact/datatable";
import { useEffect, useState } from "react";
import { Step } from "react-joyride";
import { Loader } from "../../loader";
import { Toast } from "../../toast-message/toast";
import TransactionsUI from "./transactions-main.ui";

type FilterProps =
  | "date"
  | "merchantName"
  | "merchantState"
  | "transactionAmount"
  | "status";

type FilterProps2 = "driver" | "vehicle" | "vehicleVIN" | "lastFour";
type FilterConfigType = {
  [Key in FilterProps]: {
    operator: FilterOperator;
    constraints: [{ value: null | string | Date; matchMode: FilterMatchMode }];
  };
} & {
  [Key in FilterProps2]: {
    value: null | string;
    matchMode: FilterMatchMode;
  };
};
const initFilterConfig: FilterConfigType = {
  date: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
  },
  merchantName: {
    operator: FilterOperator.OR,
    constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
  },
  merchantState: {
    operator: FilterOperator.OR,
    constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
  },
  transactionAmount: {
    operator: FilterOperator.OR,
    constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
  },
  status: {
    operator: FilterOperator.OR,
    constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
  },
  lastFour: { value: null, matchMode: FilterMatchMode.IN },
  driver: { value: null, matchMode: FilterMatchMode.IN },
  vehicle: { value: null, matchMode: FilterMatchMode.IN },
  vehicleVIN: { value: null, matchMode: FilterMatchMode.IN },
};

const authorizationInitialLazyParams: DataTablePFSEvent = {
  first: 0,
  rows: 10,
  page: 0,
  filters: initFilterConfig,
  sortField: "date",
  sortOrder: -1,
  multiSortMeta: null,
};

const transactionInitialLazyParams: DataTablePFSEvent = {
  first: 0,
  rows: 10,
  page: 0,
  filters: initFilterConfig,
  sortField: "date",
  sortOrder: -1,
  multiSortMeta: null,
  isReport: false,
};

type TransactionsProps = {
  readCurrentUserAuth: UserAuthType;
  loading: boolean;
  childFeatures: ChildFeatureType[];
  cardTags: ParameterFilterType[];
};

export const Transactions = ({
  readCurrentUserAuth,
  loading,
  childFeatures,
  cardTags,
}: TransactionsProps) => {
  const [blockMerchantsArray, setBlockMerchantsArray] = useState<string[]>([]);
  const [blockStatesArray, setBlockStatesArray] = useState<string[]>([]);
  const [authorizationLazyParams, setAuthorizationLazyParams] =
    useState<DataTablePFSEvent>({
      ...authorizationInitialLazyParams,
    });
  const [transactionLazyParams, setTransactionLazyParams] =
    useState<DataTablePFSEvent>({
      ...transactionInitialLazyParams,
    });
  const router = useRouter();
  useEffect(() => {
    const { query } = router;
    const { filter, value } = query;
    if (filter === "card") {
      setTransactionLazyParams({
        ...transactionLazyParams,
        filters: {
          ...initFilterConfig,
          lastFour: {
            ...initFilterConfig.lastFour,
            value: [value],
          },
        },
      });
      setAuthorizationLazyParams({
        ...authorizationLazyParams,
        filters: {
          ...initFilterConfig,
          lastFour: {
            ...initFilterConfig.lastFour,
            value: [value],
          },
        },
      });
    } else {
      setTransactionLazyParams({
        ...transactionInitialLazyParams,
      });
      setAuthorizationLazyParams({
        ...authorizationInitialLazyParams,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.query]);

  const { data, loading: appDashboardDataLoading } = useReadAppDashboardData();
  const { authorizationData, authorizationRefetch, authorizationLoading } =
    useReadAuthorizations(
      authorizationLazyParams,
      true,
      "PENDING",
      cardTags.length > 0 ? cardTags.map((x) => x.tag) : undefined,
    );
  const { transactionData, transactionRefetch, transactionLoading } =
    useReadTransactions(
      transactionLazyParams,
      false,
      false,
      false,
      cardTags.length > 0 ? cardTags.map((x) => x.tag) : undefined,
    );
  const { filterOptions } = useReadFilterOptions();
  const {
    transactionDashboardData,
    transactionDashboardRefetch,
    transactionDashboardLoading,
  } = useReadTransactionDashboard();

  const {
    blockedMerchantsValues,
    blockedMerchantsHandleSubmit,
    blockedMerchantsIsSubmitting,
    blockedMerchantsResetForm,
  } = useUpdateBlockedMerchantsStates({
    initialValues: {
      blockedMerchants:
        transactionDashboardData?.readTransactionDashboardDetails
          ?.transactionDashboardDetails?.blockMerchants || "",
      blockedStates:
        transactionDashboardData?.readTransactionDashboardDetails
          ?.transactionDashboardDetails?.blockStates || "",
    },
    onSubmit(res, err) {
      if (err) {
        Toast({ type: "error", message: err.message });
        return;
      }
      Toast({
        type: "success",
        message: res?.data?.updateBlockedMerchantsStates?.message,
      });
      blockedMerchantsResetForm();
    },
  });

  const [pendingTransactionData, setPendingTransactionData] = useState<{
    authorizations: AuthorizationType[];
    totalCount: number;
  }>({
    authorizations: [],
    totalCount: 0,
  });

  const [settledTransactionData, setSettledTransactionData] = useState<{
    transactions: TransactionType[];
    totalCount: number;
  }>({
    transactions: [],
    totalCount: 0,
  });

  const [transactionFilterOptions, setTransactionFilterOptions] = useState<{
    vehiclesFilterOptions: VehiclesFilterOptions[];
    cardsFilterOptions: CardsFilterOptions[];
    driversFilterOptions: DriversFilterOptions[];
  }>({
    vehiclesFilterOptions: [],
    cardsFilterOptions: [],
    driversFilterOptions: [],
  });
  const [joyride, setJoyride] = useState(false);

  const [transactionsFilterConfig, setTransactionFilterConfig] =
    useState<FilterConfigType>(initFilterConfig);
  const { data: authorizationUpdated } = useSubscription(
    AUTHORIZATION_SUBSCRIPTION,
    {
      fetchPolicy: "no-cache",
      variables: {
        data: {
          userId: readCurrentUserAuth.primaryOwnerId,
        },
      },
    },
  );

  const { data: transactionUpdated } = useSubscription(
    TRANSACTION_SUBSCRIPTION,
    {
      fetchPolicy: "no-cache",
      variables: {
        data: {
          userId: readCurrentUserAuth.primaryOwnerId,
        },
      },
    },
  );
  useEffect(() => {
    if (
      transactionDashboardData?.readTransactionDashboardDetails
        ?.transactionDashboardDetails?.blockMerchants
    ) {
      setBlockMerchantsArray(
        transactionDashboardData?.readTransactionDashboardDetails?.transactionDashboardDetails?.blockMerchants.split(
          ",",
        ),
      );
    }
  }, [
    transactionDashboardData?.readTransactionDashboardDetails
      ?.transactionDashboardDetails?.blockMerchants,
  ]);

  useEffect(() => {
    if (
      transactionDashboardData?.readTransactionDashboardDetails
        ?.transactionDashboardDetails?.blockStates
    ) {
      setBlockStatesArray(
        transactionDashboardData?.readTransactionDashboardDetails?.transactionDashboardDetails?.blockStates.split(
          ",",
        ),
      );
    }
  }, [
    transactionDashboardData?.readTransactionDashboardDetails
      ?.transactionDashboardDetails?.blockStates,
  ]);

  const steps: Step[] = [
    {
      title: <h1 className="text-lg font-semibold text-red-900">Welcome</h1>,
      content: "Welcome to the Transactions page.",
      locale: {
        skip: (
          <span aria-label="skip" style={{ fontSize: "16px" }}>
            End
          </span>
        ),
      },
      placement: "center",
      target: "body",
    },
    {
      title: (
        <h1 className="text-lg font-semibold text-red-900">Account Data</h1>
      ),
      content:
        "Lifetime Transaction Amount refers to amount spent across all your cards to date. Lifetime Total Savings refers to cumulative fuel discounts to date.",
      locale: {
        skip: (
          <span aria-label="skip" style={{ fontSize: "16px" }}>
            End
          </span>
        ),
      },
      spotlightPadding: 10,
      target: ".step-2",
    },
    {
      title: (
        <h1 className="text-lg font-semibold text-red-900">Risk Management</h1>
      ),
      content:
        "Understand and manage risky transactions that are flagged and block specific merchants.",
      locale: {
        skip: (
          <span aria-label="skip" style={{ fontSize: "16px" }}>
            End
          </span>
        ),
      },
      spotlightPadding: 10,
      target: ".step-3",
    },
    {
      target: ".step-4",
      title: (
        <h1 className="text-lg font-semibold text-red-900">
          Pending Transactions
        </h1>
      ),
      locale: {
        skip: (
          <span aria-label="skip" style={{ fontSize: "16px" }}>
            End
          </span>
        ),
      },
      content: (
        <p className="text-base text-gray-700">
          Pending transactions refer to transactions that just occurred. It
          usually takes up to <strong>48</strong> hours for a transaction to be
          settled.
        </p>
      ),
    },
    {
      target: ".step-5",
      title: (
        <h1 className="text-lg font-semibold text-red-900">
          Settled Transactions
        </h1>
      ),
      locale: {
        skip: (
          <span aria-label="skip" style={{ fontSize: "16px" }}>
            End
          </span>
        ),
      },
      spotlightPadding: 10,
      content:
        "Settled transactions show up on this table. You can click on the details button to view ganular details.",
    },
    {
      target: ".step-6",
      title: (
        <h1 className="text-lg font-semibold text-red-900">Help Center</h1>
      ),
      locale: {
        skip: (
          <span aria-label="skip" style={{ fontSize: "16px" }}>
            End
          </span>
        ),
        last: "Close",
      },
      spotlightPadding: 10,
      content: (
        <p className="text-base text-gray-700">
          If you need help or would like to go through the navigation again,
          click <em>Help Center</em>.
        </p>
      ),
    },
  ];

  useEffect(() => {
    if (filterOptions?.transactionFilterOptions) {
      setTransactionFilterOptions({
        cardsFilterOptions: filterOptions.transactionFilterOptions.cards.map(
          (value) => {
            return {
              lastFour: value.lastFour,
            };
          },
        ),
        vehiclesFilterOptions:
          filterOptions.transactionFilterOptions.vehicles.map((value) => {
            return {
              vehicleName: value.fleetName,
            };
          }),
        driversFilterOptions:
          filterOptions.transactionFilterOptions.drivers.map((value) => {
            return {
              driverName: value.name,
            };
          }),
      });
    }
  }, [filterOptions?.transactionFilterOptions]);

  useEffect(() => {
    authorizationRefetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authorizationUpdated, authorizationLazyParams]);

  useEffect(() => {
    transactionDashboardRefetch();
    transactionRefetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionUpdated]);

  useEffect(() => {
    transactionRefetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionLazyParams]);

  useEffect(() => {
    if (
      !transactionLoading &&
      transactionData &&
      transactionData?.readTransactionList?.code !== "200"
    ) {
      Toast({
        type: "error",
        message: transactionData?.readTransactionList?.message || "",
      });
    }
    if (
      transactionData &&
      transactionData?.readTransactionList?.code === "200"
    ) {
      setSettledTransactionData({
        totalCount: transactionData.readTransactionList.totalCount,
        transactions: transactionData.readTransactionList.transactions,
      });
    }
  }, [transactionData, transactionLoading]);

  useEffect(() => {
    if (
      !authorizationLoading &&
      authorizationData &&
      authorizationData?.readAuthorizationList?.code !== "200"
    ) {
      Toast({
        type: "error",
        message: authorizationData.readAuthorizationList?.message || "",
      });
    }
    if (
      authorizationData &&
      authorizationData.readAuthorizationList.code === "200"
    ) {
      setPendingTransactionData({
        totalCount: authorizationData.readAuthorizationList.totalCount,
        authorizations: authorizationData.readAuthorizationList.authorizations,
      });
    }
  }, [authorizationData, authorizationLoading]);

  if (loading) {
    return null;
  }

  if (transactionDashboardLoading) {
    return <Loader />;
  }

  const updateList = () => {
    blockedMerchantsValues.blockedMerchants = blockMerchantsArray.join(",");
    blockedMerchantsHandleSubmit();
  };

  const updateStatesList = () => {
    blockedMerchantsValues.blockedStates = blockStatesArray.join(",");
    blockedMerchantsHandleSubmit();
  };

  const dashboardDetails =
    transactionDashboardData?.readTransactionDashboardDetails
      ?.transactionDashboardDetails || null;
  const mainDashboardData =
    data?.readAppDashboardData?.appDashboardData || null;

  const customerType = readCurrentUserAuth?.fuelCard?.customerType;

  return (
    <TransactionsUI
      {...{
        dashboardDetails,
        authorizationLoading,
        authorizationRefetch,
        settledTransactionData,
        transactionRefetch,
        transactionLoading,
        authorizationLazyParams,
        transactionLazyParams,
        setAuthorizationLazyParams,
        setTransactionLazyParams,
        transactionsFilterConfig,
        setTransactionFilterConfig,
        initFilterConfig,
        mainDashboardData,
        appDashboardDataLoading,
        customerType,
        pendingTransactionData,
        transactionFilterOptions,
        updateList,
        updateStatesList,
        blockedMerchantsIsSubmitting,
        blockMerchantsArray,
        setBlockMerchantsArray,
        blockStatesArray,
        setBlockStatesArray,
        joyride,
        setJoyride,
        steps,
        childFeatures,
        readCurrentUserAuth,
      }}
    />
  );
};
