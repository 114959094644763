/** @format */

import { useLazyQuery, useSubscription } from "@apollo/client";
import { ENUM_CUSTOMER_TYPE } from "@prisma/client";
import { pdf } from "@react-pdf/renderer";
import {
  GET_STATEMENT_OF_ACCOUNT_DATA,
  TRANSACTION_SUBSCRIPTION,
} from "@roadflex/graphql";
import {
  useQuickbookDetails,
  useReadBillingCycleList,
  useRemoveQuickBookConnection,
  useTransactionList,
} from "@roadflex/react-hooks";
import {
  ExtendedTransactionType,
  StatementOfAccount,
  UserAuthType,
} from "@roadflex/types";
import { startOfMonth, subMonths } from "date-fns";
import { saveAs } from "file-saver";
import getConfig from "next/config";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { useEffect, useState } from "react";
import { StatementOfAccountPdf } from "../../load-pdf";
import { Loader } from "../../loader";
import { Toast } from "../../toast-message/toast";
import AccountingUI from "./accounting.ui";
const { publicRuntimeConfig } = getConfig();
const { NEXT_PUBLIC_API_URL } = publicRuntimeConfig;
type FilterProps = "date" | "transactionAmount";

const initFilterConfig: {
  [Key in FilterProps]: {
    operator: FilterOperator;
    constraints: [{ value: null | string | Date; matchMode: FilterMatchMode }];
  };
} = {
  date: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
  },
  transactionAmount: {
    operator: FilterOperator.OR,
    constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
  },
};

type AccountingProps = {
  readCurrentUserAuth: UserAuthType;
  loading: boolean;
};

export const Accounting = ({
  readCurrentUserAuth,
  loading,
}: AccountingProps) => {
  const transactionList: ExtendedTransactionType[] = [];
  const currentCardsSet = new Set<string>();
  const currentDriversSet = new Set<string>();

  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [showComplete, setShowComplete] = useState<boolean>(false);
  const [dates, setDates] = useState<Date | Date[] | undefined>([
    startOfMonth(subMonths(new Date(), 1)),
    new Date(),
  ]);
  const {
    data: quickbookData,
    loading: loadingQuickbookDetails,
    refetch: quickBookDataRefetch,
  } = useQuickbookDetails();
  const { removeQuickBookConnectionFn } = useRemoveQuickBookConnection();

  const [soaInfoFn, { loading: soaInfoLoading }] = useLazyQuery<{
    getStatementOfAccount: {
      statementOfAccountData: StatementOfAccount[];
      businessName: string;
      city: string;
      firstName: string;
      lastName: string;
      street: string;
      streetDetail: string;
      zip: string;
    };
  }>(GET_STATEMENT_OF_ACCOUNT_DATA, {
    fetchPolicy: "no-cache",
    onCompleted: async (data) => {
      setShowLoading(false);
      setShowComplete(true);
      if (data?.getStatementOfAccount) {
        const dateOnlyArray = (dates as Date[]).map((dateString) => {
          const date = new Date(dateString);
          date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
          const parts = date.toISOString().split("T")[0].split("-");
          return `${parts[1]}/${parts[2]}/${parts[0]}`;
        });
        const doc = (
          <StatementOfAccountPdf
            {...{
              company: {
                businessName: data?.getStatementOfAccount.businessName || "",
                city: data?.getStatementOfAccount.city || "",
                firstName: data?.getStatementOfAccount.firstName || "",
                lastName: data?.getStatementOfAccount.lastName || "",
                street: data?.getStatementOfAccount.street || "",
                streetDetail: data?.getStatementOfAccount.streetDetail || "",
                zip: data?.getStatementOfAccount.zip || "",
              },
              statementOfAccountData:
                data?.getStatementOfAccount?.statementOfAccountData || [],
              enterprise: true,
              statementDateRange: dateOnlyArray,
            }}
          />
        );
        const asPdf = pdf();
        asPdf.updateContainer(doc);
        const blob = await asPdf.toBlob();
        saveAs(
          blob,
          `${
            data?.getStatementOfAccount.businessName || ""
          } - Statement of Account - ${dateOnlyArray[0]}-${
            dateOnlyArray[1]
          }.pdf`,
        );
      }
    },
    onError: (err) => {
      Toast({ type: "error", message: `SOA: ${err.message}` });
      setShowLoading(false);
      setShowComplete(false);
    },
    notifyOnNetworkStatusChange: true,
  });

  const fetchSoaData = () => {
    setShowLoading(true);
    const dateOnlyArray = (dates as Date[]).map((dateString) => {
      const date = new Date(dateString);
      date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
      return date.toISOString().split("T")[0];
    });
    soaInfoFn({
      variables: {
        data: {
          email: undefined,
          startDate: dateOnlyArray[0],
          endDate: dateOnlyArray[1],
        },
      },
    });
  };

  const { transactionData, transactionRefetch, transactionLoading } =
    useTransactionList();
  const {
    data: billingCycleList,
    loading: billingCycleListLoading,
    refetch,
  } = useReadBillingCycleList();
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (
      !billingCycleListLoading &&
      billingCycleList &&
      billingCycleList.readBillingCycle.code !== "200"
    ) {
      Toast({
        type: "error",
        message: billingCycleList.readBillingCycle.message || "",
      });
    }
  }, [billingCycleList, billingCycleListLoading]);

  const customerType = readCurrentUserAuth.fuelCard.customerType;
  const isPrepaidCustomer =
    customerType === ENUM_CUSTOMER_TYPE.PREPAID ||
    customerType === ENUM_CUSTOMER_TYPE.PREPAID_PLUS ||
    customerType === ENUM_CUSTOMER_TYPE.PREPAID_ENTERPRISE;

  transactionData?.readTransactions?.transactions?.forEach((item) => {
    if (item.transactionStatus === "CAPTURE") {
      const newTransactionObj = {
        ...item,
        date: new Date(item?.authorizationDate),
        driver: item?.driverName || "-",
        vehicle: item?.vehicleName || "-",
        status: "Approved",
        lastFour: item?.card?.lastFour || "-",
      };
      currentDriversSet.add(newTransactionObj.driver);
      currentCardsSet.add(newTransactionObj.lastFour);
      transactionList.push(newTransactionObj);
    }
  });
  const [transactionsFilterConfig, setTransactionFilterConfig] = useState<{
    [key in FilterProps]: {
      operator: FilterOperator;
      constraints: [
        { value: null | string | Date; matchMode: FilterMatchMode },
      ];
    };
  }>(initFilterConfig);

  const { data: transactionUpdated } = useSubscription(
    TRANSACTION_SUBSCRIPTION,
    {
      fetchPolicy: "no-cache",
      variables: {
        data: {
          userId: readCurrentUserAuth.primaryOwnerId,
        },
      },
    },
  );
  useEffect(() => {
    transactionRefetch();
  }, [transactionRefetch, transactionUpdated]);
  useEffect(() => {
    if (
      !transactionLoading &&
      transactionData &&
      transactionData?.readTransactions?.code !== "200"
    ) {
      Toast({
        type: "error",
        message: transactionData.readTransactions.message || "",
      });
    }
  }, [transactionData, transactionLoading]);
  const billingList =
    billingCycleList?.readBillingCycle?.billingCycleList &&
    billingCycleList?.readBillingCycle?.billingCycleList?.length > 0
      ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        billingCycleList?.readBillingCycle?.billingCycleList
      : [];

  const connectToQuickbooks = async () => {
    try {
      setSubmitting(true);
      const response = await fetch(
        `${NEXT_PUBLIC_API_URL}/api/quickbooks/authUri`,
        {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
        },
      );
      const authUrl = await response.json();
      setSubmitting(false);

      // Open QuickBooks authorization URL in a new tab
      window.open(authUrl);
    } catch (err) {
      console.error("Error connecting to QuickBooks:", err);
      setSubmitting(false);
    }
  };

  const disconnectQuickbooks = async () => {
    try {
      setSubmitting(true);
      await removeQuickBookConnectionFn();
      // quickBookDataRefetch();
      // If needed, you might refetch your data or update local state
      setSubmitting(false);
      Toast({
        type: "success",
        message: "QuickBooks disconnected successfully",
      });
      // For example:
      // onSuccessfulDisconnect();
    } catch (err) {
      Toast({ type: "error", message: "Error disconnecting QuickBooks" });
      console.error("Error disconnecting QuickBooks:", err);
      setSubmitting(false);
    }
  };

  if (loadingQuickbookDetails) {
    return <Loader></Loader>;
  }
  return (
    <AccountingUI
      {...{
        isPrepaidCustomer,
        transactionList,
        billingList,
        transactionLoading,
        transactionsFilterConfig,
        setTransactionFilterConfig,
        initFilterConfig,

        showLoading,
        setShowLoading,
        showComplete,
        setShowComplete,
        dates,
        setDates,
        fetchSoaData,
        quickbookData:
          quickbookData?.getQuickBookDetails?.quickBookDetail || null,
        connectToQuickbooks,
        disconnectQuickbooks,
        submitting,
      }}
    />
  );
};
