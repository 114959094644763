/* eslint-disable @typescript-eslint/no-explicit-any */
/** @format */
import { useMutation } from "@apollo/client";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import { ADMIN_FINAL_BUSINESS_APPROVAL } from "@roadflex/graphql";
import { usePlatformDetect } from "@roadflex/react-hooks";
import { UserDetailsForAdmin } from "@roadflex/types";
import { useFormik } from "formik";
import { Calendar } from "primereact/calendar";
import { Dialog } from "primereact/dialog";
import { Tooltip } from "primereact/tooltip";
import { useRef } from "react";
import * as Yup from "yup";
import { Button, ButtonSize, ButtonType, ButtonVariant } from "../../buttons";
import { TextInput } from "../../inputs";
import { Toast } from "../../toast-message/toast";

interface ConfirmReceiptOfDepositModalProps {
  openReceiptOfDepositModal: boolean;
  setOpenReceiptOfDepositModal: (val: boolean) => void;
  userData: UserDetailsForAdmin;
  getUsers: () => void;
}

export const ConfirmReceiptOfDepositModal = ({
  openReceiptOfDepositModal,
  setOpenReceiptOfDepositModal,
  userData,
  getUsers,
}: ConfirmReceiptOfDepositModalProps) => {
  const cancelButtonRef = useRef(null);
  const { isMobile } = usePlatformDetect();
  const [finalBusinessApprovalFn, { loading }] = useMutation(
    ADMIN_FINAL_BUSINESS_APPROVAL,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
    },
  );
  const {
    handleChange,
    handleSubmit,
    handleBlur,
    values,
    touched,
    isSubmitting,
    errors,
  } = useFormik({
    initialValues: {
      depositAmount: "0",
      depositDate: null,
      status: "VERIFIED",
      userId: null,
    },
    validationSchema: Yup.object().shape({
      depositAmount: Yup.string().required("Deposit amount is required"),
      depositDate: Yup.date().required("Deposit date is required"),
    }),
    onSubmit: async (value) => {
      if (!userData) {
        Toast({ type: "error", message: "User unavailable" });
        return;
      }
      try {
        await finalBusinessApprovalFn({
          variables: {
            data: {
              depositAmount: parseInt(value.depositAmount, 10) * 100,
              depositDate: value.depositDate,
              userId: userData.id,
              status: value.status,
            },
          },
        });
        Toast({ type: "success", message: "Receipt of deposit confirmed" });
        getUsers();
        setOpenReceiptOfDepositModal(false);
      } catch (err: unknown) {
        if (err instanceof Error) {
          Toast({ type: "error", message: err.message });
        } else {
          Toast({ type: "error", message: "Unknown error happened" });
        }
      }
    },
  });

  const footer = (
    <div className="flex flex-row justify-end">
      <Button
        disabled={isSubmitting}
        variant={ButtonVariant.Gray}
        size={ButtonSize.AppSize}
        onClick={() => setOpenReceiptOfDepositModal(false)}
        ref={cancelButtonRef}
      >
        Cancel
      </Button>
      <Button
        type={ButtonType.Button}
        variant={ButtonVariant.AppOrange}
        size={ButtonSize.AppSize}
        loading={loading}
        disabled={isSubmitting || loading}
        className="ml-3"
        onClick={() => {
          handleSubmit();
        }}
      >
        Save
      </Button>
    </div>
  );

  const renderHeader = () => {
    return (
      <div className="text-base">{`Confirm receipt of deposit for ${userData.email}`}</div>
    );
  };

  return (
    <Dialog
      header={renderHeader}
      visible={openReceiptOfDepositModal}
      style={{
        width: isMobile() ? "90vw" : "30vw",
        fontFamily: "Inter",
      }}
      footer={footer}
      onHide={() => setOpenReceiptOfDepositModal(false)}
      closable={false}
    >
      <form onSubmit={handleSubmit} className="w-full">
        <div className="flex">
          <div className="w-full">
            <TextInput
              label="Deposit Amount ($):"
              type="number"
              min={0}
              step={0.01}
              name="depositAmount"
              id="depositAmount"
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.depositAmount && Boolean(errors.depositAmount)}
              errorMessage={errors.depositAmount}
              touched={touched.depositAmount}
              variant="medium"
            ></TextInput>

            <div className="flex justify-between mt-4 flex-nowrap">
              <div className="relative w-full mb-1 whitespace-nowrap">
                <div className="mb-1">Deposit Date:</div>
                <Calendar
                  id="depositDate"
                  value={values.depositDate ?? undefined}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="w-full custom-calendar h-[37.6px] text-[14px]"
                  panelClassName="custom-calendar-panel"
                />
                {touched.depositDate && errors?.depositDate && (
                  <>
                    <Tooltip
                      target=".depositDate-error"
                      position="top"
                      className="red-tooltip"
                      content={`${errors.depositDate}`}
                    ></Tooltip>

                    <div className="absolute h-[44px] bottom-0 right-0 pr-8 flex items-center cursor-pointer">
                      <ExclamationCircleIcon
                        className="w-5 h-5 text-red-500 depositDate-error"
                        aria-hidden="true"
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </form>
    </Dialog>
  );
};
