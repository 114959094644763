/* eslint-disable max-lines */
/** @format */
import { gql } from "@apollo/client";

/* Most queries are classified to the individual page (e.g. try searching "app: referrals page")
 */
export const READ_CURRENT_USER_AUTH = gql`
  query READ_CURRENT_USER_AUTH {
    readCurrentUserAuth {
      primaryOwnerId
      loggedInId
      isDefaultUser
      isParentCompanyUser
      isPasswordSet
      isBetaTester
      isSSNSet
      isEINSet
      isLoggedIn
      bypassOtpDuringLogIn
      userRole
      referralCode
      fuelCard {
        isFuelCard
        customerType
        creditCycleType
        creditLimit
        numberOfCards
        platform
        status
        metadata
      }
      factor {
        isFactor
        status
      }
      accountDisplaySetting {
        timeZone
        colorScheme
        showCompanyName
      }
      businessName
      isDeviceAuthorized
      code
      message
    }
  }
`;
export const READ_CURRENT_USER_BASIC = gql`
  query READ_CURRENT_USER_BASIC {
    readCurrentUserBasic {
      id
      email
      phoneNumber
      firstName
      lastName
      phoneLastDateOfEdit
      emailLastDateOfEdit
      isEmailVerified
      isPhoneVerified
      code
      message
    }
  }
`;
export const READ_CURRENT_USER_ONBOARD = gql`
  query READ_CURRENT_USER_ONBOARD {
    readCurrentUserOnboard {
      id
      email
      phoneNumber
      firstName
      lastName
      phoneLastDateOfEdit
      emailLastDateOfEdit
      isEmailVerified
      isPhoneVerified
      ssn
      isPasswordSet
      personaInquiryId
      personaInquirySessionToken
      fuelCard {
        isFuelCard
        customerType
        creditCycleType
        creditLimit
        numberOfCards
        onboardStatus
        status
      }
      code
      message
    }
  }
`;
export const READ_FEATURE_BY_PAGE = gql`
  query READ_FEATURE_BY_PAGE($data: ReadFeatureByPageInput) {
    readFeatureByPage(data: $data) {
      parentCommonFeature {
        id
        name
        parentId
      }
      children {
        id
        name
        parentId
        grandchildren {
          id
          name
          parentId
        }
      }
      parameterFilter {
        tag
        readWritePermission
      }
      code
      message
    }
  }
`;
export const READ_BUSINESS_INFORMATION = gql`
  query READ_BUSINESS_INFORMATION($where: BusinessWhereInput!) {
    readBusinessInformation(where: $where) {
      id
      title
      description
      cardName
      type
      companyType
      dot
      ein
      mc
      deliveryType
      paymentMethodType
      yearsOfOperation
      plaid {
        id
        institution
        itemId
        accountInfo
        isPrimary
        accountType
        authInfo
        plaidError
        newAccountsAvailable
      }
      document {
        irsLetter
      }
    }
  }
`;
export const READ_ADDRESSES = gql`
  query READ_ADDRESSES($where: ReadAddressesInput!) {
    readAddresses(where: $where) {
      addresses {
        id
        streetAddress
        streetDetails
        state
        city
        zip
        type
        isPrimary
      }
      isSameAddress
    }
  }
`;
export const READ_HOME_ADDRESS = gql`
  query READ_HOME_ADDRESS($data: ReadHomeAddressInput!) {
    readHomeAddress(data: $data) {
      id
      address {
        streetAddress
        streetDetails
        state
        city
        zip
        type
      }
      owner
    }
  }
`;
export const READ_OWNERS_AND_EXECUTIVES = gql`
  query READ_OWNERS_AND_EXECUTIVES {
    readOwnersAndExecutives {
      code
      message
      ownersAndExecutives {
        id
        firstName
        lastName
        phoneNumber
        dateOfBirth
        ownership
        email
        owner
        executive
        controller
        executiveJobTitle
        ssn
        representative
        address {
          streetAddress
          streetDetails
          state
          city
          zip
          type
        }
      }
    }
  }
`;
export const READ_ADDITIONAL_BUSINESS_INFORMATION = gql`
  query READ_ADDITIONAL_BUSINESS_INFORMATION {
    readAdditionalBusinessInformation {
      id
      addressList {
        id
        streetAddress
        streetDetails
        state
        city
        zip
        type
      }
      isSameAddress
      deliveryType
      weeklyfuelSpends
      fleetNum
      fleetType
      annualBusinessRevenue
    }
  }
`;
export const READ_PERSONAL_INFORMATION = gql`
  query READ_PERSONAL_INFORMATION {
    readPersonalInformation {
      code
      message
      personalInfo {
        id
        firstName
        lastName
        ssn
        email
        phoneNumber
        dateOfBirth
        executiveJobTitle
        fleetLicense
        fleetLicenseBack
      }
    }
  }
`;
export const PLAID_LINK_TOKEN = gql`
  query PLAID_LINK_TOKEN(
    $id: String!
    $isUpdateMode: Boolean
    $isWithTransaction: Boolean
  ) {
    linkToken(
      id: $id
      isUpdateMode: $isUpdateMode
      isWithTransaction: $isWithTransaction
    ) {
      success
      code
      type
      message
      detail
      help
      linkToken
    }
  }
`;
export const READ_PLAID_PENDING_CONNECTION = gql`
  query READ_PLAID_PENDING_CONNECTION($data: ReadPlaidPendingConnectionInput!) {
    readPlaidPendingConnection(data: $data) {
      plaidPendingConnections {
        linkToken
        institution
        plaidId
      }
      success
      code
      type
      message
    }
  }
`;
export const STRIPE_GET_EPHEMERAL_KEY = gql`
  query STRIPE_GET_EPHEMERAL_KEY($data: StripeGetEphemeralKeyInput) {
    stripeGetEphemeralKey(data: $data) {
      code
      message
      ephemeralKeySecret
    }
  }
`;

export const TRANSACTION_LIST = gql`
  query TRANSACTION_LIST($data: TransactionInput) {
    readTransactions(data: $data) {
      code
      message
      transactions {
        id
        authorizationDate
        transactionDate
        transactionStatus
        transactionAmount
        transactionType
        merchantName
        merchantState
        merchantPostCd
        stripeResponse
        purchaseDetails
        highnotePurchaseDetails
        highnoteResponse
        fuelQuantity
        fuelDiscountDetail
        fuelDiscountAmount
        cardId
        cardHolderId
        driverName
        vehicleName
        vehicleVIN
        card {
          id
          lastFour
        }
      }
    }
  }
`;
export const OLD_TRANSACTION_LIST = gql`
  query OLD_TRANSACTION_LIST($data: TransactionInput) {
    readOldTransactions(data: $data) {
      code
      message
      transactions {
        id
        authorizationDate
        transactionDate
        transactionStatus
        transactionAmount
        transactionType
        merchantName
        merchantState
        merchantPostCd
        stripeResponse
        purchaseDetails
        highnotePurchaseDetails
        highnoteResponse
        fuelQuantity
        fuelDiscountDetail
        fuelDiscountAmount
        cardId
        cardHolderId
        driverName
        vehicleName
        vehicleVIN
        card {
          id
          lastFour
        }
      }
    }
  }
`;
export const IMPORTED_TRANSACTION_LIST = gql`
  query IMPORTED_TRANSACTION_LIST($data: TransactionInput) {
    readImportedTransactions(data: $data) {
      code
      message
      transactions {
        id
        authorizationDate
        transactionDate
        transactionStatus
        transactionAmount
        transactionType
        merchantName
        merchantState
        merchantPostCd
        stripeResponse
        purchaseDetails
        highnotePurchaseDetails
        highnoteResponse
        fuelQuantity
        fuelDiscountDetail
        fuelDiscountAmount
        cardHolderId
        driverName
        vehicleName
        vehicleVIN
        extraInfo
        createdAt
      }
    }
  }
`;
export const RESET_PASSWORD_VERIFY = gql`
  query RESET_PASSWORD_VERIFY($token: String!) {
    resetPasswordVerify(token: $token)
  }
`;
export const RESET_PASSWORD_DRIVER_VERIFY = gql`
  query RESET_PASSWORD_DRIVER_VERIFY($token: String!) {
    resetPasswordDriverVerify(token: $token)
  }
`;
export const READ_BILLING_CYCLE = gql`
  query READ_BILLING_CYCLE($data: EmailInput) {
    readBillingCycle(data: $data) {
      code
      message
      billingCycleList {
        updatedAt
        billingCycleId
        cardHolderId
        endingDate
        startingDate
        dueDate
        spendings
        rewards
        outstandingAmount
        status
        lateFees
        invoiceId
        isSentToQuickbooks
        disableLateFeesButton
        createdManually
        paymentTerms
        archive
        billingCycleItems {
          id
          itemName
          itemAmount
          order
        }
      }
    }
  }
`;
export const READ_BILLING_CYCLE_PAGINATION = gql`
  query READ_BILLING_CYCLE_PAGINATION($data: BillingCycleListInput) {
    readBillingCyclePagination(data: $data) {
      code
      message
      totalCount
      billingCycleList {
        updatedAt
        billingCycleId
        cardHolderId
        endingDate
        startingDate
        dueDate
        spendings
        rewards
        outstandingAmount
        status
        lateFees
        invoiceId
        isSentToQuickbooks
        disableLateFeesButton
        createdManually
        paymentTerms
      }
    }
  }
`;
export const READ_FLEET_INFORMATION = gql`
  query READ_FLEET_INFORMATION($where: FleetInfoWhereInput!) {
    readFleetInfo(where: $where) {
      fuel
      fleetNum
      fleetType
    }
  }
`;
//app: onboard
export const READ_DOCUMENTS = gql`
  query READ_DOCUMENTS($where: DocumentWhereInput!) {
    readDocuments(where: $where) {
      fleetLicense
      fleetLicenseBack
      statements
    }
  }
`;
export const READ_DEFAULT_SETTINGS = gql`
  query READ_DEFAULT_SETTINGS {
    adminDefaultSetting {
      id
      creditCycleType
      creditLimit
      numberOfCards
      customerType
    }
  }
`;

//app: no access
export const GET_PREPAID_ELIGIBLE = gql`
  query GET_PREPAID_ELIGIBLE {
    isEligibleForPrepaid
  }
`;

//app: dashboard page
export const READ_APP_DASHBOARD_DATA = gql`
  query READ_APP_DASHBOARD_DATA($data: ReadAppDashboardDataInput!) {
    readAppDashboardData(data: $data) {
      appDashboardData {
        latestCycle {
          id
          startDt
          endDt
          createdAt
          totalAmount
        }
        currentCycle {
          startDt
          endDt
        }
        setting {
          id
          creditCycleType
          creditLimit
          numberOfCards
          additionalLimit
          updatedCreditLimit
          customerType
          metaData
        }
        outstandingAmount {
          totalAmountDue
          outstandingAmountAfterWalletDeduction
          outstandingAmountAfterWalletDeductionFailedOnly
          pastStatementDue
          currentStatementDue
        }
        wallet {
          id
          signOnBalance
          referralBalance
          cashBalance
          adminBalance
        }
        fuelDiscount {
          bonusType
          bonusAmount
          targetFuelQuantity
          totalFuelQuantity
        }
        cards {
          active
          inactive
          canceled
        }
        totalSavings
        totalSpendings
        creditRemaining
        amountCorrection
        weeklySpends
        categoryBreakdown
        totalFraudDeclinedTransaction
        totalFraudSavings
        totalLifeTimeSavings
      }
      code
      message
    }
  }
`;
export const USER_WALLET_ITEMS_AND_STATISTICS_FOR_PREPAID = gql`
  query USER_WALLET_ITEMS_AND_STATISTICS_FOR_PREPAID {
    userWalletItemsAndStatisticsForPrepaid {
      isPrepaidUser
      totalAmountSpentInTransactions
      totalAmountSpentInPendingAuthorizations
      totalAmountUsedForWalletRecharges
    }
  }
`;
export const READ_USER_WALLET_ITEMS = gql`
  query READ_USER_WALLET_ITEMS($data: GetWalletItemInput) {
    readUserWalletItem(data: $data) {
      code
      message
      totalCount
      walletItems {
        type
        ItemType
        amount
        classification
        fuelDiscountAmount
        createdAt
      }
    }
  }
`;
//app: transactions page
export const READ_TRANSACTION_LIST_PAGINATION = gql`
  query READ_TRANSACTION_LIST_PAGINATION($data: ReadTransactionListInput) {
    readTransactionList(data: $data) {
      code
      message
      totalCount
      transactions {
        id
        authorizationDate
        transactionDate
        transactionStatus
        transactionAmount
        transactionType
        merchantName
        merchantState
        merchantPostCd
        stripeResponse
        purchaseDetails
        highnotePurchaseDetails
        highnoteResponse
        fuelQuantity
        fuelDiscountDetail
        fuelDiscountAmount
        cardId
        cardHolderId
        driverName
        vehicleName
        vehicleVIN
        flagReason
        extraInfo
        memo
        card {
          id
          lastFour
          metaData
        }
        transactionTagId
        transactionTagName
        transactionTagDescription
        cardTagId
        cardTagName
        cardTagDescription
        driverTagId
        driverTagName
        driverTagDescription
        vehicleTagId
        vehicleTagName
        vehicleTagDescription
        accountTags {
          id
          title
          description
          tagId
          tagType
          colorCode
        }
        transactionReceiptRequired
        receiptUploaded
        policyName
        receipts {
          fileName
          fileExtension
          folderName
        }
      }
    }
  }
`;
export const READ_AUTHORIZATION_LIST_PAGINATION = gql`
  query READ_AUTHORIZATION_LIST_PAGINATION($data: ReadAuthorizationListInput) {
    readAuthorizationList(data: $data) {
      code
      message
      totalCount
      authorizations {
        id
        authorizationDate
        approvalStatus
        authorizationAmount
        merchantName
        merchantState
        merchantPostCd
        stripeResponse
        cardId
        cardHolderId
        authorizationStatus
        driverName
        vehicleName
        vehicleVIN
        declineReason
        extraInfo
        memo
        card {
          id
          lastFour
          metaData
        }
        transactionTagId
        transactionTagName
        transactionTagDescription
        cardTagId
        cardTagName
        cardTagDescription
        driverTagId
        driverTagName
        driverTagDescription
        vehicleTagId
        vehicleTagName
        vehicleTagDescription
        transactionTags
        cardTags
        driverTags
        vehicleTags
        policyName
        receipts {
          fileName
          folderName
          fileExtension
          createdAt
        }
        accountTags {
          id
          title
          description
          tagId
          tagType
          colorCode
        }
      }
    }
  }
`;
export const READ_TRANSACTION_DETAIL = gql`
  query READ_TRANSACTION_DETAIL($data: TransactionDetailInput) {
    readTransactionDetail(data: $data) {
      code
      message
      transaction {
        id
        authorizationDate
        transactionDate
        transactionStatus
        transactionAmount
        merchantName
        merchantState
        merchantPostCd
        stripeResponse
        purchaseDetails
        highnotePurchaseDetails
        highnoteResponse
        fuelQuantity
        fuelDiscountDetail
        fuelDiscountAmount
        cardId
        cardHolderId
        driverName
        vehicleName
        vehicleVIN
        extraInfo
        memo
        card {
          id
          lastFour
          fleet {
            fleetName
            make
            model
            year
          }
        }
        receipts {
          fileName
          folderName
          fileExtension
          createdAt
        }
        authorization {
          extraInfo
        }
        transactionTagId
        cardTagId
        driverTagId
        vehicleTagId
        transactionTags
        cardTags
        driverTags
        vehicleTags
      }
    }
  }
`;
export const READ_AUTHORIZATION_DETAIL = gql`
  query READ_AUTHORIZATION_DETAIL($data: AuthorizationDetailInput) {
    readAuthorizationDetail(data: $data) {
      code
      message
      authorization {
        memo
        transactionTagId
        cardTagId
        driverTagId
        vehicleTagId
        extraInfo
        transactionTags
        cardTags
        driverTags
        vehicleTags
        receipts {
          fileName
          folderName
          fileExtension
          createdAt
        }
      }
    }
  }
`;
export const IFTA_TRANSACTION_LIST = gql`
  query IFTA_TRANSACTION_LIST($data: IftaTransactionInput) {
    iftaTransaction(data: $data) {
      success
      code
      type
      message
      detail
      help
      iftaTransactions {
        date
        jurisdiction
        vehicle
        fuelType
        volume
        cost
        vendor
        lastFour
        transactionTagId
        transactionTagName
        transactionTagDescription
        cardTagId
        cardTagName
        cardTagDescription
        driverTagId
        driverTagName
        driverTagDescription
        vehicleTagId
        vehicleTagName
        vehicleTagDescription
      }
    }
  }
`;
export const TRANSACTION_FILTER_OPTIONS = gql`
  query TRANSACTION_FILTER_OPTIONS($data: EmailInput) {
    transactionFilterOptions(data: $data) {
      code
      message
      cards {
        id
        lastFour
        createdAt
      }
      drivers {
        id
        name
        isPhoneVerified
        createdAt
      }
      vehicles {
        id
        fleetName
        createdAt
        tankCapacity
        fuelType
        eldOrgName
      }
    }
  }
`;
export const READ_TRANSACTION_DASHBOARD_DETAILS = gql`
  query READ_TRANSACTION_DASHBOARD_DETAILS {
    readTransactionDashboardDetails {
      transactionDashboardDetails {
        totalTransactionAmount
        totalTransactionCount
        totalDiscount
        blockMerchants
        blockStates
      }
      code
      message
    }
  }
`;
//app: account tags page
export const READ_ACCOUNT_TAGS = gql`
  query READ_ACCOUNT_TAGS($data: ReadAccountTagsInput) {
    readAccountTags(data: $data) {
      code
      message
      totalNumberOfTags
      accountTagList {
        TRANSACTION {
          active {
            id
            title
            description
            tagId
            promptCode
            tagType
            colorCode
            deleted
            createdAt
            updatedAt
          }
          deleted {
            id
            title
            description
            tagId
            promptCode
            tagType
            colorCode
            deleted
            createdAt
            updatedAt
          }
        }
        CARD {
          active {
            id
            title
            description
            tagId
            promptCode
            tagType
            colorCode
            deleted
            createdAt
            updatedAt
          }
          deleted {
            id
            title
            description
            tagId
            promptCode
            tagType
            colorCode
            deleted
            createdAt
            updatedAt
          }
        }
        DRIVER {
          active {
            id
            title
            description
            tagId
            promptCode
            tagType
            colorCode
            deleted
            createdAt
            updatedAt
          }
          deleted {
            id
            title
            description
            tagId
            promptCode
            tagType
            colorCode
            deleted
            createdAt
            updatedAt
          }
        }
        VEHICLE {
          active {
            id
            title
            description
            tagId
            promptCode
            tagType
            colorCode
            deleted
            createdAt
            updatedAt
          }
          deleted {
            id
            title
            description
            tagId
            promptCode
            tagType
            colorCode
            deleted
            createdAt
            updatedAt
          }
        }
      }
    }
  }
`;
export const READ_SINGLE_ACCOUNT_TAG = gql`
  query READ_SINGLE_ACCOUNT_TAG($data: ReadAccountTagsInput) {
    readSingleAccountTag(data: $data) {
      code
      message
      accountTagList {
        id
        title
        description
        promptCode
        tagId
        tagType
        deleted
        createdAt
        updatedAt
      }
    }
  }
`;
//app: policy page
export const READ_CARD_POLICY = gql`
  query READ_CARD_POLICY($data: ReadCardPolicyInput) {
    readCardPolicy(data: $data) {
      policy {
        id
        name
        description
        smsSecurity
        fuelTypeSecurity
        odometerSecurity
        requireJobID
        suspiciousFuelingSecurity
        telematicsSecurity
        tankCapacitySecurity
        enableSmsReceiptUpload
        flaggedMerchantCategories
        authorizationMethod
        merchants {
          classification
          authorized
          toggleOn
          name
          numberOfPurchaseLimitToggleOn
          numberOfPurchaseLimit
          numberOfPurchaseFrequency
          totalSpendLimitToggleOn
          totalSpendLimit
          totalSpendFrequency
          requireMemo
          requireReceiptAmount
          requireReceiptToggleOn
        }
        enableOperationalHours
        blockState
        countries
        operationalHours {
          dayOfWeek
          startTime
          endTime
        }
        cardSpending {
          id
          interval
          amount
        }
        genericIdPromptMode
        genericIdPromptEntityType
      }
      message
      code
    }
  }
`;
//app: cards page
export const READ_APP_CARD_DATA = gql`
  query READ_APP_CARD_DATA($data: ReadAppCardDataInput) {
    readAppCardData(data: $data) {
      cards {
        id
        lastFour
        cardType
        cardId
        metaData
        platform
        stripeCardProgram
        authorizationMethod
        genericIdPromptEntityType
        genericIdPromptMode
        blockedMerchantsNetworkIds {
          merchantName
          networkId
        }
        merchants {
          classification
          authorized
          toggleOn
          name
          numberOfPurchaseLimitToggleOn
          numberOfPurchaseLimit
          numberOfPurchaseFrequency
          totalSpendLimitToggleOn
          totalSpendLimit
          totalSpendFrequency
          requireMemo
          requireReceiptAmount
          requireReceiptToggleOn
        }
        enableOperationalHours
        blockState
        countries
        operationalHours {
          dayOfWeek
          startTime
          endTime
        }
        type
        currency
        status
        smsSecurity
        fuelTypeSecurity
        tankCapacitySecurity
        telematicsSecurity
        odometerSecurity
        requireJobID
        enableSmsReceiptUpload
        suspiciousFuelingSecurity
        flaggedMerchantCategories
        fleetDriver {
          id
          email
          name
          isPhoneVerified
        }
        fleet {
          id
          vin
          fleetName
        }
        cardSpending {
          id
          interval
          amount
          category
        }
        cardShipping {
          deliveryStatus
          deliveryPartner
          deliveryService
          deliveryType
          trackingNumber
          trackingUrl
          eta
          shippingLogs
        }
        accountTag {
          id
          title
          description
          tagId
          tagType
          colorCode
          deleted
        }
      }
      business {
        id
        title
        description
        cardName
        type
        companyType
        dot
        ein
        mc
        deliveryType
        paymentMethodType
        plaid {
          id
          institution
          itemId
          accountInfo
          isPrimary
          accountType
          authInfo
          plaidError
          newAccountsAvailable
        }
      }
      cardsCharged
      code
      message
    }
  }
`;
//app: parent company user page
export const READ_PARENT_COMPANY_USER = gql`
  query READ_PARENT_COMPANY_USER {
    readParentCompanyUser {
      role
      profilesAuthorized {
        email
        businessName
        id
        roles {
          roleId
          role {
            name
          }
        }
      }
      code
      message
    }
  }
`;
export const READ_ALL_PARENT_COMPANY_USERS = gql`
  query READ_ALL_PARENT_COMPANY_USERS {
    readAllParentCompanyUsers {
      users {
        id
        email
        phoneNumber
        profilesAuthorized {
          email
          businessName
          id
          roles {
            roleId
            role {
              name
            }
          }
        }
      }
      code
      message
    }
  }
`;
//app: drivers page
export const READ_DRIVERS = gql`
  query READ_DRIVERS($data: IdInputList) {
    readDrivers(data: $data) {
      drivers {
        id
        businessId
        cardId
        name
        email
        phoneNumber
        status
        notes
        promptCode
        createdAt
        transaction {
          authorizationDate
          transactionAmount
          fuelQuantity
          merchantName
          merchantState
          merchantPostCd
          stripeResponse
        }
        accountTags {
          id
          title
          description
          tagId
          tagType
          colorCode
          deleted
        }
      }
      code
      message
    }
  }
`;
//app: vehicles page
export const READ_VEHICLES = gql`
  query READ_VEHICLES($data: IdInputList) {
    readVehicles(data: $data) {
      vehicles {
        id
        businessId
        fleetName
        vin
        make
        model
        year
        fuel
        fleetNum
        odometer
        promptCode
        fuelType
        status
        tankCapacity
        provisionState
        fleetLicensePlate
        createdAt
        eldOrgName
        transaction {
          authorizationDate
          transactionAmount
          fuelQuantity
          merchantName
          merchantPostCd
          stripeResponse
        }
        accountTags {
          id
          title
          description
          tagId
          tagType
          colorCode
          deleted
        }
      }
      code
      message
    }
  }
`;
//app: payment methods page
export const READ_PAYMENT_SETTINGS = gql`
  query READ_PAYMENT_SETTINGS {
    readPaymentSettings {
      showRepaymentButton
      userDepositCode
    }
  }
`;
export const READ_STRIPE_CUSTOMER_PAYMENT_METHODS = gql`
  query READ_STRIPE_CUSTOMER_PAYMENT_METHODS {
    readStripeCustomerPaymentMethods {
      paymentMethods {
        brand
        last4
        exp_year
        exp_month
        paymentMethodId
        default
      }
      code
      message
    }
  }
`;
export const GET_AUTO_RELOAD_DETAILS = gql`
  query GET_AUTO_RELOAD_DETAILS {
    getAutoReloadDetails {
      enabled
      topUpAmount
      belowAmount
      reloadFrequency
      reloadType
      creditLimitThreshold
    }
  }
`;
export const GET_REPAYMENT_PLAN_DETAILS = gql`
  query GET_REPAYMENT_PLAN_DETAILS {
    getRepayment {
      id
      amount
      durationInNumber
      repaymentDurationType
      paymentHistory {
        id
        amount
        status
        paymentType
        stripeResponse
        failureReason
        modernTreasuryStatus
        mtResponse
      }
    }
  }
`;
//App: account documents page
export const READ_ACCOUNT_DOCUMENTS = gql`
  query READ_ACCOUNT_DOCUMENTS($data: ReadAccountDocumentsInput) {
    readAccountDocuments(data: $data) {
      message
      code
      accountDocuments {
        id
        fileName
        fileExtension
        folderName
        fileType
        createdAt
        updatedAt
        metaData
        deleted
        published
      }
    }
  }
`;
//app: alerts
export const READ_ALERTS = gql`
  query READ_ALERTS($data: ReadAlertsInput) {
    readAlerts(data: $data) {
      message
      code
      alerts {
        id
        createdAt
        message
        type
        deleted
        read
      }
      totalCount
      totalUnreadCount
      latestAlerts {
        id
        createdAt
        message
        type
        deleted
        read
      }
    }
  }
`;

export const READ_ALERTS_GEOTAB_IFRAME = gql`
  query READ_ALERTS_GEOTAB_IFRAME($data: ReadAlertsGeoTabIframeInput) {
    readAlertsGeoTabIframe(data: $data) {
      message
      code
      alerts {
        id
        createdAt
        message
        type
        deleted
        read
      }
      totalCount
      totalUnreadCount
      latestAlerts {
        id
        createdAt
        message
        type
        deleted
        read
      }
    }
  }
`;
//app: fuel station finder page
export const READ_FUEL_STATIONS = gql`
  query READ_FUEL_STATIONS($data: ReadFuelStationsInput) {
    readFuelStations(data: $data) {
      markers {
        latitude
        longitude
        title
        description
        image
        rating
        ratingNumber
        vicinity
        fuelPrices {
          currencyCode
          units
          nanos
          type
          updateTime
        }
      }
      stats {
        fuelType
        lowest {
          station {
            title
            latitude
          }
          price
        }
        highest {
          station {
            title
            latitude
          }
          price
        }
        average
      }
      closestMarker {
        station {
          title
          latitude
        }
        distanceMiles
      }
      code
      message
    }
  }
`;
//app: reports
export const CARD_ANALYSIS_DATA = gql`
  query CARD_ANALYSIS_DATA($data: ReadCardAnalysisDataInput) {
    readCardAnalysisData(data: $data) {
      code
      message
      transactions {
        id
        authorizationDate
        transactionDate
        transactionStatus
        transactionAmount
        transactionType
        merchantName
        merchantState
        merchantPostCd
        stripeResponse
        purchaseDetails
        highnotePurchaseDetails
        fuelQuantity
        fuelDiscountDetail
        fuelDiscountAmount
        cardId
        cardHolderId
        driverName
        vehicleName
        vehicleVIN
        card {
          id
          lastFour
        }
      }
      gmvGraphData
      transactionCountGraphData
    }
  }
`;
export const TAG_ANALYSIS_DATA = gql`
  query TAG_ANALYSIS_DATA($data: ReadTagAnalysisDataInput) {
    readTagAnalysisData(data: $data) {
      code
      message
      transactions {
        id
        authorizationDate
        transactionDate
        transactionStatus
        transactionAmount
        transactionType
        merchantName
        merchantState
        merchantPostCd
        stripeResponse
        purchaseDetails
        highnotePurchaseDetails
        fuelQuantity
        fuelDiscountDetail
        fuelDiscountAmount
        cardId
        cardHolderId
        driverName
        vehicleName
        vehicleVIN
        card {
          id
          lastFour
        }
      }
      gmvGraphData
      transactionCountGraphData
    }
  }
`;
export const FRAUD_ANALYSIS_DATA = gql`
  query FRAUD_ANALYSIS_DATA($data: ReadFraudDeclinedTransactionsInput!) {
    readFraudDeclinedTransactionReport(data: $data) {
      dailyFraudDeclinedTransactions
      weeklyFraudDeclinedTransactions
      categoryBreakdown
      code
      message
    }
  }
`;
export const DRIVER_ANALYSIS_DATA = gql`
  query DRIVER_ANALYSIS_DATA($data: ReadDriverAnalysisDataInput) {
    readDriverAnalysisData(data: $data) {
      code
      message
      data {
        driverName
        cardNumber
        vehicleName
        totalSpend
        discountAmount
        flagCount
        declineCount
        fuelQuantity
      }
    }
  }
`;
export const FLEET_ANALYSIS_DATA = gql`
  query FLEET_ANALYSIS_DATA($data: ReadFleetAnalysisDataInput) {
    readFleetAnalysisData(data: $data) {
      code
      message
      categoryBreakdown
      weeklySpends
      blockedTransactionsList {
        declineReason
        numberOfOccurrences
        numberOfUniqueDrivers
      }
    }
  }
`;
export const TECHNICAL_ANALYSIS_DATA = gql`
  query TECHNICAL_ANALYSIS_DATA($data: ReadTechnicalAnalysisDataInput) {
    readTechnicalAnalysisData(data: $data) {
      code
      message
      cards {
        id
        lastFour
        cardType
        cardId
        metaData
        platform
        merchants {
          classification
          authorized
          toggleOn
          name
          numberOfPurchaseLimitToggleOn
          numberOfPurchaseLimit
          numberOfPurchaseFrequency
          totalSpendLimitToggleOn
          totalSpendLimit
          totalSpendFrequency
          requireMemo
          requireReceiptAmount
          requireReceiptToggleOn
        }
        enableOperationalHours
        operationalHours {
          dayOfWeek
          startTime
          endTime
        }
        type
        currency
        status
        smsSecurity
        fuelTypeSecurity
        tankCapacitySecurity
        telematicsSecurity
        odometerSecurity
        requireJobID
        enableSmsReceiptUpload
        suspiciousFuelingSecurity
        flaggedMerchantCategories
        fleetDriver {
          id
          email
          name
          isPhoneVerified
        }
        fleet {
          id
          vin
          fleetName
        }
        cardSpending {
          id
          interval
          amount
          category
        }
        cardShipping {
          deliveryStatus
          deliveryPartner
          deliveryService
          deliveryType
          trackingNumber
          trackingUrl
          eta
          shippingLogs
        }
        accountTag {
          id
          title
          description
          tagId
          tagType
          colorCode
          deleted
        }
      }
      vehicles {
        vehicleName
        cardNumber
        driverName
        spend90Days
        discount90Days
        spend60Days
        discount60Days
        spend30Days
        discount30Days
        fuelQuantity
      }
      drivers {
        vehicleName
        cardNumber
        driverName
        spend90Days
        discount90Days
        spend60Days
        discount60Days
        spend30Days
        discount30Days
        fuelQuantity
      }
    }
  }
`;
//app: account settings page
export const READ_USER_ACTIVITY_LOGS = gql`
  query READ_USER_ACTIVITY_LOGS {
    readUserActivityLogs {
      userActivityLogs {
        id
        activity
        activityType
        activityDetails
        createdAt
        email
        firstName
        lastName
        parentFirstName
        parentLastName
        parentId
        parentEmail
      }
      code
      message
    }
  }
`;
export const READ_ACCOUNT_DISPLAY_SETTING = gql`
  query READ_ACCOUNT_DISPLAY_SETTING {
    readAccountDisplaySetting {
      timeZone
      colorScheme
      showCompanyName
      message
      code
    }
  }
`;
export const READ_AUTHORIZATION_BEARER = gql`
  query READ_AUTHORIZATION_BEARER {
    readAuthorizationBearer {
      code
      message
      authorizationBearer
    }
  }
`;
//app: multiple roles page
export const READ_ACCOUNT_USERS = gql`
  query READ_ACCOUNT_USERS($data: IdInput) {
    readAccountUsers(data: $data) {
      childUsers {
        id
        email
        isEmailVerified
        firstName
        lastName
        phoneNumber
        roles {
          role {
            name
          }
        }
        otherUserDetail {
          status
          parameterFilter {
            tag
            readWritePermission
          }
          userNotification {
            id
            name
            category
            description
            communicationMode
            isSmsDisabled
            isEmailDisabled
            optionValues
            variables
          }
        }
      }
      parent {
        id
        email
        firstName
        lastName
        phoneNumber
      }
      code
      message
    }
  }
`;
export const READ_ACCOUNT_USERS_VERIFY = gql`
  query READ_ACCOUNT_USERS_VERIFY($data: ReadAccountUsersInputVerifyInput!) {
    readAccountUsersVerify(data: $data) {
      childUsers {
        id
        email
        isEmailVerified
        firstName
        lastName
        phoneNumber
        roles {
          role {
            name
          }
        }
        otherUserDetail {
          status
          userNotification {
            name
            category
            description
            communicationMode
            isSmsDisabled
            isEmailDisabled
            optionValues
            variables
          }
        }
      }
      code
      message
    }
  }
`;
//app: referrals page
export const READ_REFERRALS = gql`
  query READ_REFERRALS($data: EmailInput) {
    readReferrals(data: $data) {
      referralEarnings
      referralCode
      referralSetting {
        bonusAmount
        bonusType
        fuelQuantity
        days
      }
      referred {
        firstName
        lastName
        email
        createdAt
        totalFuelQuantity
        bonusAmount
        status
      }
      code
      message
    }
  }
`;
export const ADMIN_EXPORT_USERS_DATA = gql`
  query ADMIN_EXPORT_USERS_DATA {
    exportUsersData {
      accountNumber
      businessName
      address1
      address2
      city
      state
      zipCode
      phone
      dateOpen
      accountType
      closedReason
      terms
      dateOfLastPayment
      totalBalanceDue
      currentFuture
      daysPastDue_1_30
      chargeOffDate
      dateClose
      chargeOffAmount
      daysPastDue_31_60
      daysPastDue_61_90
      daysPastDue_91_120
    }
  }
`;

export const ADMIN_EXPORT_TRANSACTIONS_DATA = gql`
  query ADMIN_EXPORT_TRANSACTIONS_DATA {
    exportTransactionsData {
      date
      amount
      fuelQuantity
      fuelUnitCost
      fuelType
      fuelUnit
      fuelCategory
      fuelSubCategory
      merchantName
      merchantState
      merchantCategory
    }
  }
`;

export const READ_ACCOUNT_STATUS = gql`
  query READ_ACCOUNT_STATUS {
    readAccountStatus {
      code
      message
      userAccount {
        firstName
        lastName
        email
        cardholderStatus
        inactiveReasons {
          type
          message
        }
      }
    }
  }
`;

export const GET_USER_CARD_ACTIVITY_LOGS = gql`
  query GET_USER_CARD_ACTIVITY_LOGS($where: EmailInput!) {
    getUserCardActivityLogs(where: $where) {
      id
      userId
      activity
      activityType
      createdAt
      user {
        id
        email
        firstName
        lastName
      }
    }
  }
`;

export const READ_CARDHOLDER_STATUS = gql`
  query READ_CARDHOLDER_STATUS($data: EmailInput!) {
    readCardholderMembershipStatus(data: $data) {
      id
      status
      ACHAttempt
    }
  }
`;

export const READ_ADMIN_COMMENTS = gql`
  query READ_ADMIN_COMMENTS($data: EmailInput!) {
    readAdminComments(data: $data) {
      message
      code
      comments {
        id
        adminId
        comments
        adminFname
        adminLname
        type
        createdAt
        pin
      }
    }
  }
`;

export const READ_USER_DETAILS = gql`
  query READ_USER_DETAILS($data: EmailBusinessNamePhoneInput) {
    readUserDetails(data: $data) {
      code
      message
      details {
        id
        userLeadId
        email
        phoneNumber
        firstName
        lastName
        isEmailVerified
        isPhoneVerified
        customerSource
        emailLastDateOfEdit
        phoneLastDateOfEdit
        internalDocumentUpload
        underwritingStage
        underwritingIssues
        userRole
        address {
          id
          streetAddress
          streetDetails
          state
          city
          zip
          type
        }
        fleet {
          fuel
          fleetNum
          fleetType
        }
        setting {
          id
          creditCycleType
          previousCreditCyleType
          creditLimit
          numberOfCards
          customerType
          additionalLimit
          adminOrderedCards
          updatedCreditLimit
          platform
        }
        businessDetails {
          id
          title
          deliveryType
          type
          companyType
          cardName
          ein
          dot
          description
          mc
          paymentMethodType
        }
        userDepositCode
        status
        statusUpdatedAt
        submittedAt
        approvedAt
        plaidAccounts {
          id
          institution
          isPrimary
          plaidError
        }
        document {
          fleetLicense
          fleetLicenseBack
          statements
          irsLetter
        }
        referralSetting {
          bonusAmount
          bonusType
          fuelQuantity
          days
        }
        accountCapabilities {
          transfers
          card_issuing
          card_payments
        }
        cardHoldersMembership {
          id
          status
          ACHAttempt
          amountCorrection
          blockMerchant
          blockedMerchantsNetworkIds {
            merchantName
            networkId
          }
        }
        latestAuthorization {
          id
          authorizationDate
          authorizationStatus
        }
        ssn
        highRisk
        customerTier
        isDefault
        customerSupportAdminId
        salesAdminId
        ownersAndExecutives {
          firstName
          lastName
          phoneNumber
          dateOfBirth
          ownership
          email
          owner
          executive
          executiveJobTitle
          ssn
          representative
          # address {
          #   streetAddress
          #   streetDetails
          #   state
          #   city
          #   zip
          #   type
          # }
        }
      }
    }
  }
`;

export const SEARCH_USERS = gql`
  query SEARCH_USERS($data: EmailBusinessNamePhoneInput) {
    searchUsers(data: $data) {
      results
      code
      message
    }
  }
`;

export const READ_USER_CUSTOMIZED_SETTING = gql`
  query READ_USER_CUSTOMIZED_SETTING($data: EmailInput!) {
    readUserCustomizedSettings(data: $data) {
      monthlyCardFee
      printingFee
      shippingFee
      requireSignatureFee
      virtualOrderFee
      allowACHPaymentMethod
      allowDebitCardPaymentMethod
      allowCreditCardPaymentMethod
      cardProfiles {
        name
        cardProfileId
      }
      stripeCardProfiles {
        name
        cardProfileType
      }
      countriesToEnable {
        name
        percentageFees
        dollarFees
      }
      message
      code
    }
  }
`;

export const READ_ADDITIONAL_MERCHANT_CATEGORIES = gql`
  query READ_ADDITIONAL_MERCHANT_CATEGORIES($data: EmailInput) {
    readAdditionalMerchantCategories(data: $data) {
      additionalCategory
      code
      message
    }
  }
`;

export const GET_QUICKBOOK_DETAILS = gql`
  query GET_QUICKBOOK_DETAILS {
    getQuickBookDetails {
      code
      message
      quickBookDetail {
        realmId
        accessToken
        refreshToken
        accessTokenExpDt
        refreshTokenExpDt
        isAuthenticated
      }
    }
  }
`;

export const USER_WALLET_ITEMS = gql`
  query USER_WALLET_ITEMS($data: EmailInput) {
    getUserWalletItems(data: $data) {
      amount
      details
      createdAt
      paymentId
      type
      ItemType
    }
  }
`;

export const CURRENT_DRIVER = gql`
  query CURRENT_DRIVER($data: CurrentDriverInput!) {
    currentDriver(data: $data) {
      id
      name
      status
      cardId
      email
      phoneNumber
    }
  }
`;

export const GET_DISPLAY_UNDERWRITING_DATA_STATUS = gql`
  query GET_DISPLAY_UNDERWRITING_DATA_STATUS(
    $data: displayUnderwritingDataStatusInput!
  ) {
    displayUnderwritingDataStatus(data: $data) {
      suitableApplication
      kycStatuses
      kybStatuses
      lendFlowStatuses
      underwritingSetting
      validationDataStatuses
      verificationDataStatuses
    }
  }
`;

export const DRIVER_PENDING_AUTHORIZATION_LIST = gql`
  query DRIVER_PENDING_AUTHORIZATION_LIST {
    driverPendingAuthorization {
      success
      code
      type
      message
      detail
      help
      pendingAuthorization {
        id
        authorizationStatus
        authorizationDate
        authorizationAmount
        merchantName
        merchantState
        merchantPostCd
      }
    }
  }
`;

export const DRIVER_DECLINED_AUTHORIZATION_LIST = gql`
  query DRIVER_DECLINED_AUTHORIZATION_LIST {
    driverDeclinedAuthorization {
      success
      code
      type
      message
      detail
      help
      declinedAuthorization {
        id
        authorizationStatus
        authorizationDate
        authorizationAmount
        merchantName
        merchantState
        merchantPostCd
      }
    }
  }
`;

export const DRIVER_TRANSACTION_LIST = gql`
  query DRIVER_TRANSACTION_LIST {
    driverTransaction {
      success
      code
      type
      message
      detail
      help
      transactions {
        id
        authorizationDate
        transactionDate
        transactionStatus
        transactionAmount
        merchantName
        merchantState
        merchantPostCd
        purchaseDetails
        fuelDiscountDetail
        fuelDiscountAmount
        fuelQuantity
        memo
      }
    }
  }
`;

export const USER_APPLICANTS_LAZY = gql`
  query USER_APPLICANTS_LAZY($data: UserApplicantsInput) {
    userApplicants(data: $data) {
      totalCount
      applicant {
        userProfileId
        businessId
        firstName
        lastName
        email
        title
        statusUpdatedAt
        businessProcessId
        updatedAt
        submittedAt
        onBoardStatus
        createdAt
        creditLimit
        cardHolderStatus
        phoneNumber
        underwritingStage
        underwritingMessage
        userProductStatusMessage
        highRisk
        customerType
        salesAdminFirstName
        salesAdminLastName
        parentCompanyUserAuthorizedProfileId
      }
    }
  }
`;

export const USER_LEAD_APPLICANTS_LAZY = gql`
  query USER_LEAD_APPLICANTS_LAZY($data: UserApplicantsInput) {
    userLeadApplicant(data: $data) {
      totalCount
      applicant {
        userProfileId
        firstName
        lastName
        email
        title
        statusUpdatedAt
        businessProcessId
        status
        updatedAt
        submittedAt
        onBoardStatus
        createdAt
        phoneNumber
        underwritingMessage
        userProductStatusMessage
        highRisk
        userLeadId
        customerType
        stripeResponse
        salesAdminFirstName
        salesAdminLastName
        underwritingStage
        customerSource
      }
    }
  }
`;

export const GET_ACH_PAYMENT_FAILED_DATA = gql`
  query GET_ACH_PAYMENT_FAILED_DATA($data: achPaymentStatusInput) {
    achPaymentStatus(data: $data) {
      achPaymentList {
        payId
        accountId
        payIntentId
        chargeId
        failureReason
        cardHolder {
          user {
            email
          }
        }
      }
    }
  }
`;

export const FLEETIO_CONNECTED = gql`
  query FLEETIO_CONNECTED {
    isFleetioConnected {
      code
      message
      connected
      deleted
    }
  }
`;

export const ELD_CONNECTED = gql`
  query ELD_CONNECTED {
    isEldConnected {
      isConnected
      orgName
    }
  }
`;

export const READ_PROSPECTIVE_LEADS_FILE_ENTRIES = gql`
  query READ_PROSPECTIVE_LEADS_FILE_ENTRIES {
    readProspectiveLeadsFileEntries {
      prospectiveLeadsFileEntries {
        id
        fileName
        description
        publicLink
        createdAt
        updatedAt
      }
    }
  }
`;

export const READ_PROSPECTIVE_LEADS_COMMUNICATION_FILE_ENTRIES = gql`
  query READ_PROSPECTIVE_LEADS_COMMUNICATION_FILE_ENTRIES {
    readProspectiveLeadsCommunicationFileEntries {
      prospectiveLeadsCommunicationFileEntries {
        id
        fileName
        description
        publicLink
        actionType
        executionStatus
        createdAt
        updatedAt
      }
    }
  }
`;

export const READ_SMS_TEMPLATE_FOR_PROSPECTIVE_LEADS = gql`
  query READ_SMS_TEMPLATE_FOR_PROSPECTIVE_LEADS {
    readSmsTemplateForProspectiveLeads {
      template
      isSaved
    }
  }
`;

export const GET_STATEMENT_OF_ACCOUNT_DATA = gql`
  query GET_STATEMENT_OF_ACCOUNT_DATA($data: GetStatementOfAccountInput!) {
    getStatementOfAccount(data: $data) {
      code
      message
      statementOfAccountData {
        date
        merchant
        description
        amount
        accountBalance
      }
      businessName
      city
      firstName
      lastName
      street
      streetDetail
      zip
      email
    }
  }
`;

export const SEND_DEVICE_VERIFICATION_SMS_FOR_CURRENT_USER = gql`
  query SEND_DEVICE_VERIFICATION_SMS_FOR_CURRENT_USER {
    sendDeviceVerificationSmsForCurrentUser
  }
`;

export const RESEND_DEVICE_VERIFICATION_FOR_SIGN_IN = gql`
  query RESEND_DEVICE_VERIFICATION_FOR_SIGN_IN($data: EmailInput) {
    resendDeviceVerificationForSignIn(data: $data)
  }
`;

export const GET_LATEST_HIGHNOTE_SESSION = gql`
  query GET_LATEST_HIGHNOTE_SESSION($data: latestHighnoteSessionInput) {
    getLatestHighnoteSession(data: $data) {
      documentSessionList {
        uploadDocumentName
        name
        status
        currentVerificationStatus
        documentUploadSessionStatus
        documentUploadSessionId
        primaryDocumentTypes
        uploadedDocuments
        internalUploadedDocuments
      }
      code
      message
      type
      success
    }
  }
`;

export const HIGHNOTE_GET_VIRTUAL_PAYMENT_CARD_TOKEN = gql`
  query HIGHNOTE_GET_VIRTUAL_PAYMENT_CARD_TOKEN(
    $data: GetVirtualPaymentCardTokenInput
  ) {
    getVirtualPaymentCardToken(data: $data) {
      success
      code
      message
      token
    }
  }
`;

export const IS_EJ_WARD_CONNECTED = gql`
  query IS_EJ_WARD_CONNECTED {
    isEjWardConnected {
      code
      message
    }
  }
`;

export const READ_ACCOUNT_CONTROL_SETTINGS = gql`
  query READ_ACCOUNT_CONTROL_SETTINGS {
    readAccountControlSettings {
      code
      message
      dynamicPurchaseControlsLinkedTo
      cardSharingControls
    }
  }
`;

export const READ_DYNAMIC_PURCHASE_CONTROLS = gql`
  query READ_DYNAMIC_PURCHASE_CONTROLS(
    $data: ReadDynamicPurchaseControlsInput!
  ) {
    readDynamicPurchaseControls(data: $data) {
      code
      message
      id
      object
      dynamicPurchaseControlsSetting
      dynamicPurchaseControlsAllowedFuelCategories
      dynamicPurchaseControlsAllowedNonFuelCategories
    }
  }
`;

export const READ_BLOCKED_MERCHANTS_BY_MERCHANT_ID_FROM_ACCOUNT = gql`
  query READ_BLOCKED_MERCHANTS_BY_MERCHANT_ID_FROM_ACCOUNT {
    readBlockedMerchantsByMerchantIdFromAccount {
      code
      message
      blockedMerchantsNetworkIds {
        merchantName
        networkId
      }
    }
  }
`;

export const READ_CARD_ORDER = gql`
  query READ_CARD_ORDER {
    readCardOrder {
      code
      message
      data {
        cancellableUntil
        cardType
        city
        failedReason
        quantity
        requireSignature
        state
        status
        streetAddress
        deliveryType
        streetDetails
        zip
        id
      }
    }
  }
`;

//admin
export const READ_CURRENT_ADMIN = gql`
  query READ_CURRENT_ADMIN {
    readCurrentAdmin {
      id
      email
      firstName
      lastName
      lastLogin
      roles
    }
  }
`;
//admin: dashboard page
export const READ_ADMIN_DASHBOARD = gql`
  query READ_ADMIN_DASHBOARD {
    readAdminDashboard {
      data {
        totalTransactionAmount
        totalTransactionCount
        totalClientsCount
        totalActiveClientsCount
        totalCardCount
        totalActiveCardCount
        totalOutstandingAmount
        lateFeesOutstandingAmount
        lastBillingCycleOutstandingAmount
        startDate
        endDate
        achPayment {
          automatedPayment {
            startDate
            endDate
            data
          }
          manualPayment {
            startDate
            endDate
            data
          }
        }
        autoReloadDataCount
        userRepaymentDataCount {
          userRepaymentDataCount
          succeededRepayment
        }
      }
      message
      code
    }
  }
`;
export const READ_ADMIN_DASHBOARD_CUSTOMER_STATISTICS = gql`
  query READ_ADMIN_DASHBOARD_CUSTOMER_STATISTICS {
    readAdminDashboardCustomerStatistics {
      data {
        customerType
        amount
        count
        activeCount
        gmv
      }
      code
      message
    }
  }
`;
export const ADMIN_READ_SYSTEM_HEALTH_CHECK = gql`
  query ADMIN_READ_SYSTEM_HEALTH_CHECK {
    readSystemHealthCheck {
      data {
        cronData
        otherData
        validationScriptData
        discrepancyScriptData
      }
      functionalityTracker {
        id
        identifier
        details
        type
      }
      message
      code
    }
  }
`;
export const READ_ADMIN_AUTO_RELOAD_DATA = gql`
  query READ_ADMIN_AUTO_RELOAD_DATA($data: AutoReloadDataInput!) {
    autoReloadData(data: $data) {
      reloadType
      reloadFrequency
      enabled
      topUpAmount
      belowAmount
      createdAt
      email
    }
  }
`;
export const READ_ADMIN_USER_REPAYMENT_DATA = gql`
  query READ_ADMIN_USER_REPAYMENT_DATA {
    userRepaymentData {
      repaymentUserList {
        amount
        durationInNumber
        email
        failedPaymentCount
        repaymentDurationType
        successPaymentCount
        successfulPaymentAmount
        title
      }
      succeededRepaymentAmount
    }
  }
`;
export const READ_ADMIN_GRAPH_OF_PAYMENTS = gql`
  query READ_ADMIN_GRAPH_OF_PAYMENTS {
    graphOfPayments {
      SUCCEEDED
      FAILED
      PENDING
    }
  }
`;
export const READ_ADMIN_GRAPH_OF_TRANSACTIONS = gql`
  query READ_ADMIN_GRAPH_OF_TRANSACTIONS {
    graphOfTransactions {
      TRANSACTIONS
    }
  }
`;
export const READ_ADMIN_GRAPH_OF_CARDS = gql`
  query READ_ADMIN_GRAPH_OF_CARDS {
    graphOfCards {
      ACTIVE
      ACTIVEMORETHANZEROSPENT
      INACTIVE
      TOTAL
      numberofActivecards
      numberOfTotalCards
    }
  }
`;

//admin: subscribers page
export const ADMIN_READ_SUBSCRIBERS = gql`
  query ADMIN_READ_SUBSCRIBERS {
    adminReadSubscribers {
      code
      message
      subscriberList {
        id
        createdAt
        updatedAt
        email
        source
        downloadDates
        data
      }
    }
  }
`;
export const ADMIN_READ_AFFILIATES = gql`
  query ADMIN_READ_AFFILIATES {
    adminReadAffiliates {
      code
      message
      affiliatesList {
        id
        email
        affiliateCode
        referred
        createdAt
      }
    }
  }
`;

//admin: customer information page
export const ADMIN_READ_STRIPE_RESPONSE = gql`
  query ADMIN_READ_STRIPE_RESPONSE($data: IdAndEmailInput) {
    adminReadStripeResponse(data: $data) {
      code
      message
      stripeResponse
    }
  }
`;
export const ADMIN_READ_PROMOTION = gql`
  query ADMIN_READ_PROMOTION($data: ReadPromotionInput) {
    adminReadPromotion(data: $data) {
      code
      message
      promotionData {
        description
        email
        endDate
        id
        isActive
        promotionType
        startDate
      }
    }
  }
`;
export const AUTHORIZATION_LIST = gql`
  query AUTHORIZATION_LIST($data: EmailInput) {
    authorization(data: $data) {
      success
      code
      type
      message
      detail
      help
      authorization {
        id
        authorizationDate
        approvalStatus
        authorizationAmount
        merchantName
        merchantState
        merchantPostCd
        # stripeResponse
        cardId
        cardHolderId
        authorizationStatus
        driverName
        vehicleName
        card {
          id
          lastFour
        }
      }
    }
  }
`;
export const REVERSED_AUTHORIZATION_LIST = gql`
  query REVERSED_AUTHORIZATION_LIST($data: EmailInput) {
    reversedAuthorization(data: $data) {
      success
      code
      type
      message
      detail
      help
      authorization {
        id
        authorizationDate
        approvalStatus
        authorizationAmount
        merchantName
        merchantState
        merchantPostCd
        stripeResponse
        cardId
        cardHolderId
        authorizationStatus
        driverName
      }
    }
  }
`;
//admin: global settings page
export const ADMIN_READ_CARD_SETTING = gql`
  query ADMIN_READ_CARD_SETTING {
    readAdminCardSetting {
      data {
        id
        dailyLimit
        transactionLimit
        categories
        customerType
        restrictedMerchants
      }
      message
      code
    }
  }
`;
export const ADMIN_READ_BONUS_AND_FUEL_DISCOUNT = gql`
  query ADMIN_READ_BONUS_AND_FUEL_DISCOUNT(
    $data: ReadBonusAndFuelDiscountInput
  ) {
    readBonusAndFuelDiscount(data: $data) {
      signupBonus {
        id
        bonusType
        bonusAmount
        fuelQuantity
      }
      referralBonus {
        id
        bonusAmount
        bonusType
        fuelQuantity
        days
      }
      fuelDiscount {
        creditFuelDiscount
        creditPlusFuelDiscount
        prepaidFuelDiscount
        prepaidPlusFuelDiscount
      }
      code
      message
    }
  }
`;
//admin: notifications page
export const READ_ADMIN_EMAIL_TEMPLATE_LIST = gql`
  query READ_ADMIN_EMAIL_TEMPLATE_LIST {
    readAdminEmailTemplateList {
      emailTemplates {
        templateName
        id
        emailId
        sendgridTemplateType
        variables
      }
      sendGridTemplates {
        id
        sendgridTemplateType
        variableNames
      }
      mailTypes {
        id
        mailType
        templateName
      }
      code
      message
    }
  }
`;
export const READ_NOTIFICATION_WORKFLOW = gql`
  query READ_NOTIFICATION_WORKFLOW($data: ReadNotificationWorkflowInput) {
    readNotificationWorkflow(data: $data) {
      notificationWorkflow {
        id
        day
        notificationType
        createdAt
        updatedAt
        emailId
        smsTemplate
        sendgridTemplateType
        variables
        workflowType
      }
      sendGridTemplates {
        id
        sendgridTemplateType
        variableNames
      }
    }
  }
`;
export const ADMIN_READ_AGENT_ACTIONS = gql`
  query ADMIN_READ_AGENT_ACTIONS($data: AgentActionInput) {
    agentActions(data: $data) {
      callClientAction {
        email
        phoneNumber
        firstName
        lastName
        id
        onBoardStatus
        customerType
      }
      smsAction {
        email
        phoneNumber
        firstName
        lastName
        id
        onBoardStatus
        customerType
      }
      emailAction {
        email
        phoneNumber
        firstName
        lastName
        id
        onBoardStatus
        customerType
      }
    }
  }
`;
//admin: blogs page
export const READ_ADMIN_BLOG = gql`
  query READ_ADMIN_BLOG {
    readAdminBlogs {
      code
      message
      blogs {
        id
        titleImage
        supportImages
        title
        blogUrlId
        summary
        rank
        publishDate
        createdAt
        blog
      }
    }
  }
`;
export const READ_ADMIN_IN_THE_NEWS = gql`
  query READ_ADMIN_IN_THE_NEWS {
    readAdminInTheNews {
      code
      message
      inTheNews {
        id
        title
        summary
        publishDate
        newsLink
        image
        rank
      }
    }
  }
`;
//admin: risks page
export const READ_ADMIN_UNDERWRITING_SETTING = gql`
  query READ_ADMIN_UNDERWRITING_SETTING {
    readAdminUnderwritingSetting {
      data {
        id
        suitableApplication
        kyc
        kyb
        businessCredit
        ocrolus
      }
      message
      code
    }
  }
`;
export const READ_ADMIN_APPROVE_UNDERWRITING_DATA = gql`
  query READ_ADMIN_APPROVE_UNDERWRITING_DATA {
    readAdminApproveUnderwritingData {
      data {
        id
        isLicenseVerified
        isBusinessNameVerified
        isPeopleVerified
        isOfficeAddressVerified
        sosDomesticActivationStatus
        sosActivationStatus
        isTinMatch
        watchListHitCount
        registrationStatus
        transactionRiskScore
        accountOpeningRiskScore
        addressRiskScore
        isPhoneRisk
        recommendedCreditLimit
        intelliscoreRiskClass
        fsrRiskCalss
        bankruptcyCount
        judgementCount
        uccFilingCount
        lienCount
        avgEstimatedRevenue
        avgDailyBalance
        negativeBalanceCount
        nsfCount
        altLendingDebitValue
        autoUnderWriting
        altLendingDebitCount
      }
      code
      message
    }
  }
`;
export const READ_ADMIN_REJECT_UNDERWRITING_DATA = gql`
  query READ_ADMIN_REJECT_UNDERWRITING_DATA {
    readAdminRejectUnderwritingData {
      data {
        id
        isTinMatch
        companyType
        watchListHitCount
        avgEstimatedRevenue
        avgDailyBalance
        autoUnderWriting
      }
      code
      message
    }
  }
`;

//admin: collections page
export const READ_ADMIN_OUTBOUND_EMAILS = gql`
  query READ_ADMIN_OUTBOUND_EMAILS {
    readAdminOutboundEmails {
      id
      email
    }
  }
`;
export const READ_COLLECTION_WORKFLOW_SETTINGS = gql`
  query READ_COLLECTION_WORKFLOW_SETTINGS {
    readCollectionWorkflowSetting {
      id
      isEmailEnabled
      isSmsEnabled
    }
  }
`;
export const READ_DELINQUENT_DEFAULT_DATA = gql`
  query READ_DELINQUENT_DEFAULT_DATA {
    readDelinquentDefaultData {
      delinquent {
        daysOverDue
        email
        billingStatus
        invoiceId
        businessName
        phoneNumber
        collectionAdminId
        userId
      }
      default {
        email
        businessName
        phoneNumber
        formattedPhoneNumber
        assignedAgent
        invoices {
          invoiceId
          status
          daysOverDue
        }
      }
      code
      message
    }
  }
`;
//admin: activity logs page
export const READ_ADMIN_ACTIVITY_LOGS = gql`
  query READ_ADMIN_ACTIVITY_LOGS($data: ReadAdminActivityLogsInput) {
    readAdminActivityLogs(data: $data) {
      message
      code
      adminActivityLogs {
        id
        adminId
        activity
        activityType
        activityDetails
        createdAt
        admin {
          firstName
          lastName
          email
        }
        userLead {
          email
        }
        userProfile {
          email
        }
      }
    }
  }
`;
export const READ_ADMINS = gql`
  query READ_ADMINS {
    readAdmins {
      code
      message
      adminList {
        id
        firstName
        lastName
        email
        lastLogin
        roles
        identifier
      }
    }
  }
`;
export const GET_ADMIN_UNDERWRITING_STATISTICS = gql`
  query GET_ADMIN_UNDERWRITING_STATISTICS {
    businessUnderwritingStatistics {
      statistics
    }
  }
`;

export const PAYMENT_HISTORY_LIST = gql`
  query PAYMENT_HISTORY_LIST($data: EmailInput) {
    paymentHistoryList(data: $data) {
      success
      code
      type
      message
      detail
      help
      paymentHistory {
        amount
        status
        createdAt
        payId
        stripeResponse
        mtResponse
        failureReason
        id
        refunded
        paymentType
      }
      manualPayment {
        amount
        status
        stripeResponse
        createdAt
        id
      }
      refundPayment {
        amount
        status
        stripeResponse
        createdAt
        id
      }
    }
  }
`;

//driver app
export const NATIVE_READ_STRIPE_EPHEMERAL_KEY = gql`
  query NATIVE_READ_STRIPE_EPHEMERAL_KEY(
    $data: NativeReadStripeEphemeralKeyInput
  ) {
    nativeReadStripeEphemeralKey(data: $data) {
      code
      message
      ephemeralKeySecret
      expiresIn
    }
  }
`;
export const NATIVE_DRIVER_READ_CARD_DATA = gql`
  query NATIVE_DRIVER_READ_CARD_DATA($data: NativeDriverReadCardDataInput) {
    nativeDriverReadCardData(data: $data) {
      code
      message
      card {
        name
        lastFour
        expiryMonth
        expiryYear
        brand
        smsSecurity
        odometerSecurity
        status
        licensePlateNumber
        cardId
        applePay {
          eligible
          ineligibleReason
        }
        googlePay {
          eligible
          ineligibleReason
        }
        primaryAccountIdentifier
      }
    }
  }
`;
export const NATIVE_DRIVER_READ_ALERTS = gql`
  query NATIVE_DRIVER_READ_ALERTS($data: NativeDriverReadAlertsInput) {
    nativeDriverReadAlerts(data: $data) {
      message
      code
      alerts {
        id
        createdAt
        message
        type
        deleted
        read
      }
    }
  }
`;
export const NATIVE_DRIVER_UNREAD_ALERTS_COUNT = gql`
  query NATIVE_DRIVER_UNREAD_ALERTS_COUNT {
    nativeDriverUnreadAlertsCount {
      message
      code
      unreadAlertsCount
    }
  }
`;
export const NATIVE_DRIVER_TRANSACTION_DETAIL = gql`
  query NATIVE_DRIVER_TRANSACTION_DETAIL(
    $data: NativeDriverTransactionDetailInput
  ) {
    nativeDriverTransactionDetail(data: $data) {
      code
      message
      transaction {
        id
        authorizationDate
        transactionDate
        transactionStatus
        transactionAmount
        merchantName
        merchantState
        merchantPostCd
        purchaseDetails
        fuelDiscountDetail
        fuelDiscountAmount
        fuelQuantity
        memo
        receipts {
          fileName
          folderName
          fileExtension
          createdAt
        }
      }
    }
  }
`;
export const NATIVE_DRIVER_AUTHORIZATION_DETAIL = gql`
  query NATIVE_DRIVER_AUTHORIZATION_DETAIL(
    $data: NativeDriverAuthorizationDetailInput
  ) {
    nativeDriverAuthorizationDetail(data: $data) {
      code
      message
      authorization {
        id
        authorizationDate
        authorizationStatus
        authorizationAmount
        merchantName
        merchantState
        merchantPostCd
        memo
        receipts {
          fileName
          folderName
          fileExtension
          createdAt
        }
      }
    }
  }
`;
export const NATIVE_FLEET_MANAGER_READ_ALERTS = gql`
  query NATIVE_FLEET_MANAGER_READ_ALERTS($data: NativeReadAlertsInput) {
    nativeFleetManagerReadAlerts(data: $data) {
      message
      code
      alerts {
        id
        createdAt
        message
        type
        deleted
        read
      }
    }
  }
`;
export const NATIVE_FLEET_MANAGER_READ_ACCOUNT_CONTROL_SETTINGS = gql`
  query NATIVE_FLEET_MANAGER_READ_ACCOUNT_CONTROL_SETTINGS {
    readAccountControlSettings {
      code
      message
      dynamicPurchaseControlsLinkedTo
      cardSharingControls
    }
  }
`;

export const NATIVE_FLEET_MANAGER_READ_PAYMENT_HISTORY = gql`
  query NATIVE_FLEET_MANAGER_READ_PAYMENT_HISTORY {
    nativeFleetManagerReadPaymentHistory {
      code
      message
      paymentHistoryList {
        amount
        status
        createdAt
        payId
        stripeResponse
        mtResponse
        failureReason
        id
        refunded
        paymentType
      }
    }
  }
`;
