/** @format */

import getConfig from "next/config";
import Head from "next/head";
import { useRouter } from "next/router";
import PropTypes from "prop-types";

const { publicRuntimeConfig } = getConfig();

const { NEXT_PUBLIC_CDN_URL, NEXT_PUBLIC_WWW_URL } = publicRuntimeConfig;

export interface HeadSeoProps {
  url: string;
  image: string;
  title: string;
  description: string;
  keywords: string[];
}

export function HeadSeo({
  url,
  title,
  description,
  keywords,
  image,
}: HeadSeoProps) {
  const siteUrl = NEXT_PUBLIC_WWW_URL;
  const router = useRouter();
  const cleanPath = router.asPath.split("#")[0].split("?")[0];
  const canonicalUrl = `${siteUrl}${router.asPath === "/" ? "" : cleanPath}`;
  const pages = ["/contact-us"];
  return (
    <Head>
      <meta charSet="UTF-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />

      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords.join(", ")} />
      {router.asPath.split("#")[0].split("?").length > 1 && (
        <meta name="robots" content="noindex, nofollow" />
      )}
      <link rel="icon" href={`${NEXT_PUBLIC_CDN_URL}/svg/roadflex-logo.svg`} />
      <link rel="canonical" href={canonicalUrl} />
      {/* <CanonicalURL /> */}
      {/* Open Graph */}
      <meta property="og:url" content={url} />
      {/* <meta property="og:type" content="article" /> */}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />

      {/* Twitter */}
      {/* <meta name="twitter:card" content="summary" /> */}
      {/* <meta name="twitter:site" content="@publisher_handle" /> */}
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      {/* <meta name="twitter:creator" content="@author_handle" /> */}
      <meta name="twitter:image" content={image} />

      {pages.includes(router.pathname) && (
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=AW-10819895187"
        ></script>
      )}

      {pages.includes(router.pathname) && (
        <script
          dangerouslySetInnerHTML={{
            __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', 'AW-10819895187'); 
              `,
          }}
        />
      )}
    </Head>
  );
}

HeadSeo.propTypes = {
  url: PropTypes.string,
  title: PropTypes.string,
  image: PropTypes.string,
  description: PropTypes.string,
  keywords: PropTypes.arrayOf(PropTypes.string),
};

HeadSeo.defaultProps = {
  url: `${NEXT_PUBLIC_WWW_URL}`,
  image: `${NEXT_PUBLIC_CDN_URL}/svg/roadflex-logo.svg`,
  title: "RoadFlex - A fuel card accepted at all fuel stations",
  description:
    "Start saving more on fuel. Competitive discounts. No PG needed to apply. Apply now and get your new fuel card approved in 1 day.",
  keywords: ["RoadFlex"],
};

export default HeadSeo;
