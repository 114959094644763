/** @format */
import { useMutation, useQuery } from "@apollo/client";
import {
  ADMIN_UPDATE_APPROVE_UNDERWRITING_DATA,
  ADMIN_UPDATE_REJECT_UNDERWRITING_DATA,
  ADMIN_UPDATE_UNDERWRITING_SETTING,
  READ_ADMIN_APPROVE_UNDERWRITING_DATA,
  READ_ADMIN_REJECT_UNDERWRITING_DATA,
  READ_ADMIN_UNDERWRITING_SETTING,
} from "@roadflex/graphql";
import {
  ApproveUnderwritingData,
  RejectUnderWritingData,
} from "@roadflex/types";
import { useFormik } from "formik";

type Values = {
  suitableApplication: Record<
    number,
    Record<string, string | string[] | number>
  >;
  kyc: Record<number, Record<string, string | string[]>>;
  kyb: Record<number, Record<string, string | string[]>>;
  businessCredit: Record<number, Record<string, string | string[] | number>>;
  ocrolus: Record<number, Record<string, string | string[] | number>>;
};
type useUpdateAdminUnderwritingSettingProps = {
  initialValues: Values;
  onSubmit: (val: any, err: any) => void;
};
type useUpdateAdminApproveUnderwritingDataProps = {
  initialValues: ApproveUnderwritingData;
  onSubmit: (val: any, err: any) => void;
};
type useUpdateAdminRejectUnderwritingDataProps = {
  initialValues: RejectUnderWritingData;
  onSubmit: (val: any, err: any) => void;
};

export const useReadAdminUnderwritingSetting = () => {
  const {
    data: adminUnderwritingSettingData,
    refetch: adminUnderwritingSettingRefetch,
    loading: adminUnderwritingSettingLoading,
    ...rest
  } = useQuery<{
    readAdminUnderwritingSetting: {
      data: any;
      code: string;
      message: string;
    };
  }>(READ_ADMIN_UNDERWRITING_SETTING, {
    fetchPolicy: "no-cache",
  });

  return {
    adminUnderwritingSettingData,
    adminUnderwritingSettingRefetch,
    adminUnderwritingSettingLoading,
    ...rest,
  };
};

export const useUpdateAdminUnderwritingSetting = ({
  onSubmit,
  initialValues,
}: useUpdateAdminUnderwritingSettingProps) => {
  const [updateAdminUnderwritingSettingFn] = useMutation(
    ADMIN_UPDATE_UNDERWRITING_SETTING,
  );

  const {
    handleChange: saveAdminUnderwritingSettingHandleChange,
    handleSubmit: saveAdminUnderwritingSettingHandleSubmit,
    values: saveAdminUnderwritingSettingValues,
    isSubmitting: isSaveAdminUnderwritingSettingSubmitting,
    ...rest
  } = useFormik({
    initialValues,
    onSubmit: async (values) => {
      const data = { ...values };
      try {
        const res = await updateAdminUnderwritingSettingFn({
          variables: { data },
        });
        onSubmit(res, null);
      } catch (error) {
        onSubmit(null, error);
      }
    },
  });

  return {
    saveAdminUnderwritingSettingValues,
    saveAdminUnderwritingSettingHandleChange,
    saveAdminUnderwritingSettingHandleSubmit,
    isSaveAdminUnderwritingSettingSubmitting,
    ...rest,
  };
};

export const useReadAdminApproveUnderwritingData = () => {
  const {
    data: adminApproveUnderwritingData,
    refetch: adminApproveUnderwritingRefetch,
    loading: adminApproveUnderwritingLoading,
    ...rest
  } = useQuery<{
    readAdminApproveUnderwritingData: {
      data: ApproveUnderwritingData;
      code: string;
      message: string;
    };
  }>(READ_ADMIN_APPROVE_UNDERWRITING_DATA, {
    fetchPolicy: "no-cache",
  });

  return {
    adminApproveUnderwritingData,
    adminApproveUnderwritingRefetch,
    adminApproveUnderwritingLoading,
    ...rest,
  };
};
export const useUpdateAdminApproveUnderwritingData = ({
  onSubmit,
  initialValues,
}: useUpdateAdminApproveUnderwritingDataProps) => {
  const [updateAdminUnderwritingSettingFn] = useMutation<{
    code: string;
    message: string;
  }>(ADMIN_UPDATE_APPROVE_UNDERWRITING_DATA);

  const {
    handleChange: updateApproveUnderwritingHandleChange,
    handleSubmit: updateApproveUnderwritingHandleSubmit,
    values: updateApproveUnderwritingValues,
    isSubmitting: isUpdateApproveUnderwritingSubmitting,
    ...rest
  } = useFormik({
    initialValues,
    onSubmit: async (values) => {
      const data = { ...values };
      try {
        const res = await updateAdminUnderwritingSettingFn({
          variables: { data },
        });
        onSubmit(res, null);
      } catch (error) {
        onSubmit(null, error);
      }
    },
  });

  return {
    updateApproveUnderwritingValues,
    updateApproveUnderwritingHandleChange,
    updateApproveUnderwritingHandleSubmit,
    isUpdateApproveUnderwritingSubmitting,
    ...rest,
  };
};

export const useReadAdminRejectUnderwritingData = () => {
  const {
    data: adminRejectUnderwritingData,
    refetch: adminRejectUnderwritingRefetch,
    loading: adminRejectUnderwritingLoading,
    ...rest
  } = useQuery<{
    readAdminRejectUnderwritingData: {
      data: RejectUnderWritingData;
      code: string;
      message: string;
    };
  }>(READ_ADMIN_REJECT_UNDERWRITING_DATA, {
    fetchPolicy: "no-cache",
  });

  return {
    adminRejectUnderwritingData,
    adminRejectUnderwritingRefetch,
    adminRejectUnderwritingLoading,
    ...rest,
  };
};

export const useUpdateAdminRejectUnderwritingData = ({
  onSubmit,
  initialValues,
}: useUpdateAdminRejectUnderwritingDataProps) => {
  const [saveRejectUnderwriting] = useMutation(
    ADMIN_UPDATE_REJECT_UNDERWRITING_DATA,
  );

  const {
    handleChange: updateRejectUnderwritingHandleChange,
    handleSubmit: updateRejectUnderwritingHandleSubmit,
    values: updateRejectUnderwritingValues,
    isSubmitting: isupdateRejectUnderwritingSubmitting,
    ...rest
  } = useFormik({
    initialValues,
    onSubmit: async (values) => {
      const data = { ...values };
      try {
        const res = await saveRejectUnderwriting({
          variables: { data },
        });
        onSubmit(res, null);
      } catch (error) {
        onSubmit(null, error);
      }
    },
  });

  return {
    updateRejectUnderwritingValues,
    updateRejectUnderwritingHandleChange,
    updateRejectUnderwritingHandleSubmit,
    isupdateRejectUnderwritingSubmitting,
    ...rest,
  };
};
